/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../../_partials/Card"
import TimesheetDailyToPrintChart from "./TimesheetDailyToPrintChart"

const TimesheetDailyToPrint = forwardRef((props, ref) => {
	const { singlePeopleTimesheet } = useSelector(
		state => ({
			singlePeopleTimesheet: state.reporting.singlePeopleTimesheet
		}),
		shallowEqual
	)

	return (
		<Card>
			<CardBody>
				<div style={{ overflow: "hidden", height: "0" }}>
					<TimesheetDailyToPrintChart
						singlePeopleTimesheet={singlePeopleTimesheet}
						ref={ref}
					/>
				</div>
			</CardBody>
		</Card>
	)
})

export default TimesheetDailyToPrint
