import {
	firestoreOld as db
	//firebaseAppOld as firebase,
} from "../../../../../firebase"
import firebase from "firebase/compat/app"
// import { storage } from "../../../../../firebase";

// const firestoreDate = new Date(
//   firebase.firestore.Timestamp.now().seconds * 1000
// );

const customersRef = db.collection("Customers")
const sitesRef = db.collection("Sites")

export function updateUrls(queryParams) {
	// console.log(queryParams, "params no CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.zoneToUpdateUrls ||
		!queryParams.customerId ||
		!queryParams.newImagesUrls ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.resolve(null)
	}

	const zoneId = queryParams?.zoneToUpdateUrls[0]?.id
	return Promise.all([
		customersRef.doc(`${queryParams.customerId}`).collection("Zones").doc(zoneId).update({
			imagesUrls: queryParams.newImagesUrls
		}),
		customersRef.doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])
}

export function getZonesInDB({ floorPlanId, networkId }) {
	if (!floorPlanId || !networkId) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(networkId).collection(`Areas`).where("floorPlanId", "==", floorPlanId).get()
}

export function saveUrls(queryParams) {
	// console.log(queryParams, "Params CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.customerId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson ||
		!queryParams.row ||
		!queryParams.row.geoJson ||
		!queryParams.row.geoJson.properties ||
		!queryParams.row.geoJson.properties.zoneId ||
		!queryParams.result
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return Promise.all([
		customersRef.doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		}),
		customersRef
			.doc(`${queryParams.customerId}`)
			.collection("Zones")
			.doc(queryParams.row.geoJson.properties.zoneId)
			.update({
				// arrayUnion - só adiciona as novas da Array
				imagesUrls: firebase.firestore.FieldValue.arrayUnion(...queryParams.result)
			})
	])
}

export function saveGeoJson(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.promises ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return Promise.all([
		...queryParams.promises,
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])

	//TODO: old code to be deleted
	//   customersRef
	//     .doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`)
	//     .update({
	//       geoJson: queryParams.geoJson,
	//     }),
	// ]);
}

export function saveLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function deleteLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function updateLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function saveZones({ data, customerId, netId, floorPlanId }) {
	if (!Array.isArray(data)) {
		// throw new Error('The "data" parameter must be an array.')
		return Promise.resolve(null)
	}

	return Promise.all(
		data.map(zone => {
			// console.log(zone, "zone");
			return customersRef.doc(`${customerId}`).collection("Zones").doc().update({
				name: zone.name,
				color: zone.color,
				polygon: zone.coords,
				floorPlanId: floorPlanId,
				netId: netId,
				limit: zone.capacity,
				wirelessAP: []
			})
		})
	)
}

export function deleteZones(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.zoneToDelete ||
		!queryParams.zoneToDelete.type ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	if (queryParams.zoneToDelete.type === "baseLayer") {
		return Promise.resolve(
			sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
				geoJson: queryParams.geoJson
			})
		)
	}

	return Promise.all([
		sitesRef
			.doc(`${queryParams.networkId}`)
			.collection("Areas")
			.doc(queryParams.zoneToDelete.geoJson.properties.areaId)
			.delete(),
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])
}

export function saveMarkersFromZones(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.networkId ||
		!queryParams.marker.markerOfAreaId ||
		!queryParams.marker.coords ||
		!queryParams.marker
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return sitesRef
		.doc(`${queryParams.networkId}`)
		.collection("Areas")
		.doc(queryParams.marker.markerOfAreaId)
		.update({
			marker: queryParams.marker.coords
		})
}

export function saveDoorMarkersFromZones(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.networkId ||
		!queryParams.doorMarker.markerOfAreaId ||
		!queryParams.doorMarker.coords ||
		!queryParams.doorMarker
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return sitesRef
		.doc(`${queryParams.networkId}`)
		.collection("Areas")
		.doc(queryParams.doorMarker.markerOfAreaId)
		.update({
			doorMarker: queryParams.doorMarker.coords
		})
}

export function getResourcesMeetings({ customerId, networkId }) {
	if (!customerId || !networkId) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(customerId)
		.collection(`Resources`)
		.where("networkId", "==", networkId)
		.where("type", "==", "meeting")
		.orderBy("created.date")
		.get()
}

//★━━━━━━━━━★ Points of Interest CRUD ★━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export async function createPointOfInterest({
	data,
	siteId,
	user,
	imagesUrls,
	floorPlanId,
	coords,
	markerVisible
}) {
	// console.log(user, "user");
	// console.log("New Point of Interest created", data);
	// console.log(imagesUrls, "imagesUrls CRUD");
	if (!siteId || !data || !user || !imagesUrls || !floorPlanId || !coords || !markerVisible) {
		return Promise.resolve(null)
	}

	const ref = sitesRef.doc(siteId).collection("PointsOfInterest").doc()

	await ref.set({
		name: data.name,
		description: data.description,
		type: data.type,
		imagesUrls: imagesUrls,
		siteId: siteId,
		created: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		floorPlanId: floorPlanId,
		coords: coords,
		markerVisible: markerVisible
	})
	return ref.id
}

export async function updatePointOfInterest({ data, siteId, user, newImagesUrlsArray }) {
	if (!siteId || !data || !user) {
		return Promise.resolve(null)
	}

	const id = data.id
	const ref = sitesRef.doc(siteId).collection("PointsOfInterest").doc(id)
	// console.log("New Point of Interest updated", data);

	await ref.update({
		name: data.name,
		description: data.description,
		type: data.type,
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		markerVisible: data.markerVisible
	})
	return ref.id
}

export function savePointsOfInterest({ data, customerId }) {
	// console.log("Data: ", data);
	if (!customerId || !data) {
		return Promise.resolve(null)
	}

	return Promise.all(
		data.map(resource => {
			if (resource.id !== undefined) {
				return customersRef
					.doc(`${customerId}`)
					.collection("pointsOfInterest")
					.doc(resource.id)
					.update({
						floorPlanId: resource.floorPlanId || "",
						networkId: resource.networkId || "",
						coords: resource.coords || ""
					})
			}
			return null
		})
	)
}

export function saveMarkersFromPOIs(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef
		.doc(queryParams.siteId)
		.collection("PointsOfInterest")
		.doc(queryParams.marker.markerOfPOIsId)
		.update({
			coords: queryParams.marker.coords || "",
			floorPlanId: queryParams.marker.floorPlanId || ""
		})
}

// export function saveMarkerVisibilityFromPOIs(queryParams) {
//   // console.log(queryParams, "Params CRUD saveMarkersFromPOIs");
//   return customersRef
//     .doc(`${queryParams.customerId}`)
//     .collection("pointsOfInterest")
//     .doc(queryParams.poiId)
//     .update({
//       markerVisible: queryParams.markerVisible,
//     });
// }

export function getPointsOfInterest(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef
		.doc(queryParams.siteId)
		.collection(`PointsOfInterest`)
		.orderBy("created.date")
		.get()
}

export function deletePointOfInterest({ id, siteId }) {
	if (!id || !siteId) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(`${siteId}/PointsOfInterest/${id}`).delete()
}

export function getPointOfInterestToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return Promise.resolve(null)
	}
	// console.log(id, "id on CRUD");

	// return customersRef
	//   .doc(customerId)
	//   .collection(`pointsOfInterest`)
	//   .doc(id)
	//   .get();

	return sitesRef.doc(`${siteId}/PointsOfInterest/${id}`).get()
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Anchors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export function getAnchorsCollection(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}
	return sitesRef
		.doc(queryParams.siteId)
		.collection(`AnchorsPosition`)
		.orderBy("created.date")
		.get()
}

export async function createAnchorOnDatabase({
	data,
	siteId,
	networkAddress,
	user,
	imagesUrls,
	floorPlanId,
	wirepasFloorPlanId,
	gpsData,
	customerId
}) {
	if (
		!data ||
		!siteId ||
		!networkAddress ||
		!user ||
		!floorPlanId ||
		!wirepasFloorPlanId ||
		!gpsData ||
		!customerId
	)
		return

	const ref = sitesRef.doc(siteId).collection("AnchorsPosition").doc()

	await ref.set({
		uuid: Number(data.uuid),
		node_name: data.node_name ? data.node_name : data.uuid,
		description: data.description,
		imagesUrls: imagesUrls,
		siteId: siteId,
		network_address: networkAddress,
		created: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		floorPlanId: floorPlanId,
		wirepasFloorPlanId: wirepasFloorPlanId,
		gpsData: gpsData,
		// placedOnSite: false,
		//!
		//TODO: Change to created for new inventory logic
		// status: "planned",
		status: "created",
		approved: false,
		customerId: customerId
	})
	return ref.id
}

export async function saveAnchorsDraggedOnDatabase(queryParams) {
	// console.log("🚀 ~ saveAnchorsDraggedOnDatabase ~ queryParams:", queryParams)
	if (
		!queryParams.siteId ||
		!queryParams.marker ||
		!queryParams?.marker?.markerOfPOIsId ||
		!queryParams?.network_address ||
		!queryParams?.wirepasFloorPlanId ||
		!queryParams.oldAnchorDoc ||
		!queryParams.user
	)
		return

	// network_address: selectedSite.netWirepas.toString(),
	// wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId,
	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")

	// New data
	const updateData = {
		gpsData: queryParams.marker.gpsData || "",
		floorPlanId: queryParams.marker.floorPlanId || "",
		status: queryParams.marker.status || "",
		network_address: queryParams.network_address || "",
		wirepasFloorPlanId: queryParams.wirepasFloorPlanId || ""
	}

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: queryParams.marker.markerOfPOIsId,
		previousData: queryParams.oldAnchorDoc,
		updatedData: queryParams.marker,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateAnchorOperation = anchorsRef
		.doc(queryParams.marker.markerOfPOIsId)
		.update(updateData) // AnchorsPosition Collection
	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	var end = [updateAnchorOperation, updateOtherCollectionOperation]

	await Promise.all(end)

	return end.length
}

export function getAnchorToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(`${siteId}/AnchorsPosition/${id}`).get()
}

// Update anchor
export async function updateAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId
}) {
	if (!siteId || !data || !data.uuid || !user || !customerId) return

	// const id = data.id
	const id = data.id.toString()

	const anchorsRef = sitesRef.doc(siteId).collection("AnchorsPosition")

	// New data
	const updateData = {
		uuid: Number(data.uuid),
		// node_name: data.node_name
		// 	? data.node_name
		// 	: typeof data.uuid === "string"
		// 	? data.uuid
		// 	: data.uuid.toString(),
		node_name: typeof data.uuid === "string" ? data.uuid : data.uuid.toString(),
		description: data.description || "",
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		// placedOnSite: data.placedOnSite ? data.placedOnSite : false,
		status: data?.status,
		customerId: customerId
	}

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateAnchorOperation = anchorsRef.doc(id).update(updateData) // AnchorsPosition Collection
	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	await Promise.all([updateAnchorOperation, updateOtherCollectionOperation])
}

// Approve anchor CRUD
// export async function approveAnchor({ data, siteId, user, customerId }) {
// 	if (!siteId || !data || !user || !customerId) return

// 	const anchorsRef = sitesRef.doc(siteId).collection("AnchorsPosition")

// 	return await Promise.all([
// 		data &&
// 			data.map(val => {
// 				// New data
// 				const updateData = {
// 					approved: true,
// 					updated: {
// 						id: user.id,
// 						email: user.email,
// 						date: firebase.firestore.FieldValue.serverTimestamp()
// 					}
// 				}

// 				return anchorsRef.doc(val.id).update(updateData)
// 			})
// 	])
// }

export async function deleteAnchorFromDatabase({ id, siteId, oldAnchorDoc, user }) {
	if (!id || !siteId || !oldAnchorDoc || !user) return

	// return sitesRef.doc(`${siteId}/AnchorsPosition/${id}`).delete()
	const anchorsRef = sitesRef.doc(siteId).collection("AnchorsPosition")

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		deleted: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		anchorDeleted: true
	}

	const deleteAnchorOperation = anchorsRef.doc(id).delete() // AnchorsPosition Collection
	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	await Promise.all([deleteAnchorOperation, updateOtherCollectionOperation])
}

// Update active anchor
export async function updateActiveAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId,
	wirepasFloorPlanId
}) {
	if (!siteId || !data || !data.id || !user || !wirepasFloorPlanId) return

	const id = data.id.toString()

	// const activeAnchorsRef = sitesRef.doc(siteId).collection("AnchorsActive") // not used anymore
	const nodesRef = sitesRef.doc(siteId).collection("Nodes")

	// New data
	const updateData = {
		...data,
		description: data?.description || "",
		imagesUrls: newImagesUrlsArray || [],
		status: data.status,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		customerId: customerId,
		wirepasFloorPlanId: wirepasFloorPlanId
	}

	const anchorsLogsRef = db.collection("AnchorsActiveLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}
	// console.log("🚀  logData:", logData)

	// not used anymore
	// // Find if the document id already is created to change firestore operation update or set
	// const snapshot = await db.collection(`Sites/${siteId}/AnchorsActive`).where("id", "==", id).get()

	// const foundActiveAnchor = snapshot.docs.map(doc => {
	// 	return {
	// 		id: doc.id,
	// 		...doc.data()
	// 	}
	// })
	// // console.log("🚀  foundActiveAnchor:", foundActiveAnchor)

	// // Active Anchors operation
	// const updateActiveAnchorOperation =
	// 	foundActiveAnchor && foundActiveAnchor.length !== 0
	// 		? activeAnchorsRef.doc(id).update(updateData)
	// 		: activeAnchorsRef.doc(id).set(updateData)

	const updateActiveAnchorOperation = nodesRef.doc(id).update(updateData)

	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	await Promise.all([updateActiveAnchorOperation, updateOtherCollectionOperation])
}

// export function getActiveAnchorsCollection(queryParams) {
// 	if (!queryParams) {
// 		return Promise.resolve(null)
// 	}

// 	return sitesRef.doc(queryParams.siteId).collection(`AnchorsActive`).orderBy("updated.date").get()
// }

export async function deleteSeveralAnchorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.selectedData) {
		return Promise.resolve(null)
	}

	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	return await Promise.all([
		queryParams.selectedData &&
			queryParams.selectedData.map(d => {
				return anchorsRef.doc(d.id).delete()
			}),

		queryParams.selectedData &&
			queryParams.selectedData.map(val => {
				// Log data
				const logData = {
					docId: val.id,
					previousData: val,
					deleted: {
						id: queryParams.user.id,
						email: queryParams.user.email,
						date: firebase.firestore.FieldValue.serverTimestamp()
					},
					anchorDeleted: true
				}

				return anchorsLogsRef.doc().set(logData)
			})
	])

	// Log data
	// const logData = {
	// 	docId: val.id,
	// 	previousData: val,
	// 	deleted: {
	// 		id: queryParams.user.id,
	// 		email: queryParams.user.email,
	// 		date: firebase.firestore.FieldValue.serverTimestamp()
	// 	},
	// 	anchorDeleted: true
	// }

	// const deleteAnchorOperation = anchorsRef.doc(id).delete() // AnchorsPosition Collection
	// const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	// await Promise.all([deleteAnchorOperation, updateOtherCollectionOperation])
}

// export async function unplaceAnchorsFromDatabase(queryParams) {
// 	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
// 		return Promise.resolve(null)
// 	}

// 	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")

// 	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

// 	return await Promise.all([
// 		queryParams.ids &&
// 			queryParams.ids.map(id => {
// 				const updateData = {
// 					approved: false,
// 					status: "assigned",
// 					updated: {
// 						id: queryParams.user.id,
// 						email: queryParams.user.email,
// 						date: firebase.firestore.FieldValue.serverTimestamp()
// 					}
// 				}
// 				return anchorsRef.doc(id).update(updateData)
// 			}),

// 		queryParams.ids &&
// 			queryParams.ids.map(id => {
// 				const updateData = {
// 					approved: false,
// 					status: "assigned",
// 					updated: {
// 						id: queryParams.user.id,
// 						email: queryParams.user.email,
// 						date: firebase.firestore.FieldValue.serverTimestamp()
// 					}
// 				}

// 				// Log data
// 				const logData = {
// 					docId: id,
// 					// previousData: oldAnchorDoc,
// 					updatedData: updateData,
// 					updated: {
// 						id: queryParams.user.id,
// 						email: queryParams.user.email,
// 						date: firebase.firestore.FieldValue.serverTimestamp()
// 					}
// 				}

// 				return anchorsLogsRef.doc().set(logData)
// 			})
// 	])
// }
export async function unplaceAnchorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
		return Promise.resolve(null)
	}

	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")
	const anchorsLogsRef = db.collection("AnchorsPositionLogs")

	const updateData = {
		approved: false,
		status: "assigned",
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const batch = db.batch()

	queryParams.ids.forEach(id => {
		batch.update(anchorsRef.doc(id), updateData)

		const logData = {
			docId: id,
			updatedData: updateData,
			updated: {
				id: queryParams.user.id,
				email: queryParams.user.email,
				date: firebase.firestore.FieldValue.serverTimestamp()
			}
		}
		batch.set(anchorsLogsRef.doc(), logData)
	})
	await batch.commit()
}
