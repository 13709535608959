export const defaultSorted = [{ dataField: "name", order: "asc" }]
export const sizePerPageList = [
	{ text: "5", value: 5 },
	{ text: "10", value: 10 },
	{ text: "20", value: 20 },
	{ text: "35", value: 35 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 }
]
export const initialFilter = {
	filter: {
		searchText: "",
		searchField: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "displayName",
	pageNumber: 1,
	pageSize: 10
}
