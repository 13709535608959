import React from "react"
import ReactDOM from "react-dom"
import { IconButton } from "@material-ui/core"
import { GrFilter } from "react-icons/gr"
import SearchIcon from "@material-ui/icons/Search"
import "./DrawerAnchors.css"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
// import FilterGeneralInfo from "./drawer-options/FilterGeneralInfo"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import NewSearchAnchors from "./NewSearchAnchors"

// import NewSearch from "./drawer-options/NewSearch"
// import DirectionsOutlinedIcon from "@material-ui/icons/DirectionsOutlined"
// import NewDirectionsControl from "./drawer-options/NewDirectionsControl"
// import { MdOutlineDirectionsOff } from "react-icons/md"
// import { FiLayers } from "react-icons/fi"
// import OccupancyFilter from "./drawer-options/OccupancyFilter"
// import LayersView from "./drawer-options/LayersView"

export default class DrawerAnchors {
	constructor(props) {
		this.props = props
	}

	onAdd(map) {
		this.map = map
		this.container = document.createElement("div")
		this.container.className = "mapboxgl-ctrl hide-search-drawer-bar"
		// this.container.id = "drawerOptions_main_container";
		// this.container.style.cursor = "pointer";

		// console.log("🚀🚀🚀 all _ this.props: ", this.props)
		// console.log("🎃🎃🎃  openDrawerBar:", this.props.openDrawerBar)
		// console.log("🚀🚀🚀  drawerOptions:", this.props.drawerOptions)
		// console.log("👾👾👾  openFilter:", this.props.drawerOptions.openFilter);
		// console.log("🚀  showAreaInfoMarkers:", this.props.showAreaInfoMarkers);

		const icon = this.props.openDrawerBar ? (
			<ExpandLessIcon fontSize="default" style={{ color: "#2C3E50" }} />
		) : (
			<ExpandMoreIcon fontSize="default" style={{ color: "#2C3E50" }} />
		)

		this.content = (
			<div
				className="container_drawer_bar_content anchors_drawer_bar_content"
				style={{ display: !this.props.selectedSite ? "none" : "flex" }} //  ━━━━ Hide if !selectedSite
			>
				{/* // ━━━━━━━━━━━━━━━━━━━ Components drawer options  ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				{/* // ━━━━ ↓ Search component */}
				{this.props.drawerOptions.openSearch && (
					<div className="search_wrapper">
						<NewSearchAnchors
							drawerOptions={this.props.drawerOptions}
							selectedSite={this.props.selectedSite}
							map={this.props.map}
							dispatch={this.props.dispatch}
							activeAnchors={this.props.activeAnchors}
							positionAnchors={this.props.positionAnchors}
							floorPlans={this.props.floorPlans}
							floorPlan={this.props.floorPlan}
							setGoTo={this.props.setGoTo}
							setShowAnchorsOnline={this.props.setShowAnchorsOnline}
						/>
					</div>
				)}

				{/* // ━━━━━━━━━━━━━━━━━━━ Drawer Bar ━━━━━━━━━━━━━━━━━━━━━━ \\  */}
				<div className="wrapper_drawer_bar_content">
					{/* // ━━━━━━ Option - Search filter */}
					{/* ↓ If openSearch is opened, it closes the overlayTrigger */}
					{!this.props.drawerOptions.openSearch ? (
						<OverlayTrigger
							trigger={["hover", "focus"]}
							placement="left"
							overlay={<Tooltip id="tooltip">Search</Tooltip>}
						>
							<div className="drawer_icon">
								<IconButton
									className="livemap_drawer_open_search"
									style={{
										background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
										padding: "8px",
										fontSize: "12px"
									}}
									onClick={() => {
										// this.props.setOpenDrawerBar(true)
										this.props.setDrawerOptions(prevState => ({
											// ...prevState,
											openSearch: !prevState.openSearch,
											openCreate: false
											// openPath: false,
											// openOccupancy: false,
											// openSatellite: false
										}))
									}}
								>
									<SearchIcon
										style={{
											fontSize: "20px",
											color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
										}}
									/>
								</IconButton>
							</div>
						</OverlayTrigger>
					) : (
						<div className="drawer_icon">
							<IconButton
								className="livemap_drawer_open_search"
								style={{
									background: this.props.drawerOptions.openSearch ? "#DDE9FD" : null,
									padding: "8px",
									fontSize: "12px"
								}}
								onClick={() => {
									// this.props.setOpenDrawerBar(true)
									this.props.setDrawerOptions(prevState => ({
										// ...prevState,
										openSearch: !prevState.openSearch,
										openCreate: false
										// openPath: false,
										// openOccupancy: false,
										// openSatellite: false
									}))
								}}
							>
								<SearchIcon
									style={{
										fontSize: "20px",
										color: this.props.drawerOptions.openSearch ? "#0277DA" : "#2C3E50"
									}}
								/>
							</IconButton>
						</div>
					)}

					<div
						id="drawer_class"
						className={
							this.props.openDrawerBar ? "expanded_drawer_anchors" : "collapsed_drawer"
						}
					>
						<div>
							{/* // ━━━━━━ Option - Filter General Info */}
							{/* {!this.props.drawerOptions.openFilter ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip id="tooltip">Filter</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_general_filter"
											style={{
												background: this.props.drawerOptions.openFilter
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "12px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openFilter: !prevState.openFilter,
													openSearch: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: false
												}))
											}}
										>
											<GrFilter
												style={{
													fontSize: "16px",
													filter: this.props.drawerOptions.openFilter
														? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
														: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_general_filter"
										style={{
											background: this.props.drawerOptions.openFilter ? "#DDE9FD" : null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: !prevState.openFilter,
												openSearch: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: false
											}))
										}}
									>
										<GrFilter
											style={{
												fontSize: "16px",
												filter: this.props.drawerOptions.openFilter
													? "invert(37%) sepia(95%) saturate(1594%) hue-rotate(187deg) brightness(83%) contrast(108%)"
													: "invert(21%) sepia(9%) saturate(1914%) hue-rotate(170deg) brightness(100%) contrast(92%)"
											}}
										/>
									</IconButton>
								</div>
							)} */}

							{/* // ━━━━━━ Option - Path / Routes */}
							{/* {!this.props.drawerOptions.openPath ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={
										<Tooltip id="tooltip">
											{!this.props.showPathOnMap && !this.props.pathFound
												? "Path"
												: "Clear Path"}{" "}
										</Tooltip>
									}
								>
									<div className="drawer_icon">
										{!this.props.showPathOnMap && !this.props.pathFound ? (
											<IconButton
												className="livemap_drawer_open_indoor_nav"
												style={{
													background: this.props.drawerOptions.openPath
														? "#DDE9FD"
														: null,
													padding: "10px",
													fontSize: "12px"
												}}
												onClick={() => {
													this.props.setDrawerOptions(prevState => ({
														openFilter: false,
														openSearch: false,
														openPath: !prevState.openPath,
														openOccupancy: false,
														openSatellite: false
													}))
												}}
											>
												<DirectionsOutlinedIcon
													style={{
														fontSize: "19px",
														color: this.props.drawerOptions.openPath
															? "#0277DA"
															: "#2C3E50"
													}}
												/>
											</IconButton>
										) : (
											<IconButton
												className="livemap_drawer_open_indoor_nav"
												style={{
													background: "#FBE6DE",
													padding: "10px",
													fontSize: "12px"
												}}
												onClick={() => {
													this.props.resetPathButton()
													this.props.setShowPathOnMap(false)
												}}
											>
												<MdOutlineDirectionsOff
													style={{
														fontSize: "19px",
														filter:
															"invert(38%) sepia(92%) saturate(3356%) hue-rotate(356deg) brightness(96%) contrast(87%)"
													}}
												/>
											</IconButton>
										)}
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										style={{
											background: this.props.drawerOptions.openPath ? "#DDE9FD" : null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openFilter: false,
												openSearch: false,
												openPath: !prevState.openPath,
												openOccupancy: false,
												openSatellite: false
											}))
										}}
									>
										<DirectionsOutlinedIcon
											style={{
												fontSize: "19px",
												color: this.props.drawerOptions.openPath ? "#0277DA" : "#2C3E50"
											}}
										/>
									</IconButton>
								</div>
							)} */}
						</div>
						<div>
							{/* // ━━━━━━ Option - Satellite view */}
							{/* {!this.props.drawerOptions.openSatellite ? (
								<OverlayTrigger
									trigger={["hover", "focus"]}
									placement="left"
									overlay={<Tooltip id="tooltip">Layers</Tooltip>}
								>
									<div className="drawer_icon">
										<IconButton
											className="livemap_drawer_open_satellite_view"
											style={{
												background: this.props.drawerOptions.openSatellite
													? "#DDE9FD"
													: null,
												padding: "10px",
												fontSize: "12px"
											}}
											onClick={() => {
												this.props.setDrawerOptions(prevState => ({
													openSearch: false,
													openFilter: false,
													openPath: false,
													openOccupancy: false,
													openSatellite: !prevState.openSatellite
												}))
											}}
										>
											<FiLayers
												style={{
													fontSize: "16px",
													color: this.props.drawerOptions.openSatellite
														? "#0277DA"
														: "#2C3E50"
												}}
											/>
										</IconButton>
									</div>
								</OverlayTrigger>
							) : (
								<div className="drawer_icon">
									<IconButton
										className="livemap_drawer_open_satellite_view"
										style={{
											background: this.props.drawerOptions.openSatellite
												? "#DDE9FD"
												: null,
											padding: "10px",
											fontSize: "12px"
										}}
										onClick={() => {
											this.props.setDrawerOptions(prevState => ({
												openSearch: false,
												openFilter: false,
												openPath: false,
												openOccupancy: false,
												openSatellite: !prevState.openSatellite
											}))
										}}
									>
										<FiLayers
											style={{
												fontSize: "16px",
												color: this.props.drawerOptions.openSatellite
													? "#0277DA"
													: "#2C3E50"
											}}
										/>
									</IconButton>
								</div>
							)} */}
						</div>
					</div>
					{/* ━━━━━━ Open/Close Drawer  */}
					{/* <div
						className="expand_button_drawer"
						onClick={() => {
							// Close all options when drawer bar closes
							this.props.setDrawerOptions({
								openSearch: false
								// openFilter: false,
								// openPath: false,
								// openOccupancy: false,
								// openSatellite: false
							})

							this.props.setOpenDrawerBar(prev => !prev) // Close drawer bar
						}}
					>
						<div style={{ width: "42px", background: "#BDC3C7", height: "1px" }} />
						{icon}
					</div> */}
				</div>
			</div>
		)

		ReactDOM.render(this.content, this.container)
		return this.container
	}

	onRemove() {
		this.container.parentNode && this.container.parentNode.removeChild(this.container)
		this.map = undefined
	}
}
