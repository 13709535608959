import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import { shallowEqual, useSelector } from "react-redux"
import * as UIHelpers from "../UIHelpers"
import { sortCaret } from "../../../../../_metronic/_helpers"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Toolbar } from "./toolbar/Toolbar"
import moment from "moment"
import { Dropdown } from "react-bootstrap"

export function ContactTracingTable() {
	const { entities, floorPlans } = useSelector(
		state => ({
			entities: state.contactTracing.contactTrace,
			floorPlans: state.basePage.floorPlans
		}),
		shallowEqual
	)

	const columns = [
		{
			dataField: "date",
			text: "Date",
			sort: true,
			sortCaret: sortCaret,
			formatter: cell => moment(cell).format("ddd MMM D YYYY")
		},
		// {
		// 	dataField: "floorPlan",
		// 	text: "Floor",
		// 	sort: true,
		// 	sortCaret: sortCaret,
		// 	formatter: cell => {
		// 		if (!floorPlans) {
		// 			return "Unknown floor"
		// 		}
		// 		return floorPlans.find(val => val.id === cell)?.name
		// 	}
		// },
		{
			dataField: "area",
			text: "Area",
			sort: true,
			sortCaret: sortCaret
		},
		{
			dataField: "time",
			text: "Time",
			sort: true,
			sortCaret: sortCaret,
			formatter: cell => {
				// return cell ? `0${(cell / 60) ^ 0}`.slice(-2) + ":" + ("0" + (cell % 60)).slice(-2) + " h" : ""
				return cell
					? `0${(cell / 60) ^ 0}`.slice(-2) + "h " + ("0" + (cell % 60)).slice(-2) + "m"
					: ""
			}
		}
	]

	const paginationOptions = paginationFactory({
		sizePerPageList: UIHelpers.sizePerPageList,
		sizePerPage: UIHelpers.initialFilter.pageSize,
		sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{currSizePerPage}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option.text}
								as="button"
								onClick={() => onSizePerPageChange(option.page)}
							>
								{option.text}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>
		)
	})

	return (
		<div className="ml-3 mr-3" data-testid="admin_contact-tracing-contactTracingTable">
			<ToolkitProvider
				search
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center"
				bootstrap4
				keyField="id"
				data={entities || []}
				columns={columns}
			>
				{props => (
					<div>
						{/* <Toolbar />
          <hr /> */}
						<BootstrapTable
							defaultSorted={UIHelpers.defaultSorted}
							bordered={false}
							pagination={paginationOptions}
							{...props.baseProps}
						></BootstrapTable>
					</div>
				)}
			</ToolkitProvider>
		</div>
	)
}
