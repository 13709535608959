import React from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography/Bold"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"
import { AddInventoryFirmwaresModalForm } from "./AddInventoryFirmwaresModalForm"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"

const AddInventoryFirmwaresModal = ({ setShowNewModelModal, technology }) => {
	function hide() {
		setShowNewModelModal(false)
	}

	const [saving, setSaving] = React.useState(false)

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\

	// ━━━━━━━━━━━━━  useSelector  ━━━━━━━━━━━━━ \\
	const { modelsInventory, listLoading, user } = useSelector(
		state => ({
			modelsInventory: state.inventory.modelsInventory,
			listLoading: state.inventory.listLoading,
			user: state.auth?.user
		}),
		shallowEqual
	)

	return (
		<Modal show={true} onHide={hide} backdrop="static" data-testid="AddInventoryFirmwaresModal">
			<Modal.Header className="px-5 py-2">
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>
						<Bold>NEW FIRMWARE</Bold>
					</div>
					<div className="col-auto">
						<IconButton
							onClick={() => {
								hide()
							}}
							style={{ left: "12px" }}
							data-testid="close-button"
							disabled={saving}
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>

			<Modal.Body
				style={{
					padding: "0"
				}}
			>
				{listLoading && <ModalProgressBar variant="query" />}
				<div className="p-5">
					{/* ━━★  BODY  ★━━ */}
					<AddInventoryFirmwaresModalForm
						modelsInventory={modelsInventory}
						setShowNewModelModal={setShowNewModelModal}
						listLoading={listLoading}
						technology={technology}
						user={user}
						onHide={hide}
						saving={saving}
						setSaving={setSaving}
					/>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default AddInventoryFirmwaresModal
