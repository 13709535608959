import React, { useEffect, useState, useMemo } from "react"
import { shallowEqual, useSelector } from "react-redux"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../_partials/typography/Bold"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"
import { EditInventoryModelsModalForm } from "./EditInventoryModelsModalForm"
import { useUIContext } from "../UIContext"

const EditInventoryModelsModal = ({
	showEditModal,
	setShowEditModal,
	technology,
	setSelectionModel
	// editedId,
	// setEditedId
}) => {
	function hide() {
		// setEditedId(null)
		UIProps.setIds([])
		setSelectionModel([])
		setShowEditModal(false)
		setShow(false)
	}

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [initialValues, setInitialValues] = useState(null)
	const [show, setShow] = useState(false)
	const [saving, setSaving] = useState(false)

	// ━━━━━━━━━━━━━  useSelector  ━━━━━━━━━━━━━ \\
	const { nodesInventory, modelsInventory, listLoading, user } = useSelector(
		state => ({
			nodesInventory: state.inventory.nodesInventory,
			modelsInventory: state.inventory.modelsInventory,
			listLoading: state.inventory.listLoading,
			user: state.auth.user
		}),
		shallowEqual
	)

	// ━━━━━━━━━━━━━  useUIContext  ━━━━━━━━━━━━━ \\
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.idsInventoryModels,
			setIds: UIContext.setIdsInventoryModels
		}
	}, [UIContext])

	// useEffect(() => {
	// 	if (!modelsInventory) return
	// 	console.log("🚀 ~ modelsInventory: ", modelsInventory)
	// }, [modelsInventory])

	useEffect(() => {
		if (
			!showEditModal ||
			!UIProps.ids ||
			UIProps.ids <= 0 ||
			!technology ||
			!modelsInventory ||
			modelsInventory <= 0
		)
			return

		const selectedModel =
			modelsInventory &&
			modelsInventory.length > 0 &&
			modelsInventory.find(
				model => model.id === (UIProps.ids && UIProps.ids.length > 0 && UIProps.ids[0])
			)

		// console.log("🪲 ~ useEffect ~ selectedModel:", selectedModel)

		if (!selectedModel) return
		const initial = {
			// ...selectedModel
			modelId: selectedModel.id,
			name: selectedModel.name || "",
			vendor: selectedModel.vendor || "",
			type: selectedModel.type || "",
			nodeType: selectedModel.nodeType || "",
			battery: selectedModel.battery || "0",
			chipset: selectedModel.chipset || "",
			// features: {
			button: selectedModel.features.button || false,
			motion: selectedModel.features.motion || false, //motion
			buzzer: selectedModel.features.buzzer || false,
			nfc: selectedModel.features.nfc || false,
			// },
			LEDs: selectedModel.LEDs || "0",
			ipProtection: selectedModel.ipProtection || "",
			images: selectedModel.images || []
		}
		// console.log("🪲 ~ useEffect ~ initial:", initial)

		setInitialValues(initial)
		// setShowEditModal(true)
		setShow(true)
	}, [showEditModal, UIProps.ids, technology])

	return (
		<Modal show={show} backdrop="static" onHide={hide} data-testid="EditInventoryModelsModal">
			<Modal.Header className="px-5 py-2">
				{/* ━━★  TITLE  ★━━ */}
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>
						<Bold>EDIT MODELS</Bold>
					</div>
					<div className="col-auto">
						<IconButton
							disabled={saving}
							onClick={() => {
								hide()
							}}
							style={{ left: "12px" }}
							data-testid="close-button"
						>
							<CloseIcon />
						</IconButton>
					</div>
				</div>
			</Modal.Header>

			<Modal.Body
				style={{
					padding: "0"
				}}
			>
				{/* {isLoading && <ModalProgressBar variant="query" />} */}
				<div className="p-5">
					{/* ━━★  BODY  ★━━ */}
					{initialValues && (
						<EditInventoryModelsModalForm
							onHide={hide}
							initialValues={initialValues}
							setInitialValues={setInitialValues}
							listLoading={listLoading}
							technology={technology}
							user={user}
							saving={saving}
							setSaving={setSaving}
						/>
					)}
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default EditInventoryModelsModal
