import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
import moment from "moment"
import { IoLocationSharp } from "react-icons/io5"
import { Button } from "../../../../../_partials/ButtonOLD"
import PopoverStickOnHover from "../../PopoverStickOnHover"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import { polygon, point, booleanPointInPolygon } from "@turf/turf"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
// Active
import personImageActive from "../../../../../assets/dashboard_markers/newMarkers/Marker-people-active.png"
import assetImageActive from "../../../../../assets/dashboard_markers/newMarkers/Marker-assets-active.png"
import importantImageActive from "../../../../../assets/dashboard_markers/newMarkers/Marker-important-active.png"
import unassignedImageActive from "../../../../../assets/dashboard_markers/newMarkers/Marker-unassigned-active.png"
// Idle
import personImageIdle from "../../../../../assets/dashboard_markers/newMarkers/Marker-people-idle.png"
import assetImageIdle from "../../../../../assets/dashboard_markers/newMarkers/Marker-assets-idle.png"
import importantImageIdle from "../../../../../assets/dashboard_markers/newMarkers/Marker-important-idle.png"
import unassignedImageIdle from "../../../../../assets/dashboard_markers/newMarkers/Marker-unassigned-idle.png"
// SOS
import personImageSOS from "../../../../../assets/dashboard_markers/newMarkers/Marker-people-sos.png"
import assetImageSOS from "../../../../../assets/dashboard_markers/newMarkers/Marker-assets-sos.png"
import importantImageSOS from "../../../../../assets/dashboard_markers/newMarkers/Marker-important-sos.png"
import unassignedImageSOS from "../../../../../assets/dashboard_markers/newMarkers/Marker-unassigned-default.png"
import { pixelToSize } from "../../_helpers/ActionHelpers"
import { profileSlice } from "../../../../../redux/profile/profileSlice"
// marker render
import { calculateSize, MarkerContent } from "./markerRender"

export function addTagsLayersAndClusters(params) {
	const {
		map,
		geoJsonArray,
		customClaims,
		floorPlan,
		selectedSite,
		areas,
		peopleSize,
		assetsSize,
		unassignedTagsSize,
		setSendMessageModal,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		dispatch,
		isFullscreen,
		containerRef,
		companies
	} = params

	const _finalMarkers = geoJsonArray
		? geoJsonArray.map(d => {
				const firstSeenDate = d.firstSeen ? new Date(d.firstSeen.seconds * 1000) : null
				const lastSeenDate = d.lastSeen ? new Date(d.lastSeen.seconds * 1000) : null

				return {
					type: "Feature",
					properties: {
						id: d?.id,
						color: d?.color,
						floorPlanId: d?.floorPlanId,
						asset: d?.asset,
						person: d?.person,
						important: d?.important,
						role: d?.role,
						tagState: d?.tagState,
						uuid: d?.uuid,
						firstSeen: firstSeenDate,
						lastSeen: lastSeenDate,
						positioningAnchorInfo: d?.positioningAnchorInfo,
						gpsData: {
							longitude: d?.gpsData.longitude,
							latitude: d?.gpsData.latitude
						},
						siteId: selectedSite?.id
					},
					geometry: {
						type: "Point",
						coordinates: [d?.gpsData.longitude, d?.gpsData.latitude, 0.0]
					}
				}
		  })
		: []

	// console.log("🚀 🚀 🚀 🚀 🚀 🚀 🚀 🚀 LAYERS PEOPLE SIZE:", peopleSize)
	// console.log("🚀 🚀 🚀 🚀 🚀 🚀 🚀 🚀 _finalMarkers:", _finalMarkers)

	// Parse the images / visibility / colors
	_finalMarkers.forEach(feature => {
		const tag = feature.properties

		let newSize = peopleSize || peopleSize === 0 ? pixelToSize(peopleSize) : 0.4

		// Reset iconToMarker and iconVisible for each feature
		let iconToMarker = unassignedImageActive
		let imageId = "unassigned-active-marker"
		// let iconVisible = "visible"

		// ━━━ Active tags
		if (tag?.person && tag.role?.important && tag.tagState === "active") {
			// Important tags
			iconToMarker = importantImageActive
			imageId = "important-active-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "active") {
			// Person
			iconToMarker = personImageActive
			imageId = "person-active-marker"
		} else if (tag?.asset && tag.tagState === "active") {
			// Asset
			iconToMarker = assetImageActive
			imageId = "asset-active-marker"
			newSize = assetsSize || assetsSize === 0 ? pixelToSize(assetsSize) : 0.4
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "active") {
			// Unassigned tag active
			iconToMarker = unassignedImageActive
			imageId = "unassigned-active-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize =
				unassignedTagsSize || unassignedTagsSize === 0 ? pixelToSize(unassignedTagsSize) : 0.4
		}

		// ━━━ Idle tags
		if (tag?.person && tag.role?.important && tag.tagState === "idle") {
			// Important tags
			iconToMarker = importantImageIdle
			imageId = "important-idle-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "idle") {
			// Person
			iconToMarker = personImageIdle
			imageId = "person-idle-marker"
		} else if (tag?.asset && tag.tagState === "idle") {
			// Asset
			iconToMarker = assetImageIdle
			imageId = "asset-idle-marker"
			newSize = assetsSize || assetsSize === 0 ? pixelToSize(assetsSize) : 0.4
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "idle") {
			// Unassigned tag idle
			iconToMarker = unassignedImageIdle
			imageId = "unassigned-idle-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize =
				unassignedTagsSize || unassignedTagsSize === 0 ? pixelToSize(unassignedTagsSize) : 0.4
		}

		// ━━━ SOS tags
		if (tag?.person && tag.role?.important && tag.tagState === "SOS") {
			// Important tags
			iconToMarker = importantImageSOS
			imageId = "important-sos-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "SOS") {
			// Person
			iconToMarker = personImageSOS
			imageId = "person-sos-marker"
		} else if (tag?.asset && tag.tagState === "SOS") {
			// Asset
			iconToMarker = assetImageSOS
			imageId = "asset-sos-marker"
			newSize = pixelToSize(assetsSize)
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "SOS") {
			// Unassigned tag sos
			iconToMarker = unassignedImageSOS
			imageId = "unassigned-sos-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize = pixelToSize(unassignedTagsSize)
		}

		map.loadImage(iconToMarker, (error, img) => {
			if (error) {
				// console.log(`Error loading image ->`, error)
			}
			if (!img || error) return

			// If map already has the image we need to remove so there aren't any re-renders
			const mapHasImage = map.hasImage(imageId)
			// ↓ So don't get error of loading image with same sourceId
			if (mapHasImage) {
				map.removeImage(imageId)
			}

			// add icon image
			map.addImage(imageId, img) // Use imageId as the image ID
		})

		// Set the iconImage property in the GeoJSON feature
		feature.properties.iconImage = imageId
		feature.properties.newSize = newSize
		// feature.properties.iconVisible = iconVisible // Optionally, you can store visibility in properties as well
	})

	//note: new tags layers without clusters
	if (typeof map.getLayer("unclustered-point-new") !== "undefined") {
		map.removeLayer("unclustered-point-new")
	}
	if (typeof map.getLayer("unclustered-images-new") !== "undefined") {
		map.removeLayer("unclustered-images-new")
	}

	if (typeof map.getSource("pins-new") !== "undefined" && map.getSource("pins-new")) {
		map.removeSource("pins-new")
	}

	//note: old tags layers with clusters
	if (typeof map.getLayer("unclustered-point") !== "undefined") {
		map.removeLayer("unclustered-point")
	}
	if (typeof map.getLayer("unclustered-images") !== "undefined") {
		map.removeLayer("unclustered-images")
	}
	if (typeof map.getLayer("cluster-pins") !== "undefined") {
		map.removeLayer("cluster-pins")
	}
	if (typeof map.getLayer("cluster-pins-count") !== "undefined") {
		map.removeLayer("cluster-pins-count")
	}
	if (typeof map.getSource("pins") !== "undefined" && map.getSource("pins")) {
		// map.removeSource("pins")
		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("pins").setData({
			type: "FeatureCollection",
			features: _finalMarkers
		})
	}

	if (!map.getSource("pins")) {
		map.addSource("pins", {
			type: "geojson",
			maxzoom: 24,
			cluster: true,
			clusterRadius: 35,
			// clusterMaxZoom: 17,
			clusterMaxZoom: 22,
			// clusterMaxZoom: floorPlan?.mapOrientation?.zoom
			// 	? Math.round(floorPlan?.mapOrientation?.zoom)
			// 	: map.getZoom(),
			data: {
				type: "FeatureCollection",
				features: _finalMarkers
			}
		})
	}

	map.addLayer(
		{
			id: "unclustered-images",
			type: "symbol",
			source: "pins",
			filter: ["!", ["has", "point_count"]],
			layout: {
				"icon-image": ["get", "iconImage"],
				// "icon-size": 0.8,
				// "icon-size": 0.4,
				"icon-size": ["get", "newSize"], // get newSize prop from object
				"symbol-sort-key": ["get", "iconImage"],
				"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
				"icon-ignore-placement": true // Ignore placement for better clustering behavior
			}
			// paint: {
			// 	"icon-opacity": ["match", ["get", "iconVisible"], "visible", 1, 0]
			// 	// Optionally adjust icon-opacity based on visibility property
			// }
		},
		"country-label"
	)

	map.addLayer(
		{
			id: "cluster-pins",
			type: "circle",
			source: "pins",
			filter: ["all", ["has", "point_count"]],
			paint: {
				"circle-color": {
					type: "interval",
					property: "point_count",
					stops: [
						[0, "#A791FE"],
						[20, "#635AFF"],
						[200, "#3F39A5"],
						[500, "#1C1C3B"]
					]
				},
				"circle-radius": 18,
				"circle-stroke-width": 2, // ← circle border
				"circle-stroke-color": "#ffffff"
			}
		},
		"country-label"
	)

	map.addLayer(
		{
			id: "cluster-pins-count",
			type: "symbol",
			source: "pins",
			layout: {
				"text-field": "{point_count}",
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12
			},
			paint: {
				"text-color": "#FFFFFF"
			}
		},
		"country-label"
	)

	// let popup
	const markersArray = []

	// Popups on unclustered-images
	map.on("mouseenter", "unclustered-images", e => {
		const feature = e.features[0]

		if (feature) {
			const foundedMarkerProps =
				_finalMarkers &&
				_finalMarkers.find(val => val?.properties?.id === feature?.properties?.id)
			const tag = {
				...foundedMarkerProps?.properties,
				gpsData: {
					longitude: feature?.geometry?.coordinates?.[0],
					latitude: feature?.geometry?.coordinates?.[1]
				}
			}

			const popOverStick = (
				<PopoverStickOnHover
					component={
						<div style={{ marginBottom: "-5px" }}>
							{customClaims?.isSuper && <DeveloperData tag={tag} />}
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "6px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									padding: "12px"
								}}
							>
								<PersonInfo
									tag={tag}
									floorPlan={floorPlan}
									areas={areas}
									companies={companies}
								/>
								<TimeInfo2DifferentParse tag={tag} />
								<ButtonRow
									tag={tag}
									setSendMessageModal={setSendMessageModal}
									floorPlan={floorPlan}
									setGetDirections={setGetDirections}
									geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
									setClickedToResetPath={setClickedToResetPath}
									setOpenDrawerBar={setOpenDrawerBar} //  opens New Path box on drawer
									setDrawerOptions={setDrawerOptions}
									setEndingPoint={setEndingPoint}
									dispatch={dispatch}
								/>
							</div>
						</div>
					}
					// onMouseEnter={() => {
					// 	console.log(tag.id, "feature.id")
					// 	// Handle mouse enter if needed
					// }}
					delay={200}
					containerRef={isFullscreen && containerRef}
				>
					<div
						className="tags_unclustered_html_markers_dashboard"
						style={{
							width: "34px",
							height: "34px",
							// border: "3px solid purple",
							borderRadius: "50%",
							cursor: "pointer"
						}}
					></div>
				</PopoverStickOnHover>
			)
			// Append the popover to the marker's container
			const markerContainer = document.createElement("div")

			ReactDOM.render(popOverStick, markerContainer)
			const marker = new mapboxgl.Marker(markerContainer)
				.setLngLat(feature.geometry.coordinates)
				.addTo(map)

			markersArray.push(marker)
		}
	})

	map.on("zoomend", e => {
		// console.log("🚀  map.getZoom() zoomstart", map.getZoom())

		const markerEl = document.getElementsByClassName("tags_unclustered_html_markers_dashboard")

		if (markerEl) {
			markerEl.forEach(div => {
				div.remove()
				// div.style.display = "none"
			})
		}

		if (markersArray && markersArray.length !== 0) {
			markersArray.forEach(marker => {
				marker.remove()
			})
		}
	})

	// map.on("zoom", e => {
	// 	console.log("🚀  markersArray zoom:", markersArray)

	// })

	// map.on("mouseleave", "unclustered-images", e => {
	// 	// console.log("A mouseleave event occurred.")

	// 	map.getCanvas().style.cursor = ""

	// 	// 	const markerEl = document.getElementsByClassName("tags_unclustered_html_markers_dashboard")

	// 	// 	if (markerEl) {
	// 	// 		markerEl.forEach(div => {
	// 	// 			div.style.display = "none"
	// 	// 		})

	// 	// }

	// 	const currentZoom = map.getZoom()

	// 	if (currentZoom > 18) {
	// 		if (markersArray && markersArray.length !== 0) {
	// 			markersArray.forEach(marker => {
	// 				marker.remove()
	// 			})
	// 		}
	// 	}
	// })

	map.on("mouseenter", "cluster-pins", () => {
		// console.log("enter cluster")
		map.getCanvas().style.cursor = "pointer"
	})
	map.on("mouseleave", "cluster-pins", () => {
		// console.log("LEAVEEEE cluster")
		map.getCanvas().style.cursor = ""
	})
}

export function addTagsLayersWithoutClusters(params) {
	const {
		map,
		geoJsonArray,
		customClaims,
		floorPlan,
		selectedSite,
		areas,
		peopleSize,
		assetsSize,
		unassignedTagsSize,
		setSendMessageModal,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		dispatch,
		isFullscreen,
		containerRef,
		companies
	} = params

	const _finalMarkers = geoJsonArray
		? geoJsonArray.map(d => {
				const firstSeenDate = d.firstSeen ? new Date(d.firstSeen.seconds * 1000) : null
				const lastSeenDate = d.lastSeen ? new Date(d.lastSeen.seconds * 1000) : null

				return {
					type: "Feature",
					properties: {
						id: d?.id,
						color: d?.color,
						floorPlanId: d?.floorPlanId,
						asset: d?.asset,
						person: d?.person,
						important: d?.important,
						role: d?.role,
						tagState: d?.tagState,
						uuid: d?.uuid,
						firstSeen: firstSeenDate,
						lastSeen: lastSeenDate,
						positioningAnchorInfo: d?.positioningAnchorInfo,
						gpsData: {
							longitude: d?.gpsData.longitude,
							latitude: d?.gpsData.latitude
						},
						siteId: selectedSite?.id
					},
					geometry: {
						type: "Point",
						coordinates: [d?.gpsData.longitude, d?.gpsData.latitude, 0.0]
					}
				}
		  })
		: []

	// Parse the images / visibility / colors
	_finalMarkers.forEach(feature => {
		const tag = feature.properties

		let newSize = peopleSize || peopleSize === 0 ? pixelToSize(peopleSize) : 0.4

		// Reset iconToMarker and iconVisible for each feature
		let iconToMarker = unassignedImageActive
		let imageId = "unassigned-active-marker"
		// let iconVisible = "visible"

		// ━━━ Active tags
		if (tag?.person && tag.role?.important && tag.tagState === "active") {
			// Important tags
			iconToMarker = importantImageActive
			imageId = "important-active-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "active") {
			// Person
			iconToMarker = personImageActive
			imageId = "person-active-marker"
		} else if (tag?.asset && tag.tagState === "active") {
			// Asset
			iconToMarker = assetImageActive
			imageId = "asset-active-marker"
			newSize = assetsSize || assetsSize === 0 ? pixelToSize(assetsSize) : 0.4
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "active") {
			// Unassigned tag active
			iconToMarker = unassignedImageActive
			imageId = "unassigned-active-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize =
				unassignedTagsSize || unassignedTagsSize === 0 ? pixelToSize(unassignedTagsSize) : 0.4
		}

		// ━━━ Idle tags
		if (tag?.person && tag.role?.important && tag.tagState === "idle") {
			// Important tags
			iconToMarker = importantImageIdle
			imageId = "important-idle-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "idle") {
			// Person
			iconToMarker = personImageIdle
			imageId = "person-idle-marker"
		} else if (tag?.asset && tag.tagState === "idle") {
			// Asset
			iconToMarker = assetImageIdle
			imageId = "asset-idle-marker"
			newSize = assetsSize || assetsSize === 0 ? pixelToSize(assetsSize) : 0.4
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "idle") {
			// Unassigned tag idle
			iconToMarker = unassignedImageIdle
			imageId = "unassigned-idle-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize =
				unassignedTagsSize || unassignedTagsSize === 0 ? pixelToSize(unassignedTagsSize) : 0.4
		}

		// ━━━ SOS tags
		if (tag?.person && tag.role?.important && tag.tagState === "SOS") {
			// Important tags
			iconToMarker = importantImageSOS
			imageId = "important-sos-marker"
		} else if (tag?.person && !tag.role?.important && tag.tagState === "SOS") {
			// Person
			iconToMarker = personImageSOS
			imageId = "person-sos-marker"
		} else if (tag?.asset && tag.tagState === "SOS") {
			// Asset
			iconToMarker = assetImageSOS
			imageId = "asset-sos-marker"
			newSize = pixelToSize(assetsSize)
		} else if (!tag?.person && !tag.role?.important && !tag?.asset && tag.tagState === "SOS") {
			// Unassigned tag sos
			iconToMarker = unassignedImageSOS
			imageId = "unassigned-sos-marker"
			// iconVisible = "none" // Hide icon of unassigned tags
			newSize = pixelToSize(unassignedTagsSize)
		}

		map.loadImage(iconToMarker, (error, img) => {
			if (error) {
				// console.log(`Error loading image ->`, error)
			}
			if (!img || error) return

			// If map already has the image we need to remove so there aren't any re-renders
			const mapHasImage = map.hasImage(imageId)
			// ↓ So don't get error of loading image with same sourceId
			if (mapHasImage) {
				map.removeImage(imageId)
			}

			// add icon image
			map.addImage(imageId, img) // Use imageId as the image ID
		})

		// Set the iconImage property in the GeoJSON feature
		feature.properties.iconImage = imageId
		feature.properties.newSize = newSize
		// feature.properties.iconVisible = iconVisible // Optionally, you can store visibility in properties as well
	})

	//note: old tags layers to be removed
	if (typeof map.getLayer("unclustered-point") !== "undefined") {
		map.removeLayer("unclustered-point")
	}
	if (typeof map.getLayer("unclustered-images") !== "undefined") {
		map.removeLayer("unclustered-images")
	}
	if (typeof map.getLayer("cluster-pins") !== "undefined") {
		map.removeLayer("cluster-pins")
	}
	if (typeof map.getLayer("cluster-pins-count") !== "undefined") {
		map.removeLayer("cluster-pins-count")
	}
	if (typeof map.getSource("pins") !== "undefined" && map.getSource("pins")) {
		map.removeSource("pins")
	}

	//note: new tags layers without clusters
	if (typeof map.getLayer("unclustered-point-new") !== "undefined") {
		map.removeLayer("unclustered-point-new")
	}
	if (typeof map.getLayer("unclustered-images-new") !== "undefined") {
		map.removeLayer("unclustered-images-new")
	}

	if (typeof map.getSource("pins-new") !== "undefined" && map.getSource("pins-new")) {
		// map.removeSource("pins")
		// Note: instead of removing the source, with the setData() method we update the source with the newData and doesn't flicker all the tags when filtering.
		map.getSource("pins-new").setData({
			type: "FeatureCollection",
			features: _finalMarkers
		})
	}

	if (!map.getSource("pins-new")) {
		map.addSource("pins-new", {
			type: "geojson",
			maxzoom: 24,
			cluster: false,
			data: {
				type: "FeatureCollection",
				features: _finalMarkers
			}
		})
	}

	map.addLayer(
		{
			id: "unclustered-images-new",
			type: "symbol",
			source: "pins-new",
			filter: ["!", ["has", "point_count"]],
			layout: {
				"icon-image": ["get", "iconImage"],
				// "icon-size": 0.8,
				// "icon-size": 0.4,
				"icon-size": ["get", "newSize"], // get newSize prop from object
				"symbol-sort-key": ["get", "iconImage"],
				"icon-allow-overlap": true, // Allow icons to overlap if needed (this allows markers to overlap and not disappear)
				"icon-ignore-placement": true // Ignore placement for better clustering behavior
			}
			// paint: {
			// 	"icon-opacity": ["match", ["get", "iconVisible"], "visible", 1, 0]
			// 	// Optionally adjust icon-opacity based on visibility property
			// }
		},
		"country-label"
	)

	// let popup
	const markersArray = []

	// Popups on unclustered-images
	map.on("mouseenter", "unclustered-images-new", e => {
		const feature = e.features[0]

		if (feature) {
			const foundedMarkerProps =
				_finalMarkers &&
				_finalMarkers.find(val => val?.properties?.id === feature?.properties?.id)
			const tag = {
				...foundedMarkerProps?.properties,
				gpsData: {
					longitude: feature?.geometry?.coordinates?.[0],
					latitude: feature?.geometry?.coordinates?.[1]
				}
			}

			const popOverStick = (
				<PopoverStickOnHover
					component={
						<div style={{ marginBottom: "-5px" }}>
							{customClaims?.isSuper && <DeveloperData tag={tag} />}
							<div
								style={{
									background: "#ECF1F4",
									boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
									borderRadius: "6px",
									fontFamily: "Poppins",
									fontStyle: "normal",
									color: "#4A4A68",
									padding: "12px"
								}}
							>
								<PersonInfo
									tag={tag}
									floorPlan={floorPlan}
									areas={areas}
									companies={companies}
								/>
								<TimeInfo2DifferentParse tag={tag} />
								<ButtonRow
									tag={tag}
									setSendMessageModal={setSendMessageModal}
									floorPlan={floorPlan}
									setGetDirections={setGetDirections}
									geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
									setClickedToResetPath={setClickedToResetPath}
									setOpenDrawerBar={setOpenDrawerBar} //  opens New Path box on drawer
									setDrawerOptions={setDrawerOptions}
									setEndingPoint={setEndingPoint}
									dispatch={dispatch}
								/>
							</div>
						</div>
					}
					// onMouseEnter={() => {
					// 	console.log(tag.id, "feature.id")
					// 	// Handle mouse enter if needed
					// }}
					delay={200}
					containerRef={isFullscreen && containerRef}
				>
					<div
						className="tags_unclustered_html_markers_dashboard"
						style={{
							width: "34px",
							height: "34px",
							// border: "3px solid purple",
							borderRadius: "50%",
							cursor: "pointer"
						}}
					></div>
				</PopoverStickOnHover>
			)
			// Append the popover to the marker's container
			const markerContainer = document.createElement("div")

			ReactDOM.render(popOverStick, markerContainer)
			const marker = new mapboxgl.Marker(markerContainer)
				.setLngLat(feature.geometry.coordinates)
				.addTo(map)

			markersArray.push(marker)
		}
	})

	map.on("zoomend", e => {
		// console.log("zoomendddddd -------->>>><>")

		const markerEl = document.getElementsByClassName("tags_unclustered_html_markers_dashboard")

		if (markerEl) {
			markerEl.forEach(div => {
				div.remove()
				// div.style.display = "none"
			})
		}

		if (markersArray && markersArray.length !== 0) {
			markersArray.forEach(marker => {
				marker.remove()
			})
		}
	})
}

export function removeTagsLayers(params) {
	const { map, tag, index } = params

	if (!map) return

	if (map.getLayer(`tags_circle-${index}`)) {
		map.removeLayer(`tags_circle-${index}`)
	}
	if (map.getLayer(`tags_image-${index}`)) {
		map.removeLayer(`tags_image-${index}`)
	}
	if (map.getSource(`tags_source-${index}`)) {
		map.removeSource(`tags_source-${index}`)
	}
}

export function TagsMarkers(props) {
	const {
		tag,
		floorPlan,
		areas,
		customClaims,
		setSendMessageModal,
		containerRef,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		setClickedToResetPath,
		isFullscreen,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		dispatch,
		markersSize
	} = props

	const markerSize = tag.person
		? calculateSize(markersSize?.people || 0)
		: tag.asset
		? calculateSize(markersSize?.assets || 0)
		: calculateSize(markersSize?.unassignedTags || 0)

	const commonMarkerStyles = {
		width: markerSize,
		height: markerSize,
		position: "relative",
		overflow: "hidden",
		margin: "auto",
		backgroundColor: "transparent",
		border: "1px solid red",
		borderRadius: "50%",
		zIndex: 999
	}

	return (
		<PopoverStickOnHover
			component={
				<div style={{ marginBottom: "-5px" }}>
					{customClaims?.isSuper && <DeveloperData tag={tag} />}
					<div className="popover-content">
						<PersonInfo tag={tag} floorPlan={floorPlan} areas={areas} />
						<TimeInfo tag={tag} />
						<ButtonRow
							tag={tag}
							setSendMessageModal={setSendMessageModal}
							floorPlan={floorPlan}
							setGetDirections={setGetDirections}
							geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
							setClickedToResetPath={setClickedToResetPath}
							setOpenDrawerBar={setOpenDrawerBar}
							setDrawerOptions={setDrawerOptions}
							setEndingPoint={setEndingPoint}
							dispatch={dispatch}
						/>
					</div>
				</div>
			}
			placement="top"
			delay={500}
			containerRef={isFullscreen && containerRef}
		>
			<div
				className={`d-flex justify-content-center align-items-center slider-markers-size markers-${
					tag.person ? "people" : tag.asset ? "assets" : "unassignedTags"
				}`}
				style={commonMarkerStyles}
			>
				<MarkerContent tag={tag} size={markerSize} customClaims={customClaims} />
			</div>
		</PopoverStickOnHover>
	)
}

function DeveloperData({ tag }) {
	return (
		<div
			style={{
				background: "#ECF1F4",
				boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
				borderRadius: "6px",
				fontFamily: "Poppins",
				fontStyle: "normal",
				color: "#4A4A68",
				padding: "12px",
				marginBottom: "15px"
			}}
		>
			<div>
				<b>Super admin data:</b>
			</div>
			<div>
				<b>{"ID: "}</b>
				<span>{tag?.id}</span>
			</div>
			<div>
				<b>{"UUID: "}</b>
				<span>{tag?.uuid}</span>
			</div>
			{tag?.positioningAnchorInfo?.anchorAddress && (
				<div>
					<b>{"anchorAddress: "}</b>
					<span>{tag?.positioningAnchorInfo?.anchorAddress}</span>
				</div>
			)}

			{tag?.positioningAnchorInfo?.rssi && (
				<div>
					<b>{"rssi: "}</b>
					<span>{tag?.positioningAnchorInfo?.rssi}</span>
				</div>
			)}

			<div style={{ display: "inline-block" }}>
				<b>gpsData: </b>
				<span>{tag?.gpsData?.latitude},</span>
				<span>{tag?.gpsData?.longitude}</span>
			</div>
		</div>
	)
}

function PersonInfo(props) {
	const { tag, floorPlan, areas, companies } = props

	const defaultPicture = tag.person
		? "/media/crowdkeep/Person_Placeholder.svg"
		: tag.asset
		? "/media/crowdkeep/Asset_Cube_Placeholder.svg"
		: "/media/crowdkeep/Asset_Cube_Placeholder.svg"
	const picture = tag.person?.picture
	const name = tag.person?.personalData?.name || tag.asset?.assetDetails?.name

	const role = tag.person?.role
	const type = tag.asset?.assetDetails?.type
	const helmetId = tag.person?.helmetId

	const area =
		areas &&
		areas.find(val => {
			if (!val.polygon) return false

			var areapolygon = val.polygon.map(coord => [coord.longitude, coord.latitude])
			areapolygon.push(areapolygon[0])

			var poly = polygon([areapolygon])
			var tagpoint = point([tag.gpsData.longitude, tag.gpsData.latitude])
			var inside = booleanPointInPolygon(tagpoint, poly)

			return inside
		})

	const company =
		companies &&
		companies.find(val => {
			if (tag?.person) {
				return val.id === tag?.person?.companyId
			}
			if (tag?.asset) {
				return val.id === tag?.asset?.companyId
			}
		})

	return (
		<div className="d-flex">
			<div className="mr-3">
				<img
					alt="Person"
					src={picture || defaultPicture}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = defaultPicture
					}}
					style={{
						backgroundColor: "#FAFCFE",
						width: "86px",
						height: "72px",
						objectFit: "contain",
						borderRadius: "4px"
					}}
				/>
			</div>
			<div className="d-flex flex-column w-100">
				<div className="row">
					<div className="col">
						<span
							style={{
								fontWeight: 700,
								fontSize: "18px",
								lineHeight: "160%",
								whiteSpace: "nowrap"
							}}
						>
							{truncateName(name) || "Unassigned tag"}
						</span>
					</div>
					<div
						className="col"
						style={{
							textAlignLast: "end"
							// padding: "0"
						}}
					>
						{tag.person?.geofencing?.length > 0 && (
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip id="tags-geo-tooltip">Geofenced</Tooltip>}
							>
								<SVG
									style={{ fill: "#2673F0", height: "18px", width: "18px" }}
									src={toAbsoluteUrl("/media/crowdkeep/Geofenced.svg")}
								/>
							</OverlayTrigger>
						)}
					</div>
				</div>

				<span
					style={{
						fontWeight: 400,
						fontSize: "10px",
						lineHeight: "160%",
						marginBottom: "2px"
					}}
				>
					{truncateName(company?.company)} {company?.company && role && ",  "}
					{role ||
						type ||
						(!name && (
							<span>
								<b>{"UUID: "}</b>
								{tag.uuid}
							</span>
						))}
				</span>
				<div style={{ marginBottom: "5px" }}>
					<span
						style={{
							fontWeight: 400,
							fontSize: "10px",
							lineHeight: "160%"
						}}
					>
						{helmetId ? `Helmet ID - ${helmetId}` : null}
					</span>
				</div>
				<div>
					<IoLocationSharp
						style={{
							height: "18px",
							width: "18px",
							marginRight: "5px"
						}}
					/>
					<span
						style={{
							fontWeight: 300,
							fontSize: "10px",
							lineHeight: "160%"
						}}
					>
						{floorPlan?.name}
						{area && `, ${area.name}`}
					</span>
				</div>
			</div>
		</div>
	)
}

function TimeInfo(props) {
	const { tag } = props

	if (!tag.person && !tag.asset) return <div></div>

	const checkIn = tag.firstSeen ? moment(tag.firstSeen.toDate()).format("hh:mm A") : "--:--"
	const lastSeen = tag.lastSeen ? moment(tag.lastSeen.toDate()).format("hh:mm A") : "--:--"

	const minutesSinceCheckIn =
		tag.firstSeen &&
		tag.lastSeen &&
		moment(tag.lastSeen.toDate()).diff(moment(tag.firstSeen.toDate()), "minutes")

	const hours = minutesSinceCheckIn ? Math.floor(minutesSinceCheckIn / 60) : 0
	const minutes = minutesSinceCheckIn ? minutesSinceCheckIn % 60 : 0

	const total = `${hours}h ${minutes.toString().padStart(2, "0")}min`

	return (
		<div className="d-flex mt-3">
			<div
				className="flex-fill mr-3"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					First Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{checkIn}
				</div>
			</div>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Last Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{lastSeen}
				</div>
			</div>
			<div
				className="flex-fill ml-3"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Total Hours
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{total}
				</div>
			</div>
		</div>
	)
}

function TimeInfo2DifferentParse(props) {
	const { tag } = props

	if (!tag.person && !tag.asset) return <div></div>

	// const checkIn = tag.firstSeen ? moment(tag.firstSeen) : "--:--"
	// const lastSeen = tag.lastSeen ? moment(tag.lastSeen) : "--:--"

	const firstSeenDate = tag.firstSeen ? moment(tag.firstSeen) : null
	const lastSeenDate = tag.lastSeen ? moment(tag.lastSeen) : null

	const checkIn = firstSeenDate ? firstSeenDate.format("hh:mm A") : "--:--"
	const lastSeen = lastSeenDate ? lastSeenDate.format("hh:mm A") : "--:--"

	const minutesSinceCheckIn =
		firstSeenDate && lastSeenDate && lastSeenDate.diff(firstSeenDate, "minutes")

	const hours = minutesSinceCheckIn ? Math.floor(minutesSinceCheckIn / 60) : 0
	const minutes = minutesSinceCheckIn ? minutesSinceCheckIn % 60 : 0

	const total = `${hours}h ${minutes.toString().padStart(2, "0")}min`

	return (
		<div className="d-flex mt-3">
			<div
				className="flex-fill mr-3"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					First Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{checkIn}
				</div>
			</div>
			<div
				className="flex-fill"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Last Seen
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{lastSeen}
				</div>
			</div>
			<div
				className="flex-fill ml-3"
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Total Hours
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "11px",
						color: "#4A4A68"
					}}
				>
					{total}
				</div>
			</div>
		</div>
	)
}

function ButtonRow(props) {
	const {
		tag,
		setSendMessageModal,
		floorPlan,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		dispatch
	} = props

	return (
		<div className="d-flex mt-3">
			<div className="flex-fill mr-1">
				<Button
					className="w-100"
					onClick={() => {
						// ↓ if no routes drawn display message to user and don't show directions box
						if (!floorPlan?.geoJsonRoutes || floorPlan?.geoJsonRoutes.features.length === 0) {
							geoJsonRoutesUndefinedSnackBar(floorPlan)
							return
						}

						// const foundedPerson = people && people.find(option => tag.uuid === option.uuid)

						// if (!foundedPerson) {
						// 	const paragraphToaster = (
						// 		<p style={{ textAlign: "center" }}>
						// 			This tag has no person information.
						// 			<br />
						// 			<span>Can't display path with this tag.</span>
						// 		</p>
						// 	)

						// 	dispatch({
						// 		type: "SNACKBAR_ERROR",
						// 		payload: paragraphToaster
						// 	})

						// 	return
						// }

						const name = tag.person?.personalData?.name || tag.asset?.name || tag.uuid

						if (!name || !tag?.gpsData || !tag?.floorPlanId || !tag?.siteId) {
							dispatch({
								type: "SNACKBAR_ERROR",
								payload: "Error searching for a path to this tag"
							})
							return
						}

						const tagWithPersonalData = {
							group: tag.person ? "People" : tag.asset ? "Assets" : "Unassigned",
							name: name,
							gpsData: tag?.gpsData,
							floorPlanId: tag?.floorPlanId,
							siteId: tag?.siteId, // Added the siteId
							...tag
						}

						// ↓ Select Tag has start point in directions box
						setGetDirections({
							type: tag.person ? "people" : tag.asset ? "assets" : "unassigned",
							feature: tagWithPersonalData,
							clicked: true,
							group: tag.person ? "People" : tag.asset ? "Assets" : "Unassigned",
							floorPlanId: floorPlan?.id
						})

						setEndingPoint(tagWithPersonalData) // new endingPoint

						// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
						// // Set a copy of start point (that doesn't close directions box)
						// setCopyOfStartPointCoords([
						//   tag.gpsData.longitude,
						//   tag.gpsData.latitude,
						// ]);

						// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
						// // ↓ Empty go to in directions box
						// setTravelTo()

						// ↓ Reset Path
						setClickedToResetPath(true)

						// ↓ opens New Path box on drawer
						setOpenDrawerBar(true)
						setDrawerOptions({
							openSearch: false,
							openFilter: false,
							openPath: true,
							openOccupancy: false,
							openSatellite: false,
							openSlider: false
						})
					}}
				>
					<span
						className="mr-2"
						style={{
							fontSize: "12px",
							letterSpacing: "0.56px",
							fontFamily: "Poppins",
							fontWeight: 700,
							fontStyle: "normal",
							lineHeight: "normal"
						}}
					>
						Directions
					</span>
					<img alt="Directions" src="/icons/directions.svg" />
				</Button>
			</div>
			{tag.person && (
				<>
					<div className="flex-fill mr-1 ml-1">
						<Button
							disabled={!tag.person?.personalData?.phoneNumber}
							className="w-100"
							variant="outlined"
						>
							<span className="mr-2">Call</span>
							<img alt="Directions" src="/icons/phone.svg" />
						</Button>
					</div>
					<div className="flex-fill ml-1">
						<Button
							disabled={!tag.person?.UID && !tag.person?.personalData?.phoneNumber}
							className="w-100"
							variant="outlined"
							onClick={() => {
								if (tag.person?.UID || tag.person?.personalData?.phoneNumber) {
									setSendMessageModal({
										person: tag.person
									})
								}
							}}
						>
							<span className="mr-2">Text</span>
							<img alt="Directions" src="/icons/email.svg" />
						</Button>
					</div>
				</>
			)}
		</div>
	)
}

// // Note: Function below not being used
// export function addTagsHasLayers(params) {
// 	// console.log("🚀  params:", params)

// 	const { map, tag, index } = params

// 	// ━━━ Remove sources and layers ━━━ \\
// 	// typeof map.getLayer(`tags_circle-${index}`) !== "undefined" &&
// 	// 	map.removeLayer(`tags_circle-${index}`)
// 	// typeof map.getLayer(`tags_image-${index}`) !== "undefined" &&
// 	// 	map.removeLayer(`tags_image-${index}`)
// 	// typeof map.getSource(`tags_source-${index}`) !== "undefined" &&
// 	// 	map.removeSource(`tags_source-${index}`)

// 	if (map.getLayer(`tags_circle-${index}`)) {
// 		map.removeLayer(`tags_circle-${index}`)
// 	}
// 	if (map.getLayer(`tags_image-${index}`)) {
// 		map.removeLayer(`tags_image-${index}`)
// 	}

// 	if (map.getSource(`tags_source-${index}`)) {
// 		map.removeSource(`tags_source-${index}`)
// 	}

// 	const fetchedLng = tag?.gpsData?.longitude
// 	const fetchedLat = tag?.gpsData?.latitude

// 	// console.log("🎃 🎃 🎃 🎃 tag", tag)
// 	// console.log("🎃 🎃 🎃 🎃 tag.gpsData", tag.gpsData)

// 	if (!fetchedLng || !fetchedLat) return

// 	// ━━━━ Choose icon for each tag type
// 	let iconToMarker = personImageActive
// 	let iconVisible = "visible"

// 	// important tags
// 	if (tag?.person && tag.role?.important) {
// 		iconToMarker = importantImageActive
// 	}
// 	// person
// 	if (tag?.person && !tag.role?.important) {
// 		iconToMarker = personImageActive
// 	}
// 	// asset
// 	if (tag?.asset) {
// 		iconToMarker = assetImageActive
// 	}
// 	// unassigned tags
// 	if (!tag.person && !tag.asset) {
// 		// hide icon of unassigned tags
// 		iconVisible = "none"
// 	}

// 	map.loadImage(iconToMarker, (error, image) => {
// 		if (error) {
// 			console.log("error on map.loadImage", error)
// 		}

// 		if (!image) return

// 		// If map already has the image we need to remove so there aren't any re-renders
// 		const mapHasImage = map.hasImage(`custom_tags_marker_icon-${index}`)
// 		// ↓ So don't get error of loading image with same sourceId
// 		if (mapHasImage) {
// 			map.removeImage(`custom_tags_marker_icon-${index}`)
// 		}

// 		// add icon image
// 		map.addImage(`custom_tags_marker_icon-${index}`, image)

// 		// Add source
// 		if (!map.getSource(`tags_source-${index}`)) {
// 			map.addSource(`tags_source-${index}`, {
// 				type: "geojson",
// 				// cluster: true,
// 				// clusterRadius: 25,
// 				// clusterMaxZoom: 18,
// 				data: {
// 					type: "FeatureCollection",
// 					features: [
// 						{
// 							type: "Feature",
// 							geometry: {
// 								type: "Point",
// 								coordinates: [fetchedLng, fetchedLat]
// 							}
// 						}
// 					]
// 				}
// 			})
// 		}

// 		// add circle layer
// 		if (!map.getLayer(`tags_circle-${index}`)) {
// 			map.addLayer(
// 				{
// 					id: `tags_circle-${index}`,
// 					source: `tags_source-${index}`,
// 					type: "circle",
// 					paint: {
// 						// "circle-radius": 14,
// 						"circle-radius": 14,
// 						"circle-color": tag.color,
// 						// "circle-color": "#FFBA57",
// 						"circle-opacity": 1,
// 						"circle-stroke-width": 2, // ← circle border
// 						"circle-stroke-color": "#ffffff",
// 						"circle-stroke-opacity": 1

// 					}
// 				},
// 				"country-label"
// 			)
// 		}

// 		// add icon image on top of circle
// 		if (!map.getLayer(`tags_image${index}`)) {
// 			// Add image layer
// 			map.addLayer(
// 				{
// 					id: `tags_image-${index}`,
// 					type: "symbol",
// 					source: `tags_source-${index}`,
// 					layout: {
// 						"icon-image": `custom_tags_marker_icon-${index}`,
// 						// "icon-size": 0.85,
// 						"icon-size": 0.4,
// 						// "icon-size": 0.02,
// 						visibility: iconVisible
// 					},
// 					paint: {
// 						"icon-opacity": 1
// 					}
// 				},
// 				"country-label"
// 			)
// 		}
// 	})

// 	// // Add source
// 	// map.addSource(`tags_source-${index}`, {
// 	// 	type: "geojson",
// 	// 	data: {
// 	// 		type: "FeatureCollection",
// 	// 		features: [
// 	// 			{
// 	// 				type: "Feature",
// 	// 				geometry: {
// 	// 					type: "Point",
// 	// 					coordinates: [fetchedLng, fetchedLat]
// 	// 				}
// 	// 			}
// 	// 		]
// 	// 	}
// 	// })

// 	// // add circle layer
// 	// if (!map.getLayer(`tags_circle-${index}`)) {
// 	// 	map.addLayer(
// 	// 		{
// 	// 			id: `tags_circle-${index}`,
// 	// 			source: `tags_source-${index}`,
// 	// 			type: "circle",
// 	// 			paint: {
// 	// 				"circle-radius": 16,
// 	// 				"circle-color": "#FFBA57",
// 	// 				"circle-opacity": 0.9,
// 	// 				"circle-stroke-width": 2, // ← circle border
// 	// 				"circle-stroke-color": "#ffffff",
// 	// 				"circle-stroke-opacity": 0.9
// 	// 			}
// 	// 		},
// 	// 		"country-label"
// 	// 	)
// 	// }
// }

function truncateName(name) {
	if (!name) return

	if (name.length <= 10) {
		return name
	} else {
		const namesArray = name && name.split(" ")
		let firstName = namesArray[0]

		if (firstName.length > 10) {
			// Split the first name with a dot if it's longer than 10 characters
			firstName = `${firstName.slice(0, 10)}.`
		}

		const middleInitials = namesArray.slice(1, -1).map(name => name.charAt(0) + ".")
		const lastName = namesArray[namesArray.length - 1]

		return `${firstName} ${middleInitials.join(" ")} ${lastName}`
	}
}
