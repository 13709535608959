import React, { useMemo, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { useUIContext } from "../../UIContext"
import ToggleLiveMapButton from "./ToggleLiveMapButton"

const DashboardTop = () => {
	return (
		<div
			className="d-flex align-items-center justify-content-between mb-4"
			style={{ width: "100%" }}
		>
			<div className="col-4">
				<div className="d-flex align-items-center" style={{ paddingTop: "8px" }}>
					<div
						style={{
							width: "35px",
							height: "40px"
						}}
					>
						<img
							src="../icons/dashboard-home.svg"
							style={{ fontSize: "23px", color: "#4A4A68" }}
						/>
					</div>

					<h2 style={{ color: "#4A4A68" }}>
						<b>Dashboard</b>
					</h2>
				</div>
			</div>
			<ToggleLiveMapButton />
		</div>
	)
}

export default DashboardTop
