import { createSlice } from "@reduxjs/toolkit"

const initialProductsState = {
	listLoading: false,
	actionsLoading: false,
	floorPlans: null,
	iconsFromDB: [],
	lastError: null,
	drawerPreferences: []
}
export const callTypes = {
	list: "list",
	action: "action"
}

export const liveDataSlice = createSlice({
	name: "liveData",
	initialState: initialProductsState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false
			} else {
				state.actionsLoading = false
			}
		},
		startCall: (state, action) => {
			state.error = null
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true
			} else {
				state.actionsLoading = true
			}
		},
		iconsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.iconsFromDB = action.payload
		},
		thingsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.things = action.payload
		},
		tagsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.tags = action.payload
		},
		tagsWithPeopleFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.tagsWithPeople = action.payload
		},
		activeTagsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.activeTags = action.payload
		},
		bleDevicesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.bleDevices = action.payload
		},
		visitorsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.visitors = entities
		},
		guestsFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.guests = entities
		},
		peopleStatsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.peopleStats = action.payload
		},
		dailyUsersFetched: (state, action) => {
			const { entities } = action.payload
			state.listLoading = false
			state.error = null
			state.dailyUsers = entities
		},
		usersFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.users = action.payload
		},
		poisFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.pois = action.payload
		},
		sensorsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.sensors = action.payload
		},
		zonesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.zones = action.payload
		},
		areasFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.areas = action.payload
		},
		resourcesMeetingsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.resourcesMeetings = action.payload
		},
		allLocationsPeopleStatsFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allLocationsPeopleStats = action.payload
		},
		allLocationsMerakiDevicesFetched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.allLocationsMerakiDevices = action.payload
		},
		addGateways: (state, action) => {
			state.listLoading = false
			state.error = null
			state.gateways = action.payload
			// state.gateways = state.gateways ? [...state.gateways, ...action.payload] : [action.payload]
		},
		addAnchors: (state, action) => {
			state.listLoading = false
			state.error = null
			state.anchors = action.payload
			// state.anchors = state.anchors ? [...state.anchors, ...action.payload] : [action.payload]
		},
		weatherFeched: (state, action) => {
			state.listLoading = false
			state.error = null
			state.weather = action.payload
		},
		userPrefsDrawer: (state, action) => {
			state.listLoading = false
			state.error = null
			state.drawerPreferences = action.payload
		},
		userPrefsMapView: (state, action) => {
			state.listLoading = false
			state.error = null
			state.mapViewPreferences = action.payload
		}
	}
})
