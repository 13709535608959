import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as actions from "../../../_redux/admin/inventory/InventoryActions"
import ImageUploading from "react-images-uploading"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { Button } from "../../../../_partials/Button"
import { Input } from "../../../../_partials/inputs/Input"
import { Checkbox } from "../../../../_partials/inputs/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers"
import { Autocomplete_People } from "../../../../_partials/inputs/Autocomplete_PeopleTable"
import { startButtonProgress, finishButtonProgress } from "../../utils/utils"

const EditSchema = Yup.object().shape({
	name: Yup.string().nullable().trim().min(1, "Name must not be empty"),
	vendor: Yup.string().nullable().trim().min(1, "Vendor Vendor must not be empty"),
	type: Yup.string().nullable().trim().min(1, "Type must not be empty"),
	nodeType: Yup.string().nullable().trim().min(1, "Notes must not be empty"),
	LEDs: Yup.string().nullable().trim().min(1, "LEDs must not be empty"),
	ipProtection: Yup.string().nullable().trim().min(1, "Ip protection must not be empty"),
	battery: Yup.string().nullable().trim().min(1, "Battery must not be empty"),
	chipset: Yup.string().nullable().trim().min(1, "Chipset must not be empty")
})

const useStyles = makeStyles(theme => ({
	checkbox: {
		borderRadius: "4px",
		//*figma
		// background: "#F7F7F7",
		background: "#ECF1F4",
		width: "100%",
		"& .MuiFormControlLabel-label": {
			color: "#4A4A68",
			textAlign: "center",
			fontFamily: "Poppins",
			fontSize: "14px",
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px"
		}
	}
}))

const ipProtection_list = [
	{ value: "ip65", name: "IP65" },
	{ value: "ip66", name: "IP66" },
	{ value: "ip67", name: "IP67" },
	{ value: "ip68", name: "IP68" }
]

const nodeType_list = [
	{ value: "anchor", name: "anchor" },
	{ value: "tag", name: "tag" }
]

const type_list = [
	{ value: "card", name: "Card" },
	{ value: "bracelet", name: "Bracelet" },
	{ value: "keyfob", name: "Keyfob" },
	{ value: "helmet", name: "Helmet" }
	// { value: "sensor", name: "Sensor" },
	// { value: "anchor", name: "Anchor" }
]

const chipset_list = [
	{ value: "NORDIC NRF 52832", name: "NORDIC NRF 52832" },
	{ value: "NORDIC NRF 52833", name: "NORDIC NRF 52833" },
	{ value: "NORDIC NRF 54840", name: "NORDIC NRF 54840" }
]

export function EditInventoryModelsModalForm({
	onHide,
	initialValues,
	setInitialValues,
	listLoading,
	technology,
	user,
	saving,
	setSaving
}) {
	const dispatch = useDispatch()
	const classes = useStyles()

	// ━━━━━━━━━━━━━  useState  ━━━━━━━━━━━━━ \\
	const [formValues, setFormValues] = useState(initialValues)
	const [images, setImages] = useState([])
	const [imagesPathToDelete, setImagesPathToDelete] = useState([])
	// ━━━━━━━━━━━━━  useEffect  ━━━━━━━━━━━━━ \\
	// ━━━━━━━━━━━━━  handlers  ━━━━━━━━━━━━━ \\
	const getUserPic = index => {
		if (images[index]?.data_url) return `url(${images[index].data_url})`
		else if (images[index]) return `url(${images[index]})`
		else return `url(${toAbsoluteUrl("/media/logos/blank_2.svg")}`
	}

	useEffect(() => {
		if (!formValues) return
		setImages(formValues.images)
	}, [formValues])

	// Function to convert file to Base64 encoding
	const fileToBase64 = async file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result.split(",")[1]) // Removes data URL prefix
			reader.onerror = error => reject(error)
		})
	}
	// Function to validate file type
	const isValidFileType = file => {
		const allowedExtensions = ["png", "jpg", "jpeg"] // List allowed file extensions
		const fileExtension = file?.name.split(".").pop().toLowerCase()

		return allowedExtensions.includes(fileExtension)
	}

	const onSubmit = async values => {
		if (!formValues || !technology || !user.id) return

		setSaving(true)
		// Starts the change of the saving UI button like progress bar
		const buttonToProgressEl = document.getElementById("buttonToProgress_editPoi")
		buttonToProgressEl &&
			startButtonProgress(buttonToProgressEl, "button__progress_editPoi", "button__text_editPoi")

		const hasFile = formValues.images.some(image => image.file instanceof File)

		let base64Images = []
		if (hasFile) {
			for (const image of formValues.images) {
				if (!isValidFileType(image.file)) {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload:
							"Invalid file type! Please select files of the following types: png, jpg, jpeg."
					})
					return
				}
				try {
					const base64String = await fileToBase64(image.file)
					base64Images.push(base64String)
				} catch (error) {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Error converting file to Base64."
					})
					console.error("Error converting file to Base64:", error)
					return
				}
			}
		}

		const imagesToDelete = initialValues.images
			.filter(img => imagesPathToDelete.includes(img.split("?")[0]))
			.map(img => img.split("?")[0])

		const imagesToCreate = base64Images

		dispatch(
			actions.updateModel({
				data: {
					...formValues,
					images: base64Images,
					callersUserId: user.id,
					imagesToDelete: imagesToDelete,
					imagesToCreate: hasFile ? imagesToCreate : []
				},
				technology: technology,
				onHide
			})
		)
			.then(() => {
				setSaving(false)
				onHide()
			})
			.catch(error => {
				console.error("Error saving:", error) // Handle error
				setSaving(false) // Reset saving state on error
			})
	}

	const handleCheckboxChange = featureKey => {
		setFormValues(prevValues => ({
			...prevValues,
			[featureKey]: !prevValues[featureKey]
		}))
	}

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={EditSchema}
			onSubmit={values => {
				onSubmit(values)
			}}
			data-testid="EditInventoryModelsModalForm-Formik"
		>
			{({ setFieldValue, handleSubmit, dirty }) => {
				return (
					<Form className="form form-label-right" data-testid="EditInventoryModelsModalForm">
						{/* Image, Name, Vendor */}
						<div className="form-group row mb-5">
							<div className="col text-center">
								<ImageUploading
									value={images}
									onChange={(imageList, addUpdateIndex) => {
										if (!imageList) {
											setFieldValue("images", "")
											setImages(imageList)
											setFormValues({ ...formValues, images: [] })
										} else {
											setFieldValue("images", imageList)
											setImages(imageList)
											setFormValues({ ...formValues, images: imageList })
										}
									}}
									dataURLKey="data_url"
									multiple
									maxNumber={4} // Set maximum number of images allowed
									acceptType={["png", "jpg", "jpeg"]}
								>
									{({
										onImageUpload,
										onImageRemoveAll,
										onImageRemove,
										isDragging,
										dragProps
									}) => (
										<div
											className="upload__image-wrapper"
											style={{
												pointerEvents: saving ? "none" : "auto"
											}}
										>
											{images && images.length <= 0 ? (
												<div
													className="image-input image-input-outline"
													style={{
														backgroundColor: "white",
														paddingRight: "8px",
														paddingTop: "8px",
														paddingBottom: "8px"
													}}
												>
													<div
														className="image-input-wrapper"
														style={{
															borderColor: isDragging ? "#C3C4DD" : "white",
															backgroundImage: `url(${toAbsoluteUrl(
																"/media/logos/blank_2.svg"
															)}`,
															cursor: "pointer",
															height: "120px",
															width: "120px",
															backgroundSize: "auto",
															backgroundPosition: "center",
															backgroundColor: "#DDE9FD"
														}}
														onClick={onImageUpload}
														{...dragProps}
													/>
													<label
														className="btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
														data-action="change"
														data-toggle="tooltip"
														title=""
														data-original-title="Change logo"
														onClick={onImageUpload}
													>
														<i className="fa fa-pen icon-sm text-muted"></i>
													</label>
												</div>
											) : (
												<div
													style={{
														textAlign: "-webkit-center"
													}}
												>
													<div
														className="image-input image-input-outline row"
														style={{
															backgroundColor: "white",
															paddingRight: "8px",
															paddingTop: "8px",
															paddingBottom: "8px",
															maxWidth: "200px",
															width: "200px",
															display: "flex"
														}}
													>
														{/* Rendering images */}
														{images.map((image, index) => (
															<div key={index} className="col-6 mb-5">
																<div
																	className="image-input-wrapper"
																	style={{
																		borderColor: isDragging ? "#C3C4DD" : "white",
																		backgroundImage: `${getUserPic(index)}`,
																		cursor: "pointer",
																		height: "80px",
																		width: "80px",
																		minWidth: "80px",
																		backgroundSize: "auto",
																		backgroundPosition: "center",
																		backgroundColor: "#DDE9FD"
																	}}
																>
																	<span
																		className="btn btn-md btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
																		style={{
																			position: "absolute",
																			right: "0",
																			top: "-10px",
																			right: "-10px"
																		}}
																		onClick={() => {
																			if (!image?.file)
																				setImagesPathToDelete(prevState => {
																					return [
																						...prevState,
																						image.split("?")[0]
																					]
																				})

																			onImageRemove(index)
																		}}
																	>
																		<i className="ki ki-bold-close icon-xs text-muted"></i>
																	</span>
																</div>
															</div>
														))}
													</div>
												</div>
											)}
											{images[0]?.file?.size &&
												images[0].file.size / (1024 * 1024) > 10 && (
													<span className="form-text text-danger mt-2">
														{`Image is too big (${(
															images[0].file.size /
															(1024 * 1024)
														).toFixed(2)}Mb).`}
														<br />
														Max imagesize: 10Mb.
													</span>
												)}
											<span
												className="form-text mt-2"
												style={{
													color: "#6CA4FF",
													textAlign: "center",
													fontFamily: "Poppins",
													fontSize: "10px",
													fontStyle: "normal",
													fontWeight: "400",
													lineHeight: "14px"
												}}
											>
												Allowed file types: <br />
												png, jpg, jpeg
											</span>
										</div>
									)}
								</ImageUploading>
							</div>
							<div className="col">
								<div className="row">
									<Field
										variant="filled"
										name="name"
										label="Name"
										disabled
										labelFullWidth={true}
										inputFullWidth={true}
										size="small"
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter name"
										onChange={event => {
											if (!event.target.value) {
												setFieldValue("name", "")
												setFormValues({ ...formValues, name: "" })
											} else {
												setFieldValue("name", event.target.value)
												setFormValues({ ...formValues, name: event.target.value })
											}
										}}
									/>
								</div>
								<div className="row mt-3">
									<Field
										variant="filled"
										name="vendor"
										label="Vendor"
										disabled
										labelFullWidth={true}
										inputFullWidth={true}
										size="small"
										component={Input}
										InputProps={{
											disableUnderline: true
										}}
										placeholder="Enter Vendor"
										onChange={event => {
											if (!event.target.value) {
												setFieldValue("vendor", "")
												setFormValues({ ...formValues, vendor: "" })
											} else {
												setFieldValue("vendor", event.target.value)
												setFormValues({ ...formValues, vendor: event.target.value })
											}
										}}
									/>
								</div>
							</div>
						</div>

						{/* Type, Note type */}
						<div className="form-group row mb-5">
							<div className="col">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="nodeType"
									label="Node Type"
									disabled={saving}
									className={classes.autocomple_input}
									requiredField
									component={Autocomplete_People}
									placeholder="Choose node type"
									options={
										nodeType_list && [
											...nodeType_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.nodeType}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("nodeType", "")
											setFormValues({ ...formValues, nodeType: "" })
										} else {
											setFieldValue("nodeType", newValue)
											setFormValues({ ...formValues, nodeType: newValue })
										}
									}}
								/>
							</div>
							{formValues.nodeType != "sensor" && formValues.nodeType != "anchor" && (
								<div className="col">
									<Field
										id="ip-autocomplete"
										customColor="#ECF1F4"
										name="type"
										label="Type"
										disabled={saving || !formValues.nodeType}
										className={classes.autocomple_input}
										// requiredField={
										// 	formValues.nodeType != "sensor" && formValues.nodeType != "anchor"
										// 		? true
										// 		: false
										// }
										requiredField
										component={Autocomplete_People}
										placeholder="Choose type"
										options={
											type_list && [
												...type_list.map(val => {
													const { value, name } = val
													return { value: value, name: name }
												})
											]
										}
										value={formValues.type}
										onChange={(event, newValue) => {
											if (!newValue) {
												setFieldValue("type", "")
												setFormValues({ ...formValues, type: "" })
											} else {
												setFieldValue("type", newValue)
												setFormValues({ ...formValues, type: newValue })
											}
										}}
									/>
								</div>
							)}
						</div>

						{/* Button, Motion, Buzzer, NFC */}
						<span
							style={{
								color: "#1C1C3B",
								fontFamily: "Poppins",
								fontSize: "16px",
								fontStyle: "normal",
								fontWeight: "500",
								lineHeight: "24px"
							}}
						>
							Specific Features
						</span>
						<div className="form-group row mb-5 pl-4 mt-3">
							<div className="col-6 pr-0">
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={formValues.button} // Assuming features is an object with boolean values
											onClick={() => handleCheckboxChange("button")}
										/>
									}
									label={"button"}
									className={classes.checkbox}
								/>
							</div>
							<div className="col-6 pr-0">
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={formValues.motion} // Assuming features is an object with boolean values
											onClick={() => handleCheckboxChange("motion")}
										/>
									}
									label={"fallDetection"}
									className={classes.checkbox}
								/>
							</div>
							<div className="col-6 pr-0">
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={formValues.buzzer} // Assuming features is an object with boolean values
											onClick={() => handleCheckboxChange("buzzer")}
										/>
									}
									label={"buzzer"}
									className={classes.checkbox}
								/>
							</div>
							<div className="col-6 pr-0">
								<FormControlLabel
									control={
										<Checkbox
											disabled={saving}
											checked={formValues.nfc} // Assuming features is an object with boolean values
											onClick={() => handleCheckboxChange("nfc")}
										/>
									}
									label={"nfc"}
									className={classes.checkbox}
								/>
							</div>
							{/* </div> */}
						</div>

						{/* LEDs, Ip protection */}
						<div className="form-group row mb-5">
							<div className="col-6">
								<Field
									variant="filled"
									name="LEDs"
									label="LEDs"
									size="small"
									disabled={saving}
									component={Input}
									type="number"
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter LEDs number"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("LEDs", "0")
											setFormValues({ ...formValues, LEDs: "0" })
										} else {
											setFieldValue("LEDs", event.target.value)
											setFormValues({ ...formValues, LEDs: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col-6">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="ipProtection"
									label="Ip protection"
									disabled={saving}
									component={Autocomplete_People}
									placeholder="Choose ip protection"
									options={
										ipProtection_list && [
											...ipProtection_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.ipProtection}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("ipProtection", "")
											setFormValues({ ...formValues, ipProtection: "" })
										} else {
											setFieldValue("ipProtection", newValue)
											setFormValues({ ...formValues, ipProtection: newValue })
										}
									}}
								/>
							</div>
						</div>

						{/* Battery, Chipset */}
						<div className="form-group row mb-5">
							<div className="col-6">
								<Field
									variant="filled"
									name="battery"
									label="Battery"
									disabled={saving}
									type="number"
									size="small"
									component={Input}
									InputProps={{
										disableUnderline: true
									}}
									placeholder="Enter battery"
									onChange={event => {
										if (!event.target.value) {
											setFieldValue("battery", "0")
											setFormValues({ ...formValues, battery: "0" })
										} else {
											setFieldValue("battery", event.target.value)
											setFormValues({ ...formValues, battery: event.target.value })
										}
									}}
								/>
							</div>
							<div className="col-6">
								<Field
									id="ip-autocomplete"
									customColor="#ECF1F4"
									name="chipset"
									label="Chipset"
									disabled={saving}
									component={Autocomplete_People}
									placeholder="Choose chipset"
									options={
										chipset_list && [
											...chipset_list.map(val => {
												const { value, name } = val
												return { value: value, name: name }
											})
										]
									}
									value={formValues.chipset}
									onChange={(event, newValue) => {
										if (!newValue) {
											setFieldValue("chipset", "")
											setFormValues({ ...formValues, chipset: "" })
										} else {
											setFieldValue("chipset", newValue)
											setFormValues({ ...formValues, chipset: newValue })
										}
									}}
								/>
							</div>
						</div>

						{/* Cancel, Save */}
						<div className="form-group row mb-1 justify-content-end">
							<div className="d-flex align-items-center">
								<Button
									variant="outlined"
									color="primary"
									style={{ width: "85px", height: "35px", marginRight: "10px" }}
									text="Cancel"
									onClick={onHide}
									disabled={saving}
									data-testid="Formik_Form_Button"
								/>
								<Button
									data-testid="Formik_Form_Button"
									variant="contained"
									style={{
										width: "65px",
										height: "35px",
										marginRight: "10px",
										fontWeight: "700",
										backgroundColor: "#3077d3",
										color: "#f4f4f4"
									}}
									onClick={() => {
										handleSubmit()
									}}
									className="ml-2"
									id="buttonToProgress_editPoi"
									data-progress-text="Saving..."
									data-complete-text="Save complete."
								>
									<div className="button__progress_editPoi"></div>
									<span className="button__text_editPoi">Save</span>
								</Button>
							</div>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
