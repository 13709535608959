import React from "react"
import ReactDOM from "react-dom"
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl"
// import * as THREE from "three"
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
// import { Threebox } from "threebox-plugin"
import "threebox-plugin/dist/threebox.css"
import * as turf from "@turf/turf"
import firebase from "firebase/compat/app"
import { uuidv4 } from "../UIHelpers"
import { formatString } from "../UIHelpers"
import { upperCase } from "../UIHelpers"
import * as actions from "../../../_redux/admin/designStudio/RoutesRedux/routesActions"

// import stairs from "../../../../assets/designStudio/stairs (white).png"
// import elevator from "../../../../assets/designStudio/new_elevator.png"
// import escalator from "../../../../assets/designStudio/escalator (white).png"
// import ramp from "../../../../assets/designStudio/ramp white.  (3).png"
import defaultBuilding from "../../../../assets/designStudio/account_tree_white_36dp.png"
import PopoverStickOnHover3 from "../RoutesPage/RoutesMapWidget/PopoverStickOnHover3"
// import { BsInfoLg } from "react-icons/bs"
// import { IconButton } from "@material-ui/core"
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
// import EditIcon from "@material-ui/icons/Edit"
// import RouterIcon from "@material-ui/icons/Router"
import { firestoreOld } from "../../../../../firebase"
import { DesignStudioSlice } from "../../../_redux/admin/designStudio/DesignStudioSlice"

// import { createPopupOnHoverConnector } from "../RoutesPage/RoutesMapWidget/RoutesHelpers";

const actionsDesignStudio = DesignStudioSlice.actions

export const addConnectorsMarkersOnOtherFloors = params => {
	const {
		map,
		floorPlanId,
		floorPlanData,
		// allFloors,
		// newConnectorPositionOnFloors,
		setNewConnectorPositionOnFloors
		// customerId,
		// netId,
		// dispatch,
	} = params

	const connectorsMarkersArray = [] // ← Array com todos os markers

	// ↓ Add marker for each floorPlanData that as a linked connector
	floorPlanData &&
		floorPlanData.forEach(floor => {
			// ↓ Only adds markers to floor that are not selected. Need this so that doesn't create purple marker on selected floor, because already has the pink one.
			if (floor.id === floorPlanId) return

			// Add connector markers in selected floor that are linked to the floorplan of the created connector.
			floor.geoJsonRoutes?.features &&
				floor.geoJsonRoutes.features.forEach(f => {
					// console.log(f, "f ALL  features no geoJsonRoutes");

					if (f.properties.linkToFloors?.includes(floorPlanId)) {
						// console.log("connector linked to this floor ->", f);
						const coords = []
						f.geometry.coordinates.forEach(coordinates => {
							coords.push(coordinates.longitude, coordinates.latitude)
						})

						let connectorTypeImage = defaultBuilding
						let idForEachType = f.properties.connectorName

						switch (f.properties.connectorType) {
							case "ELEVATOR":
								connectorTypeImage = "/icons/elevator.svg"
								idForEachType = "Elevator1"
								break
							case "STAIRS":
								connectorTypeImage = "/icons/stairs.svg"
								idForEachType = "Stairs1"
								break
							case "ESCALATOR":
								connectorTypeImage = "/icons/escalator.svg"
								idForEachType = "Escalator1"
								break
							case "RAMP":
								connectorTypeImage = "/icons/ramp.svg"
								idForEachType = "wheelChairRamp"
								break
							default:
								idForEachType = "defaultBuilding"
								connectorTypeImage = defaultBuilding
						}

						const typeWithoutAllLetterUppercase = formatString(f?.properties?.connectorType)
						// Names without spaces
						const namesWithoutWhiteSpace = idForEachType && idForEachType.replace(/ +/g, "")

						const popupstickyConnectors = f && (
							<PopoverStickOnHover3
								component={
									<div className="popoverCard">
										<div className="popupMainDiv">
											<div className="popup2">
												<div className="popupTextRoutes">
													<h6
														style={{
															paddingBottom: "1px",
															paddingTop: "1px",
															fontSize: "14px",
															fontWeight: "700"
														}}
													>
														<span
															style={{
																fontSize: "14px",
																fontWeight: "400"
															}}
														>
															{" "}
															Connector:&nbsp;
														</span>

														<b>{f.properties.connectorName}</b>
													</h6>
													<h6
														style={{
															paddingBottom: "1px",
															paddingTop: "1px",
															fontSize: "10px",
															fontWeight: "400"
														}}
													>
														Type:&nbsp;
														<b>{typeWithoutAllLetterUppercase}</b>
													</h6>
													<h6
														style={{
															paddingBottom: "1px",
															paddingTop: "1px",
															fontSize: "10px",
															fontWeight: "400"
														}}
													>
														Linked to floors:&nbsp;
													</h6>

													{f.properties.linkToFloors &&
														f.properties.linkToFloors.map(res => {
															{
																const floorName =
																	floorPlanData &&
																	floorPlanData?.find(f => f.id === res)

																const linkToFloorsCapitalized =
																	floorName && upperCase(floorName?.name)

																if (res) {
																	return (
																		<div key={res}>
																			<ul key={res}>
																				<li
																					style={{
																						marginBottom: "-1vh",
																						fontSize: "10px",
																						fontWeight: "500"
																					}}
																				>
																					<b>{linkToFloorsCapitalized}</b>
																				</li>
																			</ul>
																		</div>
																	)
																}
															}
															return null
														})}
												</div>
												<br />
											</div>
										</div>
									</div>
								}
								placement="top"
								onMouseEnter={() => {}}
								delay={200}
							>
								{/* <div className="popupLineRoutes" style={{ backgroundColor: "purple" }}> */}
								<div
									className="popupLineRoutes"
									style={{
										backgroundColor: "purple",
										width: "26px",
										height: "26px",
										position: "relative",
										overflow: "hidden"
									}}
								>
									<img
										src={connectorTypeImage}
										alt={f.properties.connectorName}
										// className="iconImageRoutes"
										// id={f.properties.connectorName}
										id={namesWithoutWhiteSpace}
										style={{
											width: "80%",
											height: "auto",
											display: "block"
											// padding: "3px"
										}}
									/>
								</div>
							</PopoverStickOnHover3>
						)

						// ↓ Get connectorPositionOnFloors coords in selected floor if they exist, else use coords com geoJsonRoutes
						const connectorPositionInSelectedFloor =
							f.properties.connectorPositionOnFloors &&
							f.properties?.connectorPositionOnFloors.find(
								marker => marker.floorPlanId === floorPlanId
							)
						// console.log(
						//   "🚀 ~ connectorPositionInSelectedFloor",
						//   connectorPositionInSelectedFloor
						// );

						const connectorPositionFetchedCoords = connectorPositionInSelectedFloor && [
							connectorPositionInSelectedFloor.coords.longitude,
							connectorPositionInSelectedFloor.coords.latitude
						]
						// console.log(
						//   "🚀 ~ connectorPositionFetchedCoords",
						//   connectorPositionFetchedCoords
						// );

						// console.log(
						//   f.properties.connectorName,
						//   coords,
						//   "geometry coords + f "
						// );

						const conditionalCoords = connectorPositionFetchedCoords
							? connectorPositionFetchedCoords
							: coords
						// console.log("🚀 ~ conditionalCoords 1", conditionalCoords);

						// ↓ Create the popup and marker
						const div = document.createElement("div")
						div.style.cursor = "move"
						ReactDOM.render(popupstickyConnectors, div)

						const markerWithPopup = new mapboxgl.Marker(div, {
							draggable: true
						})

						// Save markers movement on draggend
						markerWithPopup.on("dragend", val => {
							const lngLat = val.target.getLngLat()
							// console.log("🚀 ~ lngLat", lngLat);
							// console.log("connectorName ->", f.properties.connectorName);
							// console.log(f, "f");

							// ↓ Save markers on dragend
							const newMarkerPosition = {
								coords: new firebase.firestore.GeoPoint(lngLat.lat, lngLat.lng),
								connectorId: f.id,
								floorPlanId: floorPlanId
							}
							// console.log("🚀 ~ newMarkerPosition", newMarkerPosition);

							setNewConnectorPositionOnFloors(newMarkerPosition)
						})

						// ↓ Add marker to map
						markerWithPopup.setLngLat(conditionalCoords).addTo(map)
						connectorsMarkersArray.push(markerWithPopup)
					}

					// // Finding all features in same floorPlanId as connectorPositionOnFloors[0]
					// if (f.id === newConnectorPositionOnFloors?.connectorId) {
					//   // console.log(
					//   //   newConnectorPositionOnFloors,
					//   //   "newConnectorPositionOnFloors"
					//   // );
					//   // console.log("encontrou floor", floor);
					//   // console.log(
					//   //   floor?.geoJsonRoutes.features,
					//   //   "floor.geoJsonRoutes.features"
					//   // );
					//   newConnectorPositionOnFloors &&
					//     dispatch(
					//       actions.saveRoutesAndGeoJsonAndConnectorsNewPositions({
					//         customerId: customerId,
					//         floorPlanId: floor.floorPlanId,
					//         data: floor?.geoJsonRoutes?.features,
					//         netId: netId,
					//         newConnectorPositionOnFloors: newConnectorPositionOnFloors,
					//       })
					//     );
					//   // .then(() => {
					//   //   dispatch(
					//   //     actions.fetchFloorPlans({
					//   //       customerId: customerId,
					//   //       networkId: netId,
					//   //     })
					//   //   );
					//   // });
					// }
				})
		})

	// console.log("🚀 ~ connectorsMarkersArray", connectorsMarkersArray);
	return connectorsMarkersArray
}

export const addImageLayerToMap = params => {
	//━━━ Parameters ━━━\\
	const map = params.map
	const filter = params.filter

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove source and layer ━━━\\
		removeLayersSources(map)

		//━━━ Add image source and layer ━━━\\
		map.addSource("floorPlanImageSource", {
			type: "image",
			url: filter.ufindurl,
			coordinates: [
				[filter.topLeftCorner.lng, filter.topLeftCorner.lat],
				[filter.topRightCorner.lng, filter.topRightCorner.lat],
				[filter.bottomRightCorner.lng, filter.bottomRightCorner.lat],
				[filter.bottomLeftCorner.lng, filter.bottomLeftCorner.lat]
			]
		})
		map.addLayer(
			{
				id: "floorPlanImageLayer",
				source: "floorPlanImageSource",
				type: "raster",
				paint: {
					"raster-opacity": 0.85
				}
			},
			// "gl-draw-polygon-fill-inactive.cold"
			"state-label"
		)
	}
}

// Draw geoJson routes Layers Line in map
export const addGeoJsonLayerToMapLine = params => {
	//━━━ Parameters ━━━\\
	const map = params.map
	const geoJson = params.geoJson
	const setCirclesGeoJson = params.setCirclesGeoJson
	// const connectorsMarkers = params.connectorsMarkers;
	// const setConnectorsMarkers = params.setConnectorsMarkers;

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove source and layer ━━━\\
		var layer = map.getLayer("geoJsonLineLayerRoutes")
		if (typeof layer !== "undefined") {
			map.removeLayer("geoJsonLineLayerRoutes")
		}

		var layerCircle1 = map.getLayer("circles-vertex")
		if (typeof layerCircle1 !== "undefined") {
			map.removeLayer("circles-vertex")
		}

		var sourceCircle1 = map.getSource("geoJsonLineSourceRoutesCircles")
		if (typeof sourceCircle1 !== "undefined") {
			map.removeSource("geoJsonLineSourceRoutesCircles")
		}

		var source = map.getSource("geoJsonLineSourceRoutes")
		if (typeof source !== "undefined") {
			map.removeSource("geoJsonLineSourceRoutes")
		}

		//━━━ Add floorplan source and layer ━━━\\
		var lineGeoJson = JSON.parse(JSON.stringify(geoJson))

		//━━━ Add lines source and layer ━━━\\
		map.addSource("geoJsonLineSourceRoutes", {
			type: "geojson",
			data: lineGeoJson,
			generateId: true // This ensures that all features have unique IDs (to have onHover effect)
		})
		map.addLayer(
			{
				id: "geoJsonLineLayerRoutes",
				source: "geoJsonLineSourceRoutes",
				type: "line",
				layout: {
					visibility: "visible",
					"line-join": "round",
					"line-cap": "round"
				},

				paint: {
					// "line-color": "#3bb3d0",
					// "line-width": 4,
					// "line-color": ["get", "outline"], // before onHover fx
					"line-width": ["get", "lineWidth"],
					"line-color": [
						"case",
						["boolean", ["feature-state", "hover"], false],
						"#FFBA57",
						"#3bb3d0"
					]
				}
			},
			// "gl-draw-polygon-fill-inactive.cold"
			// "state-label"
			"country-label"
		)

		var vertexGeoJson = JSON.parse(JSON.stringify(geoJson))
		// console.log("🚀 ~ vertexGeoJson", vertexGeoJson);

		const filteredFeaturesLines = []
		const filteredFeaturesPoints = []

		vertexGeoJson &&
			vertexGeoJson.features.forEach(val => {
				// console.log(val, "val");
				// var coordinates = [];
				if (val.geometry.type === "LineString") {
					val.geometry.coordinates.forEach(coords => {
						// console.log("🚀 ~ coords", coords);
						// coordinates.push([coords[0], coords[1]]);

						const randomId = uuidv4()
						let feature = {
							type: val.type,
							properties: {
								...val.properties,
								name: val.properties.name + "_" + randomId,
								id: randomId,
								// disableVisibility: false,
								routeId: val.id // ← the routeId that the connector ou vertex belong originally
							},
							geometry: {
								type: "Point",
								coordinates: coords
							},
							id: randomId
						}

						filteredFeaturesLines.push(feature)
					})
				}
				if (val.geometry.type === "Point") {
					filteredFeaturesPoints.push(val)
				}
			})

		// console.log(filteredFeaturesPoints, "filteredFeaturesPoints");
		// console.log(filteredFeaturesLines, "filteredFeaturesLines");

		const featuresWithoutConnectors =
			filteredFeaturesLines &&
			filteredFeaturesLines.filter(feature => {
				return !filteredFeaturesPoints.some(
					point =>
						JSON.stringify(point.geometry.coordinates) ===
						JSON.stringify(feature.geometry.coordinates)
				)
			})
		// console.log("🚀 ~ featuresWithoutConnectors", featuresWithoutConnectors);

		const filteredGeoJson = turf.featureCollection(featuresWithoutConnectors) // ← geoJson sem os connectors
		// console.log("🚀 ~ filteredGeoJson", filteredGeoJson);
		setCirclesGeoJson(filteredGeoJson)

		//━━━ Add CIRCLES Vertex source and layer ━━━\\
		map.addSource("geoJsonLineSourceRoutesCircles", {
			type: "geojson",
			data: filteredGeoJson,
			generateId: true // This ensures that all features have unique IDs
		})

		map.addLayer(
			{
				id: "circles-vertex",
				type: "circle",
				source: "geoJsonLineSourceRoutesCircles",
				layout: {
					visibility: "visible"
				},
				paint: {
					"circle-stroke-color": "#3bb3d0",
					// "circle-stroke-color": "#3bb3d0",
					"circle-stroke-width": 1,
					// "circle-color": "#3bb3d0",
					"circle-color": [
						"case",
						["boolean", ["feature-state", "hover"], false],
						"#FFBA57",
						// "#e2671f",
						"#3bb3d0"
					]
				}
			},
			"country-label"
		)

		//━━━ Add CONNECTORS (images and circle) source and layer ━━━\\
		// connectorsMarkers &&
		//   connectorsMarkers.forEach((m) => {
		//     m.remove(); // ← remove markers if they exist.
		//   });

		// const connectorsMarkersArray = [];
		// filteredFeaturesPoints &&
		//   filteredFeaturesPoints.forEach((point) => {
		//     // console.log(point, "point");

		//     const coords = [];
		//     point.geometry.coordinates.forEach((coordinates) => {
		//       coords.push(coordinates);
		//     });

		//     // console.log("🚀 ~ coords", coords);
		//     //!! Aqui introduzir se para que type usar "x" imagem
		//     let connectorTypeImage = elevator;

		//     switch (point.properties.connectorType) {
		//       case "ELEVATOR":
		//         connectorTypeImage = elevator;
		//         break;
		//       case "STAIRS":
		//         connectorTypeImage = stairs;
		//         break;
		//       case "ESCALATOR":
		//         connectorTypeImage = escalator;
		//         break;
		//       case "RAMP":
		//         connectorTypeImage = ramp;
		//         break;
		//       default:
		//         connectorTypeImage = elevator;
		//     }

		//     //  Delete check
		//     let layerPoint = map.getLayer(point.id + "points");
		//     if (typeof layerPoint !== "undefined") {
		//       map.removeLayer(point.id + "points");
		//     }
		//     let layerImage = map.getLayer(point.id + "image");
		//     if (typeof layerImage !== "undefined") {
		//       map.removeLayer(point.id + "image");
		//     }
		//     let sourceImage = map.getSource(point.id + "-room");
		//     if (typeof sourceImage !== "undefined") {
		//       map.removeSource(point.id + "-room");
		//     }
		//     // Double safe check, if exists, delete
		//     if (map.getLayer(point.id + "points")) {
		//       map.removeLayer(point.id + "points");
		//     }
		//     if (map.getLayer(point.id + "image")) {
		//       map.removeLayer(point.id + "image");
		//     }
		//     if (map.getSource(point.id + "-room")) {
		//       map.removeSource(point.id + "-room");
		//     }

		//     map.loadImage(connectorTypeImage, (error, image) => {
		//       if (error) throw error;
		//       const mapHasImage = map.hasImage(point.id + "custom-marker");
		//       // ↓ So don't get error of loading image with same sourceId
		//       if (mapHasImage) {
		//         map.removeImage(point.id + "custom-marker");
		//       }

		//       // Add image
		//       map.addImage(point.id + "custom-marker", image);

		//       if (!map.getSource(point.id + "-room")) {
		//         // Add source
		//         map.addSource(point.id + "-room", {
		//           type: "geojson",
		//           data: {
		//             type: "FeatureCollection",
		//             features: [
		//               {
		//                 type: "Feature",
		//                 geometry: {
		//                   type: "Point",
		//                   coordinates: coords && coords,
		//                 },
		//               },
		//             ],
		//           },
		//         });
		//       }

		//       if (!map.getLayer(point.id + "points")) {
		//         // Add circle layer
		//         map.addLayer({
		//           id: point.id + "points",
		//           type: "circle",
		//           source: point.id + "-room",
		//           paint: {
		//             "circle-radius": 14,
		//             "circle-color": "#00C89A",
		//             // "circle-color": "#FFBA57",
		//             "circle-opacity": 0.9,
		//             "circle-stroke-width": 2, // ← circle border
		//             "circle-stroke-color": "#ffffff",
		//             "circle-stroke-opacity": 0.9,
		//           },
		//         });
		//       }

		//       if (!map.getLayer(point.id + "image")) {
		//         // Add image layer
		//         map.addLayer({
		//           id: point.id + "image",
		//           type: "symbol",
		//           source: point.id + "-room",
		//           layout: {
		//             "icon-image": point.id + "custom-marker",
		//             "icon-size": 0.55,
		//           },
		//           paint: {
		//             "icon-opacity": 0.9,
		//           },
		//         });
		//       }
		//     });

		//     // Mouseover connectors layer
		//     map.on("mouseover", point.id + "image", (e) => {
		//       map.getCanvas().style.cursor = "pointer";
		//       if (map) {
		//         createPopupOnHoverConnector({
		//           e,
		//           map,
		//           connectorsMarkersArray,
		//           point,
		//         });
		//       }
		//     });

		//     // Mouseleave connectors layer
		//     map.on("mouseleave", point.id + "image", (e) => {
		//       map.getCanvas().style.cursor = "";
		//     });
		//   });
		// setConnectorsMarkers(connectorsMarkersArray);
	}
}

export const addGeoJsonLayerToMap = params => {
	//━━━ Parameters ━━━\\
	const map = params.map
	const geoJson = params.geoJson

	if (!map) return

	const layers = map.getStyle()

	if (layers) {
		//━━━ Remove source and layer ━━━\\
		removeLayersSources(map)

		//━━━ Add floorplan source and layer ━━━\\
		map.addSource("geoJsonSource", {
			type: "geojson",
			data: geoJson
		})

		map.addLayer(
			{
				id: "geoJsonLayer",
				source: "geoJsonSource",
				type: "fill",
				layout: {
					visibility: "visible"
				},
				paint: {
					"fill-color": ["get", "color"],
					"fill-outline-color": ["get", "outline"],
					"fill-opacity": ["get", "opacity"]
				}
			},
			// "gl-draw-polygon-fill-inactive.cold"
			"state-label"
		)

		//━━━ Put base polygon in last position ━━━\\
		var lineGeoJson = JSON.parse(JSON.stringify(geoJson))

		var first = lineGeoJson?.features[0] && lineGeoJson?.features[0]

		lineGeoJson.features.shift()

		if (first?.geometry) {
			lineGeoJson.features.push(first)
		}

		//━━━ Add lines source and layer ━━━\\
		map.addSource("geoJsonLineSource", {
			type: "geojson",
			data: lineGeoJson
		})
		map.addLayer(
			{
				id: "geoJsonLineLayer",
				source: "geoJsonLineSource",
				type: "line",
				layout: {
					visibility: "visible"
				},
				paint: {
					"line-color": ["get", "outline"],
					"line-width": ["get", "lineWidth"]
				}
			},
			// "gl-draw-polygon-fill-inactive.cold"
			"state-label"
		)
	}
}

export const removeLayersSources = map => {
	const layers = map.getStyle()

	if (layers) {
		var layer = map.getLayer("floorPlanImageLayer")
		if (typeof layer !== "undefined") {
			map.removeLayer("floorPlanImageLayer")
		}

		var layerGeoJson = map.getLayer("geoJsonLayer")
		if (typeof layerGeoJson !== "undefined") {
			map.removeLayer("geoJsonLayer")
		}

		var layerGeoJsonLine = map.getLayer("geoJsonLineLayer")
		if (typeof layerGeoJsonLine !== "undefined") {
			map.removeLayer("geoJsonLineLayer")
		}

		var sourceFloorPlanImage = map.getSource("floorPlanImageSource")
		if (typeof sourceFloorPlanImage !== "undefined") {
			map.removeSource("floorPlanImageSource")
		}

		var sourceGeoJson = map.getSource("geoJsonSource")
		if (typeof sourceGeoJson !== "undefined") {
			map.removeSource("geoJsonSource")
		}

		var sourceGeoJsonLine = map.getSource("geoJsonLineSource")
		if (typeof sourceGeoJsonLine !== "undefined") {
			map.removeSource("geoJsonLineSource")
		}
	}
}

// export function addApMarkers(params) {
// 	const { map, aps } = params

// 	const filter = aps.filter(ap => ap.model.startsWith("MR"))

// 	var apMarkers = []

// 	filter.forEach(d => {
// 		var markerDiv = document.createElement("div")
// 		ReactDOM.render(
// 			<div className="pinBlack">
// 				<div className="letterDiv">
// 					<a className="letterText">
// 						<RouterIcon style={{ fontSize: "17px" }} />
// 					</a>
// 				</div>
// 			</div>,
// 			markerDiv
// 		)

// 		const marker = new mapboxgl.Marker(markerDiv, { anchor: "center" })
// 			.setLngLat([d.lng, d.lat])
// 			.addTo(map)

// 		apMarkers.push(marker)
// 	})

// 	return apMarkers
// }

export async function addAnchorsToMap(params) {
	const {
		dispatch,
		customClaims,
		map,
		selectedCustomer,
		selectedSite,
		currentFloorPlan,
		anchors,
		anchorsMarkers,
		setAnchorsMarkers,
		showAnchors
	} = params
	if (!map || !selectedCustomer || !selectedSite || !currentFloorPlan) return

	// Remove anchor markers
	anchorsMarkers && anchorsMarkers.forEach(marker => marker.remove())
	setAnchorsMarkers()

	// Check weither to show or not show anchors
	if (!showAnchors) return

	var _anchors = anchors ? anchors.filter(val => val.floorPlanId === currentFloorPlan.id) : []

	if (_anchors.length === 0) {
		const snapshot = await firestoreOld
			.collection(`Sites/${selectedSite.id}/Nodes`)
			.where("floorPlanId", "==", currentFloorPlan.id)
			.where("nodeType", "==", "anchor")
			.get()

		const newAnchors = snapshot.docs.map(doc => {
			return {
				id: doc.id,
				...doc.data()
			}
		})

		// newAnchors.length > 0 &&
		// 	newAnchors &&
		dispatch(actionsDesignStudio.addAnchorsDesignStudio(newAnchors || []))
		return
	}

	// Add marker for each anchor
	const newMarkers =
		_anchors &&
		_anchors
			.map(val => {
				// Check if all the needed data is present
				if (!val.gpsData) return null

				const popupstickyConnectors = (
					<div
						className="popupLineRoutes"
						style={{
							backgroundColor: "#2649AA",
							borderRadius: "50%",
							// width: "34px",
							// height: "34px"
							width: "34px",
							height: "34px",
							position: "relative",
							overflow: "hidden",
							outline: "2px solid #ffffff"
						}}
						id={val.id}
						onClick={() => {}}
					>
						{/* <div style={{ margin: "0 2px 2px 2px" }}> */}
						<img
							style={{
								// width: "16px",
								padding: "4px",
								objectFit: "contain",
								margin: "auto",
								width: "76%",
								height: "auto",
								display: "block"
							}}
							src="/markers/anchor.svg"
							alt="anchor icon"
							className="iconImageRoutes"
						/>
						{/* </div> */}
					</div>
				)

				const div = document.createElement("div")
				div.className = "connectorsMarkerDashboard"
				div.style.cursor = "pointer"
				ReactDOM.render(popupstickyConnectors, div)

				const marker = new mapboxgl.Marker(div, {
					draggable: false
				})

				if (customClaims.isSuper) {
					const popupHTML = (
						<div className="row" style={{ padding: "10px" }} id={val.id} onClick={() => {}}>
							<div className="col">
								<div style={{ whiteSpace: "nowrap" }}>
									<b>Anchor address:</b>
								</div>
								<div>
									<b>Anchor name:</b>
								</div>
							</div>
							<div className="col">
								<div>{val.node_address}</div>
								<div>{val.node_name}</div>
							</div>
						</div>
					)

					const popupDiv = document.createElement("div")
					ReactDOM.render(popupHTML, popupDiv)

					marker.setPopup(
						new mapboxgl.Popup() //add popups
							.setDOMContent(popupDiv)
					)

					div.addEventListener("mouseenter", () => marker.togglePopup())
					div.addEventListener("mouseleave", () => marker.togglePopup())
				}

				marker.setLngLat([val.gpsData.longitude, val.gpsData.latitude]).addTo(map)
				return marker
			})
			.filter(val => val)

	setAnchorsMarkers(newMarkers)
	return
}
