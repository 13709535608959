import React, { useState, useEffect, forwardRef } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import * as actions from "../../../../_redux/contactTracing/contactTracingActions"
import {
	TextField,
	IconButton,
	makeStyles,
	InputAdornment,
	CircularProgress
} from "@material-ui/core/"
import Autocomplete from "@material-ui/lab/Autocomplete"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import moment from "moment"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../../_partials/typography/Bold"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "../../../../../_partials/Button"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import DatePicker, { getDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { MdOutlineCalendarToday } from "react-icons/md"
import { start } from "@popperjs/core"

const useStyles = makeStyles(() => ({
	label: {
		fontWeight: 500,
		color: "#8C8CA1",
		fontFamily: "Poppins",
		fontSize: "13px"
	},
	datePicker: {
		marginTop: "2px",
		backgroundColor: "#FFFFFF",
		borderRadius: "5px",
		margin: 0,
		padding: "0 12px",
		height: "34px",
		"&:hover": {
			cursor: "pointer",
			border: "1px solid grey"
		},
		"& .MuiInputBase-root": {
			cursor: "pointer",
			height: "38px"
		},
		"&:hover .MuiInputBase-input": {
			cursor: "pointer"
		},
		"& .MuiInputBase-input": {
			// placeholder color
			// color: "#B8BCCA",
			// color: "#9A9A9A",
			fontWeight: "500"
		}
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			// placeholder color
			// color: "#6d7592",
			fontWeight: "500"
		}
	}
}))

export function Toolbar({
	person,
	setPressSubmitButton,
	areas,
	setWarningText,
	isLoading,
	areasUpdatedDashboard,
	setAreasUpdatedDashboard,
	handleStopClick,
	selectedUser,
	setSelectedUser,
	data
}) {
	//person to preselect on autocomplete coming from ContactTracingCard
	const dispatch = useDispatch()
	const classes = useStyles()

	const { user, users, customerId, customer, selectedSite, floorPlanData } = useSelector(
		state => ({
			user: state.auth.user,
			users: state.contactTracing.users.filter(
				val => val
				// (val) => val.displayName && val.devicesArray //TODO: Precisamos do MacAddress p ter real data. Por isso comentei isto
			),
			customerId: state.profile?.currentCustomer?.id,
			customer: state.profile?.currentCustomer,
			// networkId: state.profile?.currentLocation?.netId, // Old networkId
			selectedSite: state.profile?.currentSite, // new selectedLocation
			floorPlanData: state.basePage?.floorPlans // all floorplans
		}),
		shallowEqual
	)

	// const [selectedUser, setSelectedUser] = useState(null)
	// const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate())
	// const [endDate, setEndDate] = useState(moment().toDate())
	const [date, setDate] = useState(moment().toDate())
	// const [date, setDate] = useState(moment().subtract(1, "days").toDate())
	const [show, setShow] = useState(false)
	// const [reason, setReason] = useState(null)
	const [isLoadingPersons, setIsLoadingPersons] = useState(false)
	const [openAutocomplete, setOpenAutocomplete] = useState(false)
	const [usersWithCoords, setUsersWithCoords] = useState([])
	const [dataFetched, setDataFetched] = useState(false)

	//━━━━━━━━━━━━━━━ Functions ━━━━━━━━━━━━━━━\\
	//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\\

	// function onChangeDatePicker(dates) {
	// 	const [start, end] = dates

	// 	if (!dates[1]) {
	// 		setStartDate(moment(start).toDate())
	// 		setEndDate(null)
	// 	} else {
	// 		setStartDate(moment(start).toDate())
	// 		setEndDate(moment(end).toDate())
	// 		// handleChangeQueryParams({ start: moment(start), end: moment(end) }) // set new queryParams to fetch data from API with new dates
	// 	}
	// }

	function onChangeDatePicker(date) {
		setDate(date)
		setUsersWithCoords([])
		setDataFetched(false)
		setSelectedUser(null)
	}

	function onHide() {
		setShow(false)
	}

	// Submit handler
	function submitHandler(areasUpdatedDashboard) {
		onHide()
		setPressSubmitButton(true)
		handleStopClick() // stop the slider and reset values

		if (
			(areas && areas.length > 0) ||
			(areasUpdatedDashboard && areasUpdatedDashboard.length > 0)
		) {
			if (!selectedUser?.uuid) {
				setWarningText(
					"Selected person cannot be queried due to not having a Tag assigned to him."
				)
				return
			}
			// note: don't need to send areas to the query to get data.
			selectedUser?.personalData?.name &&
				dispatch(
					actions.fetchPeopleDataAuditTool({
						user: user,
						// reason: reason,
						queriedUser: selectedUser,
						queriedUserName: selectedUser?.personalData?.name,
						customerId: customerId,
						siteId: selectedSite?.id,
						networkId: selectedSite.netWirepas,
						startDate: moment(date).startOf("day").format(), //"2021-11-20T00:00:00",
						endDate: moment(date).endOf("day").format(), //"2021-11-24T23:59:59"
						areasData: areas || areasUpdatedDashboard
					})
				)
		} else {
			const paragraphToaster = (
				<p style={{ textAlign: "center" }}>
					<b>Can't make request because there are no Areas created.</b> <br />
					<span>Please create an Area on design studio page.</span>
				</p>
			)
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: paragraphToaster
			})
		}
	}

	// ━━━━━━━━━━━━━━━  useEffect ━━━━━━━━━━━━━━━ \\
	// with out this I could not use the prop value because it only was read after page render the first time
	// and clear didnt work because it always had a selectPerson if props existed so clear person.UID after person found to be able to
	// clear combo box and use it normally
	useEffect(() => {
		if (!selectedUser && person?.uuid) {
			const foundUser = users.find(user => user.uuid === person.uuid)

			if (foundUser) {
				setSelectedUser(foundUser || null)
			}

			if (foundUser) person.uuid = ""
		}
	}, [person, users])

	useEffect(() => {
		if (!person && !person?.uuid && !selectedUser) return

		// if exist areas coming from dashboard then submit request
		if (areasUpdatedDashboard && areasUpdatedDashboard.length > 0) {
			setWarningText() // hide the warning text
			submitHandler(areasUpdatedDashboard) // submit request if person is found, (clicked on dashboard's attendance list)
		}
	}, [selectedUser, person, areasUpdatedDashboard])

	// fetch persons that have data on the selected date
	useEffect(() => {
		if (!customer || !selectedSite || !date || dataFetched) return

		if (openAutocomplete) {
			setIsLoadingPersons(true)
			setWarningText()

			dispatch(
				actions.fetchAllPeopleToTimesheets({
					customerId: customer.id,
					siteId: selectedSite?.id,
					selectedSite,
					startDate: moment(date).startOf("day"),
					// .format(), //"2021-11-20T00:00:00",
					endDate: moment(date).endOf("day")
					// .format(), //"2021-11-24T23:59:59"
					// areas: areas || areasUpdatedDashboard
					// networkId: selectedSite.netWirepas,
				})
			).then(res => {
				// setOpenAutocomplete(false) // set to false to avoid fetching data again
				// console.log("🤖 🤖 🤖 🤖 🤖 🤖 🤖 FETCHED DATA res ", res)

				if (res) {
					const filteredPeople =
						users &&
						users.filter(user => res.find(userFromApi => userFromApi.uuid === user.uuid))

					if (filteredPeople && filteredPeople.length > 0) {
						setUsersWithCoords(filteredPeople)
						setIsLoadingPersons(false)
					} else {
						setUsersWithCoords([])
						setIsLoadingPersons(false)
					}
				} else {
					setUsersWithCoords([])
					setIsLoadingPersons(false)
				}
				setDataFetched(true) // Marking data as fetched
			})
		}
	}, [customer, selectedSite, date, users, openAutocomplete, dataFetched])

	// React.useEffect(() => {
	// 	// console.log("👾 👾 👾 👾 isLoadingPersons", isLoadingPersons)
	// 	// console.log("👾 👾 👾 👾 dataFetched", dataFetched)
	// 	console.log("🚀  openAutocomplete:", openAutocomplete)
	// 	// console.log("👾 👾 👾 👾 usersWithCoords", usersWithCoords)
	// }, [isLoadingPersons, dataFetched, openAutocomplete, usersWithCoords])

	useEffect(() => {
		if (usersWithCoords && usersWithCoords.length > 0) {
			setIsLoadingPersons(false)
		}
	}, [usersWithCoords])

	const sliderDisableCondition = isLoading ? true : !selectedUser ? true : false

	return (
		<div className="row">
			<div className="col-12 col-lg-5 col-md-5 mt-3 mt-lg-0 pr-lg-0 pr-md-0">
				<div style={{ marginBottom: "3px" }}>
					<span className={classes.label}>Person</span>
				</div>
				<Autocomplete
					id="audit_tool_users_autocomplete"
					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={usersWithCoords || []}
					size="small"
					getOptionLabel={option => {
						return option.personalData?.name || ""
					}}
					noOptionsText={"No data available for this date."}
					value={selectedUser}
					onChange={(event, newValue) => {
						setSelectedUser(newValue)
						setAreasUpdatedDashboard([]) // reset areas coming from dashboard (so when we search for another person, it doesn't trigger the submitHandler())
					}}
					renderInput={params => (
						<TextField
							className={classes.select}
							{...params}
							placeholder="username"
							variant="outlined"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{isLoadingPersons ? (
											<CircularProgress color="primary" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								)
							}}
						/>
					)}
					popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
					onOpen={() => {
						setOpenAutocomplete(true)
					}}
					onClose={() => {
						setOpenAutocomplete(false)
					}}
					loading={isLoadingPersons} // loading state
				/>
			</div>

			<div className="col-12 col-lg-5 col-md-5 mt-3 mt-lg-0 pr-lg-0 pr-md-0">
				<div style={{ marginBottom: "3px" }}>
					<span className={classes.label}>Date</span>
				</div>
				<DatePicker
					className="custom-datepicker"
					calendarClassName="date_picker_range_reporting"
					selected={date}
					onChange={onChangeDatePicker}
					customInput={<ExampleCustomInput />}
					dateFormat="P"
					// selectsRange
					// minDate={
					// 	endDate
					// 		? moment(endDate).subtract(7, "days").toDate()
					// 		: moment(startDate).subtract(7, "days").toDate()
					// } // Only allow searching for 7 days before the endDate so the API requests don't get huge
					maxDate={new Date()}
					wrapperClassName="custom-datepicker-wrapper"
				/>
			</div>

			<div className="col-12 col-lg-2 col-md-2 mt-5 mt-lg-0 d-flex justify-content-center align-items-end">
				<div
					style={{ height: "31px", width: "140px", marginBottom: "2px" }}
					className="w-lg-100 w-md-100"
				>
					<Button
						disabled={sliderDisableCondition}
						id="audit_tool_submit_btn"
						style={{ height: "100%", width: "100%" }}
						variant="contained"
						color="primary"
						text="Submit"
						onClick={() => {
							submitHandler()
							// setShow(true) // I've commented this, because we don't need the modal to give reason to the user
						}}
					/>
				</div>
			</div>
			<Modal show={show} onHide={onHide} contentClassName="bg-transparent">
				<Modal.Body
					style={{
						padding: "0",
						backgroundColor: "#ECF1F4",
						borderRadius: "10px",
						overflow: "hidden"
					}}
				>
					<div className="pl-7 pr-7 pb-7 pt-2">
						<div className="row align-items-center">
							<div className="col-auto mr-auto">
								<Bold>Contact tracing</Bold>
							</div>
							<div className="col-auto">
								<IconButton onClick={onHide} style={{ left: "12px" }}>
									<CloseIcon />
								</IconButton>
							</div>
						</div>

						<span className="mt-4">
							This tool is to be used with caution and only when extremely necessary. If you
							proceed your actions will be recorded for safety reasons.
						</span>
						<div className="mt-4">
							<button
								type="button"
								onClick={onHide}
								className="btn btn-light btn-elevate m-2"
							>
								Cancel
							</button>
							<> </>
							<button
								type="button"
								onClick={submitHandler}
								className="btn btn-primary btn-elevate m-2"
							>
								Continue
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef(({ value, onClick, queriedNewDate }, ref) => {
	const classes = useStyles()

	return (
		<div className="row" style={{ margin: "auto" }} onClick={onClick} ref={ref}>
			<TextField
				className={classes.datePicker}
				fullWidth
				value={value}
				style={{ backgroundColor: "#F2F2F2" }}
				InputProps={{
					readOnly: true,
					disableUnderline: true,
					endAdornment: (
						<InputAdornment position="end">
							<MdOutlineCalendarToday style={{ fontSize: "1.4rem", color: "#B8BCCA" }} />
						</InputAdornment>
					)
				}}
			/>
		</div>
	)
})
