/* eslint-disable react/display-name */
import React, { forwardRef } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import * as ChartsHelpers from "../ChartsHelpers"
import { sortCaret } from "../../../../../../_metronic/_helpers"

const TimesheetDailyToPrintChart = forwardRef((props, ref) => {
	const { singlePeopleTimesheet } = props

	const columns = [
		{
			dataField: "day",
			text: "Day",
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},

		{
			dataField: "worker",
			text: "Worker",
			// width: 200,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "role",
			text: "Role",
			// width: 200,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},

		{
			dataField: "start",
			text: "Start",
			//   type: "number",
			// width: 120,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "end",
			text: "End",
			//   type: "number",
			// width: 120,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "hours",
			text: "Total Hours",
			// width: 150,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		}
		// {
		//   dataField: "extraHours",
		//   text: "Extra Hours",
		//   width: 150,
		//   editable: true,
		//   headerClassName: "header-cell",
		//   cellClassName: "row-cell",
		// },
		// {
		//   dataField: "company",
		//   text: "Company",
		//   width: 200,
		//   editable: true,
		//   headerClassName: "header-cell",
		//   cellClassName: "row-cell",
		// },
	]

	return (
		<ToolkitProvider
			search
			wrapperClasses="table-responsive"
			classes="table table-head-custom table-vertical-center"
			bootstrap4
			keyField="id"
			data={singlePeopleTimesheet || []}
			columns={columns}
		>
			{props => (
				<div ref={ref}>
					<div
						className="d-flex justify-content-between align-items-baseline"
						style={{ marginBottom: "2rem" }}
					>
						<div>
							<h3>Daily contracted workers timesheet</h3>
							<p>Timesheet of contracted workers</p>
						</div>
						<div style={{ width: "10rem", height: "auto" }}>
							<img
								src="/media/logos/crowdkeep.png"
								alt="company logo"
								style={{ width: "100%", height: "100%", objectFit: "contain" }}
							/>
						</div>
					</div>
					<div style={{ width: "100%", height: "100%" }}>
						<BootstrapTable
							defaultSorted={ChartsHelpers.defaultSorted}
							bordered={false}
							key={singlePeopleTimesheet && singlePeopleTimesheet.map(val => val.id)}
							{...props.baseProps}
						/>
					</div>
				</div>
			)}
		</ToolkitProvider>
	)
})

export default TimesheetDailyToPrintChart
