// Progress button #buttonToProgress2 and 3
export function startButtonProgress(button, progressClassName, textClassName) {
	if (button && progressClassName && textClassName) {
		// if (button.id === "buttonToProgress_createPoi") {
		const textElement = button.querySelector(`.${textClassName}`)
		// Simulate counting to 99%
		let percent = 0
		for (var j = 0; j < 100; j++) {
			percent = j
			button.querySelector(`.${progressClassName}`).style.width = `${percent}%`

			if (percent >= 0) {
				textElement.textContent = button.dataset.progressText
			}
			if (percent >= 100) {
				textElement.textContent = button.dataset.completeText
			}
		}
		// }
	}
}

// Finish progress button #buttonToProgress2 and 3
export function finishButtonProgress(button, progressClassName, textClassName, actionFinished) {
	if (button && progressClassName && textClassName && actionFinished === 100) {
		// if (button.id === "buttonToProgress_createPoi") {
		const textElement = button.querySelector(`.${textClassName}`)
		// Simulate counting to 100%
		let percent = actionFinished
		button.querySelector(`.${progressClassName}`).style.width = `${percent}%`

		if (percent >= 0) {
			textElement.textContent = button.dataset.progressText
		}
		if (percent >= 100) {
			textElement.textContent = button.dataset.completeText
		}
		// }
	}
}
