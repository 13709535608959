import React, { useMemo, useEffect, useRef, useCallback } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as actions from "../../_redux/reporting/people/peopleActions"
import { useUIContext } from "../UIContext"
import { AttendanceByRole } from "./Charts/AttendanceByRole"
import { TimesheetPerRole } from "./Charts/TimesheetPerRole"
import TimesheetDaily from "./Charts/TimesheetDaily"
import TimesheetDailyToPrint from "./Charts/TimesheetDailyToPrint/TimesheetDailyToPrint"
import { debounce } from "lodash"

export function PeopleTimesheetPage() {
	const dispatch = useDispatch()

	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams
		}
	}, [UIContext])

	const { group, customer, selectedSite, siteId, people, roles, vendors } = useSelector(
		state => ({
			group: state.persistentReporting?.group,
			customer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			siteId: state.profile?.currentSite?.id,
			people: state.basePage?.people || [],
			roles: state.profile?.currentCustomer?.roles || [],
			vendors: state.profile?.currentCustomer?.vendors
		}),
		shallowEqual
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchSummary = useCallback(
		debounce(() => {
			if (!customer || !selectedSite) return

			dispatch(
				actions.fetchPeopleTimesheet({
					customerId: customer.id,
					siteId,
					selectedSite,
					people: people,
					roles: roles,
					vendors: vendors,
					nodeType: "people",
					...UIProps.queryParams
				})
			)
		}, 300), // Adjust the debounce delay as needed
		[customer, UIProps.queryParams, selectedSite, siteId, people, roles, vendors]
	)

	useEffect(() => {
		fetchSummary()

		// Cleanup the debounce on component unmount
		return () => {
			fetchSummary.cancel()
		}
	}, [fetchSummary])

	// useEffect(() => {
	// 	if (!customer || !selectedSite) return

	// 	dispatch(
	// 		actions.fetchPeopleTimesheet({
	// 			customerId: customer.id,
	// 			siteId,
	// 			selectedSite,
	// 			people: people,
	// 			roles: roles,
	// 			vendors: vendors,
	// 			...UIProps.queryParams
	// 		})
	// 	)
	// }, [customer, UIProps.queryParams, selectedSite, siteId, people, roles, vendors])

	const componentRef = useRef()

	return (
		<div className="row" id="people_timesheet_main_div">
			<>
				<div className="col-12">
					<AttendanceByRole />
				</div>
				<div className="col-12">
					<TimesheetPerRole />
				</div>
				<div className="col-12">
					<TimesheetDaily ref={componentRef} />
				</div>

				<div className="col-12" style={{ overflow: "hidden", height: "0" }}>
					<TimesheetDailyToPrint ref={componentRef} />
				</div>
			</>
		</div>
	)
}
