import React, { useState, useEffect, useRef, useMemo } from "react"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { useSelector, shallowEqual } from "react-redux"
import { Collapse, TextField, makeStyles } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Button } from "../../../../_partials/Button"
import ModelsInventoryTable from "./ModelsInventoryTable"
import NodesInventoryFilter from "./ModelsInventoryFilter"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import SearchIcon from "@material-ui/icons/Search"
import FilterListIcon from "@material-ui/icons/FilterList"
import AddInventoryModelsModal from "./AddInventoryModelsModal"

import { useUIContext } from "../UIContext"
import moment from "moment"
import * as XLSX from "xlsx"
import { useReactToPrint } from "react-to-print"
import { ExportModal } from "../Export/ExportModal"
import TableToPrint from "../TableToPrint/TableToPrint"
import CurrentTableToPrint from "../TableToPrint/CurrentTableToPrint"

const useStyles = makeStyles(() => ({
	autocomplete: {
		width: "170px"
	},
	select: {
		borderColor: "red",
		height: "36px",
		backgroundColor: "#F2F2F2",
		borderRadius: "5px",

		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent"
		},
		"& .MuiInputBase-input": {
			fontWeight: "500"
		}
	}
}))

export const ModelsInventoryCard = ({
	modelsInventory,
	technology,
	setTechnology,
	handleTechnology,
	technologyList
}) => {
	const classes = useStyles()

	// ━━━━━━━━━━━━━  Selectors  ━━━━━━━━━━━━━ \\
	const { listLoading } = useSelector(
		state => ({
			listLoading: state.inventory.listLoading
		}),

		shallowEqual
	)

	//* ━━━━━━━━━━━━━━━━━━━━━━━━━ UIContext
	const UIContext = useUIContext()
	const UIProps = useMemo(() => {
		return {
			rowsToUseModelsTable: UIContext.rowsToUseModelsTable,
			columnVisibilityModelModelsTable: UIContext.columnVisibilityModelModelsTable,
			setColumnVisibilityModelModelsTable: UIContext.setColumnVisibilityModelModelsTable,
			currentModelsTablePageInfo: UIContext.currentModelsTablePageInfo
		}
	}, [UIContext])

	// ━━━━━━━━━ useStates  ━━━━━━━━━
	const [showFilter, setShowFilter] = useState(false)
	//export
	const [showExportModal, setShowExportModal] = useState(false)
	const [checked, setChecked] = useState(false)
	const [optionsOfExport, setOptionsOfExport] = useState(null)
	const [columnVisibilityModelBeforePrint, setColumnVisibilityModelBeforePrint] = useState({})
	// const [technologyList, setTechnologyList] = useState(null)
	//for both components have acess to table rows
	const [rows, setRows] = useState([])
	const [searchText, setSearchText] = React.useState("")
	//fill this options with inventory state
	const [filterTypeOptions, setFilterTypeOptions] = useState([])
	const [filterVendorOptions, setFilterVendorOptions] = useState([])
	const [filterFeaturesOptions, setFilterFeaturesOptions] = useState([])
	const [filterNodeTypeOptions, setFilterNodeTypeOptions] = useState([])
	const [filterChipsetOptions, setFilterChipsetOptions] = useState([])
	const [filterBatteryOptions, setFilterBatteryOptions] = useState([])
	const [showNewModelModal, setShowNewModelModal] = useState(false)

	// ━━━━━━━━━ useEffects  ━━━━━━━━━

	useEffect(() => {
		if (!modelsInventory || !technology) return
		if (optionsOfExport === "print_all_pages") {
			handlePrint()
		}
		if (optionsOfExport === "print_current_page") {
			handleCurrentPagePrint()
		}
		if (optionsOfExport === "excel_all_pages") {
			exportToExcel()
		}
		if (optionsOfExport === "excel_current_page") {
			exportToExcel(UIProps.currentModelsTablePageInfo)
		}
	}, [optionsOfExport])
	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	useEffect(() => {
		if (!modelsInventory || !modelsInventory.length > 0 || !technology) return

		//*For Battery
		const uniqueBattery = Array.from(
			new Set(
				(modelsInventory || [])
					.filter(
						model =>
							model.battery !== "" && model.battery !== null && model.battery !== undefined
					) // Filter out empty strings
					.map(model => model.battery)
			)
		)

		// Create an array of objects with node and status properties
		const batteryOptions = uniqueBattery.map(type => ({ node: type.toString(), selected: false }))

		//*For Chipset
		const uniqueChipset = Array.from(
			new Set(
				(modelsInventory || [])
					.filter(
						model =>
							model.chipset !== "" && model.chipset !== null && model.chipset !== undefined
					) // Filter out empty strings
					.map(model => model.chipset)
			)
		)

		const chipsetOptions = uniqueChipset.map(type => ({ node: type, selected: false }))

		//*For NodeType
		const uniqueNodeType = Array.from(
			new Set(
				(modelsInventory || [])
					.filter(
						model =>
							model.nodeType !== "" &&
							model.nodeType !== null &&
							model.nodeType !== undefined
					) // Filter out empty strings
					.map(model => model.nodeType)
			)
		)

		const nodeTypeOptions = uniqueNodeType.map(type => ({ node: type, selected: false }))

		//*ForeType
		const uniqueType = Array.from(
			new Set(
				(modelsInventory || [])
					.filter(
						model => model.type !== "" && model.type !== null && model.type !== undefined
					) // Filter out empty strings
					.map(model => model.type)
			)
		)

		const typeOptions = uniqueType.map(type => ({ node: type, selected: false }))

		//*For Model
		const uniqueVendor = Array.from(
			new Set(
				(modelsInventory || [])
					.filter(
						model =>
							model.vendor !== "" && model.vendor !== null && model.vendor !== undefined
					) // Filter out empty strings
					.map(model => model.vendor)
			)
		)

		const vendorOptions = uniqueVendor.map(type => ({ node: type, selected: false }))

		//*For Features
		// const uniqueFeatures = Array.from(
		// 	new Set(
		// 		(modelsInventory || [])
		// 			.filter(
		// 				model =>
		// 					model.features !== "" &&
		// 					model.features !== null &&
		// 					model.features !== undefined
		// 			) // Filter out empty strings
		// 			.map(model => model.features)
		// 	)
		// )

		// const featuresOptions = uniqueFeatures.map(type => ({ node: type, selected: false }))

		// Set filterTypeOptions with unique types
		setFilterTypeOptions(typeOptions)
		setFilterVendorOptions(vendorOptions)
		// setFilterFeaturesOptions(featuresOptions)
		setFilterNodeTypeOptions(nodeTypeOptions)
		setFilterChipsetOptions(chipsetOptions)
		setFilterBatteryOptions(batteryOptions)
	}, [modelsInventory, technology])

	//  ━━━━━━━ useRef ━━━━━━━
	const componentRef = useRef()
	const currentPageComponentRef = useRef()

	//  ━━━━━━━ Header Collapse ━━━━━━━
	const header = (
		<div className="d-flex align-items-center">
			<span>Models</span>
		</div>
	)

	//  ━━━━━━━ Handlers ━━━━━━━
	//update the search text state variable.
	const handleSearch = value => {
		setSearchText(value)
	}

	const exportToExcel = (currentModelsTablePageInfo = null) => {
		if (UIProps.rowsToUseModelsTable) {
			const filteredDataAfterSort =
				UIProps.rowsToUseModelsTable &&
				UIProps.rowsToUseModelsTable.map(model => ({
					name: model.name || "",
					vendor: model.vendor || "",
					nodeType: model.nodeType || "",
					type: model.type || "",
					battery: model.battery || "",
					chipset: model.chipset || "",
					ipProtection: model.ipProtection || "",

					led: model.feature.led || "",
					button: model.feature.button || "",
					buzzer: model.feature.buzzer || "",
					motion: model.feature.motion || "",
					nfc: model.feature.nfc || ""
				}))

			const filteredDataAfterColumnsChange =
				filteredDataAfterSort &&
				filteredDataAfterSort.map(personData => {
					const filteredData = Object.entries(personData)
						.filter(([key]) => {
							const isVisible = UIProps.columnVisibilityModelModelsTable[key]
							if (isVisible === undefined) {
								console.warn(`Visibility setting for ${key} is undefined.`)
							}
							return isVisible !== false && isVisible !== undefined
						})
						.reduce((acc, [key, value]) => {
							acc[key] = value
							return acc
						}, {})
					return filteredData
				})

			let finalData = []
			if (currentModelsTablePageInfo) {
				// console.log(currentModelsTablePageInfo?.page && currentModelsTablePageInfo?.pageSize)
				finalData =
					filteredDataAfterColumnsChange &&
					filteredDataAfterColumnsChange.slice(
						currentModelsTablePageInfo?.page * currentModelsTablePageInfo?.pageSize,
						(currentModelsTablePageInfo?.page + 1) * currentModelsTablePageInfo?.pageSize
					)
			} else {
				finalData = filteredDataAfterColumnsChange && filteredDataAfterColumnsChange
			}

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(finalData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 },
				{ wch: 30 }
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = `Inventory_Models_Table_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}
	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: () => getPageMargins()
	})
	const handleCurrentPagePrint = useReactToPrint({
		content: () => currentPageComponentRef.current,
		pageStyle: () => getPageMargins()
	})

	return (
		<Card
			data-testid="ModelsInventoryCard"
			style={{
				display: "flex",
				flexDirection: "column"
			}}
		>
			<CardHeader
				title={header}
				toolbar={
					<div className="d-flex align-items-center">
						<SearchBarCustom1
							onSearch={handleSearch}
							searchText={searchText}
							disabled={listLoading || !technology ? true : false}
							placeholder="Search"
							size="small"
							style={{
								width: "200px",
								background: "#F2F2F2",
								marginRight: "10px"
							}}
							InputProps={{
								startAdornment: (
									<SearchIcon
										fontSize="small"
										style={{
											color: "#7F8C8D",
											marginRight: "0.7rem"
										}}
									/>
								)
							}}
						/>
						<Autocomplete
							id="audit_tool_users_autocomplete"
							selectOnFocus
							clearOnBlur
							disableClearable
							handleHomeEndKeys
							className={classes.autocomplete}
							options={technologyList || []}
							size="small"
							getOptionLabel={option => {
								return option || ""
							}}
							value={technology}
							onChange={handleTechnology}
							renderInput={params => (
								<TextField
									className={classes.select}
									{...params}
									placeholder="Technology"
									variant="outlined"
								/>
							)}
							popupIcon={<ExpandMoreIcon style={{ color: "#8C8CA2" }} />} // Your custom arrow icon
						/>
						<Button
							id="manage_site_new_building_btn"
							className="ml-2"
							color="primary"
							variant="contained"
							text="FILTER"
							style={{
								height: "34.44px"
							}}
							disabled={listLoading || !technology ? true : false}
							onClick={() => {
								setShowFilter(prev => !prev)
							}}
							endIcon={<FilterListIcon />}
							data-testid="filter"
						/>
						<Button
							id="manage_site_new_building_btn"
							className="ml-2 mr-3"
							color="primary"
							variant="contained"
							text="NEW MODEL"
							style={{
								height: "34.44px"
							}}
							disabled={listLoading || !technology ? true : false}
							onClick={() => {
								setShowNewModelModal(prev => !prev)
							}}
							data-testid="CREATE"
						/>
						<Button
							color="primary"
							variant="contained"
							text="Export"
							disabled={listLoading || !technology ? true : false}
							style={{
								height: "34.44px"
							}}
							onClick={() => {
								setShowExportModal(prev => !prev)
							}}
						/>
					</div>
				}
				// line={false}
			/>
			<Collapse in={true}>
				<CardBody style={{ padding: "10px" }}>
					<ModelsInventoryTable
						filterTypeOptions={filterTypeOptions}
						filterVendorOptions={filterVendorOptions}
						filterFeaturesOptions={filterFeaturesOptions}
						filterNodeTypeOptions={filterNodeTypeOptions}
						filterChipsetOptions={filterChipsetOptions}
						filterBatteryOptions={filterBatteryOptions}
						technology={technology}
						rows={rows}
						setRows={setRows}
						searchText={searchText}
						setSearchText={setSearchText}
					/>
					{showFilter && (
						<NodesInventoryFilter
							showFilter={showFilter}
							setShowFilter={setShowFilter}
							filterTypeOptions={filterTypeOptions}
							setFilterTypeOptions={setFilterTypeOptions}
							filterVendorOptions={filterVendorOptions}
							setFilterVendorOptions={setFilterVendorOptions}
							filterFeaturesOptions={filterFeaturesOptions}
							setFilterFeaturesOptions={setFilterFeaturesOptions}
							filterNodeTypeOptions={filterNodeTypeOptions}
							setFilterNodeTypeOptions={setFilterNodeTypeOptions}
							filterChipsetOptions={filterChipsetOptions}
							setFilterChipsetOptions={setFilterChipsetOptions}
							filterBatteryOptions={filterBatteryOptions}
							setFilterBatteryOptions={setFilterBatteryOptions}
						/>
					)}
					{showNewModelModal && (
						<AddInventoryModelsModal
							setShowNewModelModal={setShowNewModelModal}
							technology={technology}
						/>
					)}

					<div style={{ overflow: "hidden", height: "0" }}>
						<TableToPrint
							rowsToUse={UIProps.rowsToUseModelsTable}
							columnVisibilityModel={UIProps.columnVisibilityModelModelsTable}
							columns={[
								{
									dataField: "name",
									text: "Name",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "vendor",
									text: "Vendor",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "nodeType",
									text: "Node Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "type",
									text: "type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "battery",
									text: "Battery",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "chipset",
									text: "Chipset",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.led",
									text: "LED",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "ipProtection",
									text: "Ip Protection",
									type: "string",
									width: 150
								},
								{
									dataField: "features.button",
									text: "Button",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.buzzer",
									text: "Buzzer",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.motion",
									text: "Motion",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.nfc",
									text: "NFC",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={componentRef}
						/>
						<CurrentTableToPrint
							rowsToUse={UIProps.rowsToUseModelsTable}
							currentTablePageInfo={UIProps.currentModelsTablePageInfo}
							columnVisibilityModel={UIProps.columnVisibilityModelModelsTable}
							columns={[
								{
									dataField: "name",
									text: "Name",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "vendor",
									text: "Vendor",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "nodeType",
									text: "Node Type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "type",
									text: "type",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "battery",
									text: "Battery",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "chipset",
									text: "Chipset",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.led",
									text: "LED",
									type: "string",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "ipProtection",
									text: "Ip Protection",
									type: "string",
									width: 150
								},
								{
									dataField: "features.button",
									text: "Button",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.buzzer",
									text: "Buzzer",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.motion",
									text: "Motion",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								},
								{
									dataField: "features.nfc",
									text: "NFC",
									type: "bool",
									style: {
										verticalAlign: "middle",
										minWidth: "150px"
									}
								}
							]}
							ref={currentPageComponentRef}
						/>

						<ExportModal
							contentClassName="bg-transparent"
							show={showExportModal}
							onHide={() => setShowExportModal(false)}
							columnsForExport={[
								"name",
								"vendor",
								"nodeType",
								"type",
								"battery",
								"chipset",
								"ipProtection",
								"led",
								"button",
								"buzzer",
								"motion",
								"nfc"
							]}
							setOptionsOfExport={setOptionsOfExport}
							columnVisibilityModelBeforePrint={columnVisibilityModelBeforePrint}
							rowsToUse={UIProps.rowsToUseModelsTable}
							setColumnVisibilityModel={UIProps.setColumnVisibilityModelModelsTable}
						/>
					</div>
				</CardBody>
			</Collapse>
		</Card>
	)
}

function escapeRegExp(value) {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}
