import { firestoreOld } from "../../../../firebase"
import firebase from "firebase/compat/app"
import moment from "moment"

const customersRef = firestoreOld.collection("Customers")
const sitesRef = firestoreOld.collection("Sites")

export function getIconsFromDB() {
	return firestoreOld
		.collection("GlobalOptions")
		.doc("pointsOfInterestIcons")
		.collection("POIsIcons")
		.orderBy("listOrder", "asc")
		.get()
}

export function getThings(queryParams) {
	return customersRef.doc(queryParams.customerId).collection("Things").get()
}

export async function getUsers(queryParams) {
	const userDoc = await firestoreOld
		.doc(`Customers/${queryParams.customerId}/Users/${queryParams.userId}`)
		.get()

	const user = userDoc.data()
	if (!user?.buddies?.length > 0) {
		return
	}
	var newUsers = [user]

	const results = await Promise.all(
		user.buddies.map(val => {
			return firestoreOld.doc(`Customers/${queryParams.customerId}/Users/${val}`).get()
		})
	)
	const newBuddies = results.map(doc => doc.data())
	newUsers = [...newUsers, ...newBuddies]
	return newUsers
}

export function getPeopleStats(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(queryParams.customerId)
		.collection(queryParams.selectedLocation)
		.doc("peopleStats")
		.get()
}

export function getAllLocationsPeopleStats(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return Promise.all(
		queryParams.customerId.merakiNetworks.map(location => {
			return customersRef
				.doc(queryParams.customerId.id)
				.collection(location.netId)
				.doc("peopleStats")
				.get()
		})
	)
}

export function getAllLocationsMerakiDevices(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return Promise.all(
		queryParams.customerId.merakiNetworks.map(location => {
			return customersRef
				.doc(queryParams.customerId.id)
				.collection(location.netId)
				.doc("merakiDevices")
				.get()
		})
	)
}

export function getDailyUsers(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	var startDate = new moment()
	var endDate = new moment()
	startDate.subtract(endDate.date() - 1, "days")

	if (queryParams.selectedLocation.customerType == "office") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.SSIDs))
		SSIDs && SSIDs.push("")
	} else if (queryParams.selectedLocation.customerType == "shop") {
		var SSIDs = JSON.parse(JSON.stringify(queryParams?.selectedLocation?.guestSSID))
		SSIDs && SSIDs.push("")
	}

	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")
	var raw = JSON.stringify({
		typechart: "globalInfo",
		networkId: queryParams.selectedLocation.netId,
		ssid: SSIDs,
		startDate: moment(startDate).format("YYYY-MM-DD"),
		endDate: moment(endDate).format("YYYY-MM-DD[T23:59:59]")
	})
	var requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL}/bigquery/reporting/floors/${queryParams.selectedCustomer.customerId}`,
		requestOptions
	).catch(error => console.log("Error", error))

	// return fetch(`https://europe-west2-ufind-prod.cloudfunctions.net/tmp_apigw/privapi/v1/bigquery/reporting/floors/${queryParams.customer.customerId}`, requestOptions)
}

export function getPois(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(queryParams.siteId).collection("PointsOfInterest").get()
}

export function getAreasInDB(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(queryParams.siteId).collection("Areas").get()
}

export function getPreferencesInDB(queryParams) {
	if (!queryParams || !queryParams?.userId) {
		return Promise.resolve(null)
	}

	return firestoreOld.collection("UserPreferences").doc(queryParams.userId).get()
}

// export function getZonesInDB({ selectedCustomer, selectedLocation, floorPlanSelected }) {
// 	if (!selectedCustomer || !selectedLocation || !floorPlanSelected) {
// 		return Promise.resolve(null)
// 	}

// 	return customersRef
// 		.doc(selectedCustomer)
// 		.collection(`Zones`)
// 		.where("netId", "==", selectedLocation)
// 		.where("floorPlanId", "==", floorPlanSelected.id)
// 		.get()
// }

export function getResourcesMeetings({ selectedCustomer, selectedLocation, floorPlanSelected }) {
	if (!selectedCustomer) {
		return Promise.resolve(null)
	}

	return (
		customersRef
			.doc(selectedCustomer)
			.collection(`Resources`)
			.where("networkId", "==", selectedLocation)
			// .where("floorPlanId", "==", floorPlanSelected.id)
			.where("type", "==", "meeting")
			.orderBy("created.date")
			.get()
	)
}

export function createNodes(queryParams) {
	if (!queryParams || !queryParams?.newNodesArray?.[0]) {
		return Promise.resolve(null)
	}

	return Promise.all(
		queryParams.newNodesArray.map(node => {
			const generatedUUID = node.uuid.toString()
			// console.log("🚀  generatedUUID:", generatedUUID)

			return sitesRef
				.doc(queryParams.selectedSite.id)
				.collection("Nodes")
				.doc(generatedUUID)
				.set({
					mockNode: node.mockNode,
					floorPlanId: node.floorPlanId,
					siteId: node.siteId,
					gpsData: node.gpsData,
					status: node.status,
					tagState: node.tagState,
					nodeType: node.nodeType,
					networkAddress: node.networkAddress,
					uuid: node.uuid,
					is_approved: node.is_approved,
					geoHash: node.geoHash
				})
				.then(() => {
					console.log(`Node successfully created for UUID: ${generatedUUID}`)
				})
				.catch(error => {
					console.error(`Error creating node for UUID ${generatedUUID}:`, error)
				})
		})
	).catch(error => {
		console.error("Error creating nodes:", error)
	})
}

export function saveUserFilterPreferences(queryParams) {
	if (!queryParams || !queryParams[0] || !queryParams[0].userId || !queryParams[0].preferences) {
		console.error("Invalid queryParams. Unable to save preferences.")
		return Promise.resolve(null)
	}

	const userId = queryParams?.[0].userId
	const preferences = queryParams?.[0].preferences

	return firestoreOld
		.collection("UserPreferences")
		.doc(userId)
		.set({
			userId: userId,
			preferences: preferences,
			updated: {
				id: userId,
				date: firebase.firestore.FieldValue.serverTimestamp()
			}
		})
}
