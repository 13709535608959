/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useState } from "react"
import { useSelector, shallowEqual } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import SearchIcon from "@material-ui/icons/Search"
import { SearchBarCustom1 } from "../../../../_partials/inputs/SearchBarCustom1"
import * as ChartsHelpers from "./ChartsHelpers"
import { Dropdown } from "react-bootstrap"
import { sortCaret } from "../../../../../_metronic/_helpers"
import { ModalProgressBar } from "../../../../_partials/ModalProgressBar"
// import ReactToPrint from "react-to-print"
import { useReactToPrint } from "react-to-print"
import { Button } from "../../../../_partials/Button"
import * as XLSX from "xlsx"
import moment from "moment"

const TimesheetDaily = forwardRef((props, ref) => {
	const { isLoading, singlePeopleTimesheet } = useSelector(
		state => ({
			isLoading: state.reporting.singlePeopleTimesheetLoading,
			singlePeopleTimesheet: state.reporting.singlePeopleTimesheet
		}),
		shallowEqual
	)

	const [optionsOfExport, setOptionsOfExport] = useState(null)

	const columns = [
		{
			dataField: "day",
			text: "Day",
			sort: true,
			sortCaret: sortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const dateA = moment(a, "ddd MMM DD", "en")
				const dateB = moment(b, "ddd MMM DD", "en")

				if (order === "asc") return dateA - dateB
				else return dateB - dateA
			},
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},

		{
			dataField: "worker",
			text: "Worker",
			// width: 200,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "role",
			text: "Role",
			// width: 200,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "company",
			text: "Company",
			// width: 200,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},

		{
			dataField: "start",
			text: "Check-in",
			//   type: "number",
			// width: 120,
			sort: true,
			sortCaret: sortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const timeA = moment(a, "hh:mm A")
				const timeB = moment(b, "hh:mm A")

				if (order === "asc") return timeA - timeB
				else return timeB - timeA
			},
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "end",
			text: "Check-out",
			//   type: "number",
			// width: 120,
			sort: true,
			sortCaret: sortCaret,
			sortFunc: (a, b, order, dataField, rowA, rowB) => {
				const timeA = moment(a, "hh:mm A")
				const timeB = moment(b, "hh:mm A")

				if (order === "asc") return timeA - timeB
				else return timeB - timeA
			},
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		},
		{
			dataField: "hours",
			text: "Total Hours",
			// width: 150,
			sort: true,
			sortCaret: sortCaret,
			style: {
				minWidth: "150px",
				verticalAlign: "middle"
			}
		}
		// {
		//   dataField: "extraHours",
		//   text: "Extra Hours",
		//   width: 150,
		//   editable: true,
		//   headerClassName: "header-cell",
		//   cellClassName: "row-cell",
		// },
		// {
		//   dataField: "company",
		//   text: "Company",
		//   width: 200,
		//   editable: true,
		//   headerClassName: "header-cell",
		//   cellClassName: "row-cell",
		// },
	]

	useEffect(() => {
		if (!singlePeopleTimesheet) return

		// Give marginTop on paginationFactory div
		const htmlCollection = document.getElementsByClassName("react-bootstrap-table-pagination")

		if (htmlCollection) {
			Array.from(htmlCollection).forEach(element => {
				element.style.marginTop = "2rem"
			})
		}
	}, [singlePeopleTimesheet])

	const paginationOptions = paginationFactory({
		sizePerPageList: ChartsHelpers.sizePerPageList,
		sizePerPage: ChartsHelpers.initialFilter.pageSize,
		sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
			<Dropdown>
				<Dropdown.Toggle variant="light" id="dropdown-basic">
					{currSizePerPage}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{options.map(option => {
						return (
							<Dropdown.Item
								key={option.text}
								as="button"
								onClick={() => onSizePerPageChange(option.page)}
							>
								{option.text}
							</Dropdown.Item>
						)
					})}
				</Dropdown.Menu>
			</Dropdown>
		)
	})

	const getPageMargins = () => {
		return `@page { margin: 2rem 2rem !important; }`
	}

	const handlePrint = useReactToPrint({
		content: () => ref.current,
		pageStyle: () => getPageMargins()
	})

	const exportToExcel = () => {
		if (singlePeopleTimesheet) {
			const filteredData = singlePeopleTimesheet.map(val => {
				let result = {
					Day: val.day,
					Worker: val.worker,
					Role: val.role,
					Company: val.company || "",
					Start: val.start,
					End: val.end,
					Total: val.hours
				}
				return result
			})
			// console.log("🚀  filteredData:", filteredData)

			const workbook = XLSX.utils.book_new()
			const worksheet = XLSX.utils.json_to_sheet(filteredData) // send Data to Excel

			// Set column widths manually (in pixels)
			const columnWidths = [
				{ wch: 20 }, // Width of the first column (Day)
				{ wch: 30 }, // Width of the second column (Worker)
				{ wch: 20 }, // Width of the third column (Role)
				{ wch: 15 }, // Width of the third column (Start)
				{ wch: 15 }, // Width of the third column (End)
				{ wch: 25 } // Width of the third column (Total)
			]

			const dateToday = moment().format("ddd DD MMM")

			worksheet["!cols"] = columnWidths

			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1")

			const excelBuffer = XLSX.write(workbook, { type: "buffer" })
			const blob = new Blob([excelBuffer], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			})
			const url = URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.href = url
			a.download = `DailyWorkersTimesheet_${dateToday}.xlsx`
			a.click()
			URL.revokeObjectURL(url)
		}
	}

	const exportOptions = [
		{ name: "Print or PDF", value: "print" },
		{ name: "Excel", value: "excel" }
	]

	useEffect(() => {
		if (!singlePeopleTimesheet) return

		if (optionsOfExport === "print") {
			handlePrint()
		}
		if (optionsOfExport === "excel") {
			exportToExcel()
		}
	}, [optionsOfExport])

	useEffect(() => {
		setOptionsOfExport(null)
	}, [optionsOfExport])

	return (
		<Card>
			<CardBody
				style={{
					// height: "600px" ,
					height: "auto"
				}}
			>
				<ToolkitProvider
					search
					wrapperClasses="table-responsive"
					classes="table table-head-custom table-vertical-center"
					bootstrap4
					keyField="id"
					data={singlePeopleTimesheet ? singlePeopleTimesheet : []}
					columns={columns}
				>
					{props => (
						<div>
							<div className="row">
								<div className="col p-0">
									<h6
										style={{
											marginBottom: "0.1rem",
											fontWeight: "600",
											fontSize: "1.275rem",
											color: "#212121"
										}}
									>
										Daily contracted workers timesheet
									</h6>
									<span
										style={{
											fontWeight: "500",
											fontSize: "0.925rem",
											color: "#B5B5C3"
										}}
									>
										Timesheet of contracted workers
									</span>
								</div>

								<div className="col d-flex justify-content-end align-items-center	">
									<SearchBarCustom1
										{...props.searchProps}
										placeholder="Search"
										size="small"
										style={{
											backgroundColor: "#ECF1F4",
											borderRadius: "4px"
											// marginBottom: "0.5rem"
										}}
										InputProps={{
											startAdornment: (
												<SearchIcon
													fontSize="small"
													style={{
														color: "#7F8C8D",
														marginRight: "0.7rem"
													}}
												/>
											)
										}}
									/>

									{singlePeopleTimesheet && (
										<Dropdown
											onSelect={eventKey => {
												setOptionsOfExport(eventKey)
											}}
											style={{ marginLeft: "10px" }}
										>
											<Dropdown.Toggle className="export_daily_workers_dropdown_btn">
												EXPORT
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{exportOptions &&
													exportOptions.map((option, i) => {
														return (
															<Dropdown.Item
																key={i}
																eventKey={option.value}
																id={
																	"reporting_daily_workers_timesheet_" +
																	option.value
																}
															>
																<span style={{ fontFamily: "Poppins" }}>
																	{option.name}
																</span>
															</Dropdown.Item>
														)
													})}
											</Dropdown.Menu>
										</Dropdown>
									)}
									{/* <ReactToPrint
										trigger={() => (
											<Button
												style={{ marginLeft: "10px" }}
												variant="contained"
												color="primary"
												text="Export"
											/>
										)}
										content={() => ref.current}
										pageStyle={getPageMargins}
									/> */}
									{/* <Button
										style={{ marginLeft: "10px" }}
										variant="contained"
										color="primary"
										text="Export"
										onClick={handlePrint}
									/> */}
									{/* <Button
										style={{ marginLeft: "10px" }}
										variant="contained"
										color="primary"
										text="Export Excel"
										onClick={exportToExcel}
									/> */}
								</div>
							</div>

							<div
								style={{
									height: "2rem"
								}}
							></div>
							{isLoading && <ModalProgressBar variant="query" />}
							<BootstrapTable
								defaultSorted={ChartsHelpers.defaultSorted}
								bordered={false}
								pagination={paginationOptions}
								key={singlePeopleTimesheet && singlePeopleTimesheet.map(val => val.id)}
								{...props.baseProps}
							/>
						</div>
					)}
				</ToolkitProvider>
			</CardBody>
		</Card>
	)
})

export default TimesheetDaily
