import React from "react"

function SelectionCheckbox({ isSelected, onChange }) {
	return (
		<>
			<input type="checkbox" style={{ display: "none" }} />
			<label className="checkbox checkbox-single">
				<input type="checkbox" checked={isSelected} onChange={onChange} />
				<span />
			</label>
		</>
	)
}

function groupingItemOnSelect(props) {
	const { ids, setIds, rowKey, entities, setShowErrorSnackbar } = props

	//*Uncomment const & if to not allow select users without uuid
	// Get the specific row based on the rowKey (UUID)
	// const selectedPerson = entities && entities.find(person => person.id === rowKey)

	// // Check if the selectedPerson has a specific condition to prevent disabling
	// if (selectedPerson && selectedPerson.uuid) {
	// 	// Display a message or perform some action to inform the user
	// 	// alert("You cannot disable this person.")
	// 	setShowErrorSnackbar && setShowErrorSnackbar(true)
	// 	return
	// }

	if (ids.some(id => id === rowKey)) {
		setIds(ids.filter(id => id !== rowKey))
	} else {
		const newIds = [...ids]
		newIds.push(rowKey)
		setIds(newIds)
	}
}

function groupingAllOnSelect(props) {
	const { isSelected, setIds, entities } = props
	if (!isSelected) {
		const allIds = []
		entities && entities.forEach(e => allIds.push(e.id || e.uid))
		setIds(allIds)
	} else {
		setIds([])
	}
	return isSelected
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRowUUID(props) {
	const { entities, ids, setIds, setShowErrorSnackbar } = props

	// Extract row keys from the selected rows
	const selectedRowKeys = ids && ids.map(id => id.toString()) // Assuming row keys are strings

	return {
		mode: "checkbox",
		clickToSelect: true,
		hideSelectAll: false,
		// selected: ids ? ids : [],
		// selected: ids,
		// onSelect: (row, isSelect, rowIndex, e) => {
		// 	console.log("row.id -> ", row.id)
		// 	console.log("isSelect -> ", isSelect)
		// 	console.log("rowIndex -> ", rowIndex)
		// 	console.log("e -> ", e)
		// 	const { ids, setIds, rowKey } = props
		// 	if (ids.some(id => id === rowKey)) {
		// 		setIds(ids.filter(id => id !== rowKey))
		// 	} else {
		// 		const newIds = [...ids]
		// 		newIds.push(rowKey)
		// 		setIds(newIds)
		// 	}
		// },
		selectionHeaderRenderer: () => {
			const isSelected = entities && entities.length > 0 && entities.length === ids && ids.length
			const props = { isSelected, entities, setIds }
			return (
				<SelectionCheckbox
					isSelected={isSelected}
					onChange={() => groupingAllOnSelect(props)}
				/>
			)
		},
		selectionRenderer: ({ rowKey }) => {
			const isSelected = ids && ids.some(id => id === rowKey)
			const props = { ids, setIds, rowKey, entities, setShowErrorSnackbar }
			return (
				<SelectionCheckbox
					isSelected={isSelected}
					onChange={() => groupingItemOnSelect(props)}
				/>
			)
		},
		onSelectAll: (isSelected, rows) => {
			//with this we only select the rows that have uuid null, because this are the ones we can disable
			// const currentPageIds = rows && rows.filter(row => row.uuid === null).map(row => row.id) // Assuming you have unique IDs for each row

			//for now ignore the above, select all for geofencing
			const currentPageIds = rows && rows.map(row => row.id) // Assuming you have unique IDs for each row

			if (isSelected) {
				setIds(currentPageIds)
			} else {
				setIds([])
			}
		}
	}
}

export function getSelectRow(props) {
	const { entities, ids, setIds, setShowErrorSnackbar } = props

	// Extract row keys from the selected rows
	const selectedRowKeys = ids && ids.map(id => id.toString()) // Assuming row keys are strings

	return {
		mode: "checkbox",
		clickToSelect: true,
		hideSelectAll: false,
		selected: ids ? ids : [],
		// selected: ids,
		// onSelect: (row, isSelect, rowIndex, e) => {
		// 	console.log("row.id -> ", row.id)
		// 	console.log("isSelect -> ", isSelect)
		// 	console.log("rowIndex -> ", rowIndex)
		// 	console.log("e -> ", e)
		// 	const { ids, setIds, rowKey } = props
		// 	if (ids.some(id => id === rowKey)) {
		// 		setIds(ids.filter(id => id !== rowKey))
		// 	} else {
		// 		const newIds = [...ids]
		// 		newIds.push(rowKey)
		// 		setIds(newIds)
		// 	}
		// },
		selectionHeaderRenderer: params => {
			const isSelected = entities && entities.length > 0 && entities.length === ids && ids.length
			const props = { isSelected, entities, setIds }

			return (
				<SelectionCheckbox
					isSelected={isSelected}
					onChange={() => groupingAllOnSelect(props)}
				/>
			)
		},
		selectionRenderer: ({ rowKey }) => {
			const isSelected = ids && ids.some(id => id === rowKey)
			const props = { ids, setIds, rowKey, entities, setShowErrorSnackbar }
			return (
				<SelectionCheckbox
					isSelected={isSelected}
					onChange={() => groupingItemOnSelect(props)}
				/>
			)
		},
		onSelectAll: (isSelected, rows) => {
			const currentPageIds = rows && rows.map(row => row.id) // Assuming you have unique IDs for each row

			if (isSelected) {
				setIds(currentPageIds)
			} else {
				setIds([])
			}
		}
	}
}

//to remove checkbox from !uuid people
// export function getSelectRow(props) {
// 	const { entities, ids, setIds } = props;

// 	return {
// 	  mode: "checkbox",
// 	  clickToSelect: true,
// 	  hideSelectAll: false,
// 	  selectionHeaderRenderer: () => {
// 		const isSelected =
// 		  entities && entities.length > 0 &&
// 		  entities.every(entity => entity.uuid === null || entity.uuid === undefined);
// 		const props = {
// 		  isSelected,
// 		  entities,
// 		  setIds
// 		};
// 		return (
// 		  <SelectionCheckbox
// 			isSelected={isSelected}
// 			onChange={() => groupingAllOnSelect(props)}
// 		  />
// 		);
// 	  },
// 	  selectionRenderer: ({ rowKey }) => {
// 		const isSelected = ids.some(id => id === rowKey);
// 		const entity = entities && entities.find(entity => entity.id === rowKey);
// 		if (entity && (entity.uuid === null || entity.uuid === undefined)) {
// 		  const props = { ids, setIds, rowKey };
// 		  return (
// 			<SelectionCheckbox
// 			  isSelected={isSelected}
// 			  onChange={() => groupingItemOnSelect(props)}
// 			/>
// 		  );
// 		}
// 		return null; // Do not render the checkbox if uuid is not null or undefined
// 	  },
// 	  onSelectAll: (isSelected, rows) => {
// 		const currentPageIds = rows
// 		  .filter(row => {
// 			const entity = entities && entities.find(entity => entity.id === row.id);
// 			return entity && (entity.uuid === null || entity.uuid === undefined);
// 		  })
// 		  .map(row => row.id); // Assuming you have unique IDs for each row
// 		if (isSelected) {
// 		  setIds(currentPageIds);
// 		} else {
// 		  setIds([]);
// 		}
// 	  }
// 	};
//   }
