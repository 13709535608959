import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../../_partials/typography"
import { IconButton, Grid, makeStyles } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { Button } from "../../../../../_partials/Button"
import { Alert } from "@material-ui/lab"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { ModalProgressBar } from "../../../../../_partials/ModalProgressBar"

const ApproveModal = ({
	showApproveModal,
	setShowApproveModal,
	selectedRows,
	initialData,
	saveApprovedAnchors
}) => {
	const dispatch = useDispatch()
	const { selectedCustomerId, selectedSite, user } = useSelector(
		state => ({
			selectedCustomerId: state.profile?.currentCustomer?.id,
			selectedSite: state.profile?.currentSite, // new selectedLocation
			user: state.auth.user
		}),
		shallowEqual
	)

	// States
	const [plannedAnc, setPlannedAnc] = useState([])
	const [placedAnc, setPlacedAnc] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!initialData || !selectedRows) return

		const selectedData =
			selectedRows && selectedRows.map(r => initialData && initialData.find(val => val.id === r))

		if (selectedData) {
			// const planned = selectedData.filter(val => val?.placedOnSite === "Planned")
			const planned = selectedData.filter(val => val?.status === "planned")
			setPlannedAnc(planned)
			// const placed = selectedData.filter(val => val?.placedOnSite === "Placed")
			const placed = selectedData.filter(val => val?.status === "placed")
			setPlacedAnc(placed)
		}
	}, [initialData, selectedRows])

	const approveAnchorsFromAPI = () => {
		setIsLoading(true)

		const nodesArray = []
		// console.log("🚀 ~ approveAnchorsFromAPI ~ placedAnc:", placedAnc)

		placedAnc &&
			placedAnc.forEach(val => {
				nodesArray.push({
					nodeId: val.uuid,
					nodeName: val.node_name,
					nodeDescription: val.description,
					latitude: val.gpsData.latitude,
					longitude: val.gpsData.longitude,
					wirepasFloorPlanId: val.wirepasFloorPlanId,
					networkAddress: Number(val.network_address)
				})
			})
		// console.log("🚀 ~ approveAnchorsFromAPI ~ placedAnc:", placedAnc)

		const reqParams = {
			siteId: selectedSite.id,
			customerId: selectedCustomerId,
			email: user.email,
			userId: user.id,
			nodes: nodesArray
		}
		// console.log("🚀 ~ approveAnchorsFromAPI ~ reqParams:", reqParams)
		// console.log("🚀 ~ approveAnchorsFromAPI ~ reqParams:", reqParams)

		// Call API function
		var myHeaders = new Headers()
		myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
		myHeaders.append("Content-Type", "application/json")
		var raw = JSON.stringify(reqParams)

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		}
		// console.log("🚀 ~ approveAnchorsFromAPI ~ requestOptions.raw:", raw)

		fetch(`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/manageNodes`, requestOptions)
			.then(response => {
				// console.log("🚀 ~ approveAnchorsFromAPI ~ response:", response)
				if (response.status !== 200) {
					dispatch({
						type: "SNACKBAR_ERROR",
						payload: "Could not approve anchors. Please try again."
					})
					setIsLoading(false)
					setShowApproveModal(false)

					return
				}
				return response.json()
			})
			.then(res => {
				// console.log("🚀 ~ approveAnchorsFromAPI ~ res:", res)

				if (!res) return

				setIsLoading(false)
				placedAnc && saveApprovedAnchors(placedAnc) // Save approved in DB function
				setShowApproveModal(false)

				const approveMsg = (
					<span>
						Successfully approved anchor
						{res?.approvedNodes && res.approvedNodes.length > 1 && <span>s</span>} with the
						following UUID:
						<ul className="mt-3">
							{res?.approvedNodes &&
								res.approvedNodes.map(val => {
									return (
										<li key={val}>
											<b>{val}</b>
										</li>
									)
								})}
						</ul>
					</span>
				)

				res?.approvedNodes &&
					res.approvedNodes.length > 1 &&
					dispatch({
						type: "SNACKBAR_SUCCESS",
						payload: approveMsg
					})
			})
			.catch(error => {
				console.log("Error approving anchors! ", error)
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: "Anchors not approved."
				})
				setIsLoading(false)
				setShowApproveModal(false)
			})
	}

	return (
		<div>
			<Modal
				show={showApproveModal}
				onHide={() => {
					setShowApproveModal(false)
				}}
				contentClassName="bg-transparent"
			>
				<Modal.Body
					style={{
						padding: "0",
						backgroundColor: "#FFF",
						borderRadius: "10px",
						overflow: "hidden"
					}}
				>
					<div className="pl-7 pr-7 pb-7 pt-2">
						{/* ━━━━━━  TITLE ━━━━━━ */}
						<div className="d-flex align-items-center justify-content-between">
							<div>
								<Bold>Approve Anchors</Bold>
							</div>
							<div>
								<IconButton
									onClick={() => {
										setShowApproveModal(false)
									}}
									style={{ left: "12px" }}
								>
									<CloseIcon />
								</IconButton>
							</div>
						</div>
						{isLoading && <ModalProgressBar variant="query" />}
						{/* ━━━━━━  BODY  ━━━━━━ */}
						<Grid container spacing={2} className="mt-3" style={{ fontFamily: "Poppins" }}>
							<Grid item xs={12}>
								<span>
									Approve placed anchor
									{placedAnc && placedAnc.length > 1 && <span>s</span>} with the following
									UUID
									{placedAnc && placedAnc.length > 1 && <span>'s</span>}?{" "}
									<ul className="mt-3">
										{placedAnc &&
											placedAnc.map(val => {
												return (
													<li key={val.id}>
														<b>{val.uuid}</b>
													</li>
												)
											})}
									</ul>
								</span>
							</Grid>
						</Grid>
						{plannedAnc && plannedAnc.length > 0 && (
							<Grid item xs={12}>
								<div style={{ marginTop: "1rem", fontFamily: "Poppins" }}>
									<Alert severity="warning">
										<p style={{ textAlign: "left" }}>
											Cannot approve planned anchors. <br />
											Please go to map and edit each one to be placed, before approval.{" "}
											<br />
										</p>
									</Alert>

									<div
										style={{ marginTop: "2rem", marginBottom: "3rem", fontSize: "11px" }}
									>
										<div style={{ display: "inline-block" }}>
											The following UUID's are from planned anchors:{" "}
											{plannedAnc &&
												plannedAnc.length > 0 &&
												plannedAnc.map((val, index) => (
													<span key={val.id}>
														<b>{val.uuid}</b>
														{index < plannedAnc.length - 1 ? ", " : "."}
													</span>
												))}
										</div>
									</div>
								</div>
							</Grid>
						)}

						{/* ━━━━━━  FOOTER ━━━━━━ */}
						<div className="d-flex mt-4">
							<Button
								disabled={isLoading}
								fullWidth
								color="primary"
								variant="outlined"
								onClick={() => {
									setShowApproveModal(false)
								}}
								className="mr-2"
								text="Cancel"
							/>
							<Button
								disabled={isLoading}
								fullWidth
								variant="contained"
								color="primary"
								onClick={() => {
									approveAnchorsFromAPI()
								}}
								className="ml-2"
								text="Approve"
							/>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default ApproveModal

const useStyles = makeStyles(theme => ({
	input2: {
		width: "100%"
	}
}))
