import React, { useEffect, createContext, useContext, useState } from "react"
import moment from "moment"

const UIContext = createContext()

export function useUIContext() {
	return useContext(UIContext)
}

export const UIConsumer = UIContext.Consumer

export function UIProvider({ children }) {
	// old code to be deleted ↓
	// const [queryParams, setQueryParams] = useState({
	//   date: moment()
	//     .subtract(1, "days")
	//     .endOf("day")
	//     .toString(),
	//   timeframe: "day",
	// });
	const [queryParams, setQueryParams] = useState({
		startDate: moment().subtract(24, "hours"),
		endDate: moment()
		// areas: [],
		// roles: []
	})
	// note: we don't need .toString() if we have the timeframe on the query

	const value = {
		queryParams,
		setQueryParams
	}

	// useEffect(() => {
	// 	console.log("queryParams", queryParams)
	// }, [queryParams])

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
