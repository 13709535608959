import React, { useState, useEffect } from "react"
import { Carousel } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"

const BigImageModalActive = ({ show, handleClose, activeAnchor, selectedImageIndexActive }) => {
	// const [imageSize, setImageSize] = useState({ width: 0, height: 0 })

	// Create an Image element to retrieve the actual image dimensions
	// const imgElement = new Image()
	// imgElement.src = imageSrc

	// useEffect(() => {
	// 	// When the image is loaded, set its dimensions
	// 	imgElement.onload = () => {
	// 		setImageSize({ width: imgElement.width, height: imgElement.height })
	// 	}
	// }, [imageSrc])

	// useEffect(() => {
	// 	if (imageSrc) {
	// 		const el = document.getElementsByClassName("big_image_modal_anchors_active")

	// 		if (el && el[0]) {
	// 			el.forEach(val => {
	// 				val.style.backgroundColor = "transparent"
	// 				val.style.display = "flex"
	// 				val.style.justifyContent = "center"
	// 				val.style.alignItems = "center"
	// 			})
	// 		}
	// 		const el2 = document.getElementsByClassName("big_image_modal_anchors_content_active")

	// 		if (el2 && el2[0]) {
	// 			el2.forEach(val => {
	// 				val.style.backgroundColor = "transparent"
	// 				val.style.width = imageSize.width < 800 ? imageSize.width + "px" : "500px"
	// 				val.style.height = imageSize.height < 800 ? imageSize.height + "px" : "400px"
	// 			})
	// 		}
	// 	}
	// }, [imageSrc, imgElement, imageSize])

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="big_image_modal_anchors_active"
			contentClassName="big_image_modal_anchors_content_active"
		>
			<Modal.Body style={{ padding: 0 }}>
				<Carousel
					indicators={false}
					controls={
						activeAnchor?.imagesUrls &&
						activeAnchor?.imagesUrls?.length > 0 &&
						activeAnchor?.imagesUrls?.length > 1
					}
					interval={1500}
					defaultActiveIndex={selectedImageIndexActive}
				>
					{activeAnchor &&
						activeAnchor?.imagesUrls?.length > 0 &&
						activeAnchor?.imagesUrls.map((image, index) => (
							<Carousel.Item key={index} interval={1000}>
								<img
									src={image}
									alt="Large Image"
									style={{
										width: "100%",
										height: "43rem",
										maxHeight: "43rem", // You can adjust this value
										objectFit: "cover"
									}}
								/>
							</Carousel.Item>
						))}
				</Carousel>
				<div className="d-flex justify-content-between align-items-center popup_title pt-5 pb-5">
					<span>UUID: {activeAnchor && activeAnchor?.uuid}</span>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default BigImageModalActive
