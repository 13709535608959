import React from "react"
import { useSelector, shallowEqual } from "react-redux"
import SVG from "react-inlinesvg"
import "./TopRowInfo.css"

export function TopRowInfo() {
	const { tags, people, weather } = useSelector(
		state => ({
			tags: state.liveData?.tags || [],
			people: state.basePage?.people || [],
			weather: state.liveData?.weather
		}),
		shallowEqual
	)

	// const activePeople = tags.filter(tag => tag.person && tag.tagState === "active").length
	// const inactivePeople = tags.filter(tag => tag.person && tag.tagState === "inactive").length
	// const activeUnnasignedTags = tags.filter(
	// 	tag => !tag.person && !tag.asset && tag.tagState === "active"
	// ).length

	// const activeAssets = tags.filter(tag => tag.asset && tag.tagState === "active").length
	// const inactiveAssets = tags.filter(tag => tag.asset && tag.tagState === "inactive").length

	const activeTags = tags.filter(tag => tag.person && tag.tagState === "active")
	const activePeople = activeTags.length
	const activeUnnasignedTags = tags.filter(
		tag => !tag.person && !tag.asset && tag.tagState === "active"
	).length

	const peopleWithTagAssigned = people.filter(person => person.uuid)
	const inactivePeople = peopleWithTagAssigned.filter(
		person => !activeTags.some(tag => tag.uuid === person.uuid)
	).length

	const activeAssets = tags.filter(tag => tag.asset && tag.tagState === "active").length
	const inactiveAssets = tags.filter(tag => tag.asset && tag.tagState === "inactive").length

	return (
		<div className="row" data-testid="top_row_div" id="dashboard_top_row_info_main_div">
			<div className="h-100 d-flex col-md-4 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between">
				<GlobalPeopleInfo
					value={activePeople + activeUnnasignedTags}
					text="On-site people tags"
					icon="/media/crowdkeep/Person_present.svg"
					color="#99BDF8"
				/>
				<GlobalPeopleInfo
					value={inactivePeople}
					text="Off-site people"
					icon="/media/crowdkeep/Person_absent.svg"
					color="#DDE9FD"
				/>
			</div>
			<div className="h-100 d-flex col-md-4 col-sm-6 col-xs-12 col flex-column justify-content-lg-between justify-content-md-between">
				<GlobalPeopleInfo
					value={activeAssets}
					text="On-site assets tags"
					icon="/media/crowdkeep/asset_active.svg"
					color="#B7B3FF"
				/>
				<GlobalPeopleInfo
					value={inactiveAssets}
					text="Off-site assets"
					icon="/media/crowdkeep/asset_idle.svg"
					color="#E7E5FF"
				/>
			</div>

			<div className="h-100 col-md-4 col-sm-12 col-xs-12 col-12 col">
				<WeatherInfo weather={weather} />
			</div>
		</div>
	)
}

export function GlobalPeopleInfo({ value, text, icon, color }) {
	return (
		<div
			id="dashboard_GlobalPeopleInfo_div"
			className="d-flex justify-content-between align-items-start"
			style={{
				backgroundColor: color,
				borderRadius: "5px",
				padding: "15px",
				height: "80px"
			}}
		>
			<div
				className="d-flex flex-column"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					// letterSpacing: "-0.02em",
					color: "#4A4A68"
				}}
			>
				<span
					style={{
						fontSize: "28px",
						lineHeight: "40px",
						fontWeight: "700"
					}}
				>
					{value}
				</span>
				<span
					className="top_row_info_text"
					style={{
						// marginTop: "-8px",
						fontSize: "12px",
						lineHeight: "18px",
						fontWeight: "500"
					}}
				>
					{text}
				</span>
			</div>
			<img alt="Person Info Icon" src={icon} />
		</div>
	)
}

export function WeatherInfo({ weather }) {
	const celsius = weather?.main?.temp ? Math.round(weather.main.temp) : "-"
	const fahrenheit = weather?.main?.temp ? Math.round((weather.main.temp * 9) / 5 + 32) : "-"

	const temperature = `${fahrenheit}ºF / ${celsius}ºC`
	const description = weather?.weather[0]?.description
	return (
		<div
			className="h-100 d-flex flex-column justify-content-end"
			style={{
				backgroundColor: "#FFFFFF",
				borderRadius: "5px",
				padding: "17px",
				boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.10)"
			}}
		>
			{weather?.weather[0]?.icon ? (
				<img
					alt="Weather info icon"
					style={{
						width: "86px",
						height: "86px",
						marginLeft: "auto",
						marginRight: "-15px",
						marginTop: "-15px",
						marginBottom: "-15px"
					}}
					src={`http://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
				/>
			) : (
				<div />
			)}
			<div
				className="d-flex flex-column"
				style={{
					fontFamily: "Poppins",
					fontStyle: "normal",
					letterSpacing: "-0.02em",
					color: "#4A4A68"
				}}
			>
				<span
					className="mb-2"
					style={{
						fontSize: "28px",
						fontWeight: "700"
					}}
				>
					{temperature}
				</span>
				<div className="d-flex mb-2">
					<SVG
						src="/media/svg/markers/location.svg"
						width={16}
						height="100%"
						title="location"
						className="mr-1"
					/>
					<span
						style={{
							fontSize: "12px",
							fontWeight: "500"
						}}
					>
						{weather && weather.name}
					</span>
				</div>

				<span
					className="mb-1"
					style={{
						fontSize: "12px",
						fontWeight: "500"
					}}
				>
					{description ? description?.charAt(0).toUpperCase() + description?.slice(1) : "-"}
				</span>
				<span
					style={{
						fontSize: "12px",
						fontWeight: "500"
					}}
				>
					Humidity - {weather?.main?.humidity}%
				</span>
			</div>
		</div>
	)
}
