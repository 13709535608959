import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader } from "../../../_partials/Card"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import * as actions from "../../_redux/contactTracing/contactTracingActions"
import { ContactTracingTable } from "./contactTracingTable/ContactTracingTable"
import { Toolbar } from "./contactTracingTable/toolbar/Toolbar"
import AuditToolMapWidget from "./AuditToolMapWidget/AuditToolMapWidget"
import AuditSlider from "./AuditSlider/AuditSlider"
import { Alert } from "@material-ui/lab"
import { firestoreOld } from "../../../../firebase"

export function ContactTracingCard({ person, dashboardAreas }) {
	//note: var person is coming from ContactTracingPage and going to toolbar
	const dispatch = useDispatch()

	// ━━━━━━━━━━━━━━━━━ Selectors ━━━━━━━━━━━━━━━━━
	const {
		selectedCustomer,
		selectedSite,
		selectedFloorPlan,
		isLoading,
		floorPlans,
		// areas,
		auditToolPersonLocation
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			selectedFloorPlan: state.profile?.currentFloorPlan,
			isLoading: state.contactTracing.listLoading,
			floorPlans: state.basePage.floorPlans,
			// areas: state.basePage?.areas,
			auditToolPersonLocation: state.contactTracing.auditToolPersonLocation
		}),
		shallowEqual
	)

	// ━━━━━━━━━━━━━━━  States  ━━━━━━━━━━━━━━━ \\
	const [map, setMap] = useState()
	const [updatedCurrentFloorPlan, setUpdatedCurrentFloorPlan] = useState() // updated current floorplan with all data updated from redux (geoJson / geoJson routes / areas) without need of refreshing after creating on design studio
	const [sliderValue, setSliderValue] = useState(0)
	const [data, setData] = useState([])
	const [allFetchedData, setAllFetchedData] = useState([])
	const [pressSubmitButton, setPressSubmitButton] = useState(false)
	const [warningText, setWarningText] = useState(null)
	const [areasUpdated, setAreasUpdated] = useState([])
	const [areasUpdatedDashboard, setAreasUpdatedDashboard] = useState([])
	const [playing, setPlaying] = useState(false)
	const [selectedUser, setSelectedUser] = useState(null)

	// ━━━━━━━━━━━━━━━  Functions  ━━━━━━━━━━━━━━━ \\
	function handleSliderChange(e, newValue) {
		setSliderValue(newValue)

		// Filter only one element from the data array based on the slider value (index)
		const selectedData = allFetchedData[newValue]
		// console.log("selectedData ->", selectedData)

		selectedData && setData([selectedData])
	}

	// Backward / Reset / Stop slider
	const handleStopClick = e => {
		setSliderValue(0) // Reset the value to the minimum value.
		setPlaying(false)
		handleSliderChange(e, 0)
	}

	// ━━━━━━━━━━━━━━━  UseEffects  ━━━━━━━━━━━━━━━ \\

	// if no selectedUser stop playing and reset slider.
	useEffect(() => {
		if (!selectedUser) {
			handleStopClick()
			setData([])
		}
	}, [selectedUser])

	// store areas from dashboard in a state (so we can clean state after searching for new person on this page)
	useEffect(() => {
		if (!dashboardAreas) {
			return
		} else {
			setAreasUpdatedDashboard(dashboardAreas)
		}
	}, [dashboardAreas])

	// Fetch all updated areas and set to state
	useEffect(() => {
		let isMounted = true

		const fetchAllAreas = async () => {
			try {
				const _areas = await firestoreOld
					.collection("Sites")
					.doc(selectedSite.id)
					.collection(`Areas`)
					.get()

				const allAreasFetched = _areas.docs.map(doc => ({
					id: doc.id,
					...doc.data()
				}))

				if (isMounted) {
					setAreasUpdated(allAreasFetched)
				}
			} catch (error) {
				// Handle errors
			}
		}

		if (selectedSite) {
			fetchAllAreas()
		}

		return () => {
			isMounted = false
		}
	}, [selectedSite])

	// fetch users
	useEffect(() => {
		if (!selectedCustomer && !selectedSite) return

		dispatch(actions.fetchUsersSelectedSite({ customerId: selectedCustomer?.id, selectedSite }))
	}, [selectedCustomer, selectedSite])

	// Set fetched data to state
	useEffect(() => {
		// if (!auditDataSmall) return

		// console.log(auditDataSmall, "auditDataSmall")

		// const fetchedDataClone = [...auditDataSmall]

		if (!auditToolPersonLocation) return

		if (auditToolPersonLocation.length === 0) {
			// const paragraphToaster = (
			// 	<p style={{ textAlign: "center" }}>
			// 		Not receiving any data from this person. <br />
			// 	</p>
			// )
			setWarningText("No data for the person at the selected timeframe")
			setAllFetchedData([])
			setData([])
			// dispatch({
			// 	type: "SNACKBAR_WARNING",
			// 	payload: paragraphToaster
			// })

			return
		}

		setWarningText()
		const fetchedDataClone = [...auditToolPersonLocation]

		if (pressSubmitButton) {
			setData([fetchedDataClone[0]])
			setAllFetchedData(fetchedDataClone)
		} else {
			setData([])
		}
	}, [
		// auditDataSmall,
		auditToolPersonLocation,
		pressSubmitButton
	])

	return (
		<Card isLoading={isLoading} data-testid="card-ContactTracingCard">
			<CardHeader title="Audit Tool" />
			<CardBody padding="15px">
				<div data-testid="div-toolbar-ContactTracingCard">
					<Toolbar
						person={person}
						setPressSubmitButton={setPressSubmitButton}
						areas={areasUpdated}
						setWarningText={setWarningText}
						isLoading={isLoading}
						areasUpdatedDashboard={areasUpdatedDashboard}
						setAreasUpdatedDashboard={setAreasUpdatedDashboard}
						handleStopClick={handleStopClick}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
						data={data}
					/>
				</div>
				{warningText && selectedUser && (
					<div className="px-4 pt-4" data-testid="div-alert-ContactTracingCard">
						<Alert className="d-flex align-items-center" severity="warning">
							{warningText}
						</Alert>
					</div>
				)}
				<div data-testid="div-Auditslider-ContactTracingCard">
					<AuditSlider
						data={data}
						allFetchedData={allFetchedData}
						handleSliderChange={handleSliderChange}
						sliderValue={sliderValue}
						setSliderValue={setSliderValue}
						playing={playing}
						setPlaying={setPlaying}
						handleStopClick={handleStopClick}
						isLoading={isLoading}
						selectedUser={selectedUser}
					/>
				</div>
				<div data-testid="div-AuditToolMapWidget-ContactTracingCard">
					<AuditToolMapWidget
						map={map}
						setMap={setMap}
						floorPlans={floorPlans}
						selectedFloorPlan={selectedFloorPlan}
						updatedCurrentFloorPlan={updatedCurrentFloorPlan}
						setUpdatedCurrentFloorPlan={setUpdatedCurrentFloorPlan}
						selectedCustomer={selectedCustomer}
						selectedSite={selectedSite}
						areas={areasUpdated}
						// auditToolPersonLocation={auditToolPersonLocation}
						data={data}
					/>
				</div>
				<div className="mt-5" data-testid="div-ContactTracingTable-ContactTracingCard">
					<ContactTracingTable />
				</div>
				{/* // note: Chart done  only with mockData */}
				{/* <div className='m-5'>
         			 <ContactCharts />
       				 </div> */}
			</CardBody>
		</Card>
	)
}
