import React from "react"
import ReactDOM from "react-dom"
import PopoverStickOnHover from "../PopoverStickOnHover"
import { IoLocationSharp } from "react-icons/io5"
import { polygon, point, booleanPointInPolygon } from "@turf/turf"
import moment from "moment"
import { Button } from "../../../../_partials/ButtonOLD"

const SPIDERFY_FROM_ZOOM = 15

export function mouseClick(e, map, spiderifier) {
	/* Handle spiderifier of devices in cluster */
	var devicesClusterFeatures = map.queryRenderedFeatures(e.point, {
		layers: ["cluster-pins"]
	})

	spiderifier.unspiderfy()
	if (devicesClusterFeatures.length) {
		if (map.getZoom() < SPIDERFY_FROM_ZOOM) {
			if (map.getZoom() + 2 > SPIDERFY_FROM_ZOOM) {
				map.easeTo({ center: e.lngLat, zoom: SPIDERFY_FROM_ZOOM })
			} else {
				map.easeTo({ center: e.lngLat, zoom: map.getZoom() + 2 })
			}
		} else {
			const clusterId = devicesClusterFeatures[0].properties.cluster_id
			map.getSource("pins").getClusterLeaves(clusterId, 100, 0, (err, leafFeatures) => {
				if (err) {
					return console.error("error while getting leaves of a cluster", err)
				}
				var markers = leafFeatures.map(leafFeature => {
					return leafFeature.properties
				})
				spiderifier.spiderfy(devicesClusterFeatures[0].geometry.coordinates, markers)
			})
		}
		return
	}
}

export function initializeSpiderLeg(
	spiderLeg,
	markersSize,
	roles,
	updatedCurrentFloorPlan,
	areas,
	isFullscreen,
	mapContainerRef,
	setSendMessageModal,
	setGetDirections,
	geoJsonRoutesUndefinedSnackBar,
	setClickedToResetPath,
	setOpenDrawerBar,
	setDrawerOptions,
	setEndingPoint,
	dispatch,
	customClaims
) {
	var feature = spiderLeg.feature
	const tag = feature
	var pinElem = spiderLeg.elements.pin

	let targetColor = null
	const role = roles && roles.find(val => val.name === tag.person?.role) // find color of each role and pass to TagsMarkers

	// Color by tagState
	const tagStateColor =
		tag.tagState === "active"
			? "#53B04F"
			: tag.tagState === "idle"
			? "#F6B952"
			: tag.tagState === "SOS"
			? "#E65019"
			: "#95A5A6"

	const color = targetColor || tagStateColor
	const important = role?.important

	const content = (
		<PopoverStickOnHover
			component={
				<div style={{ marginBottom: "-2px" }}>
					{customClaims?.isSuper && <DeveloperData tag={tag} />}
					<div
						style={{
							background: "#ECF1F4",
							boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
							borderRadius: "6px",
							fontFamily: "Poppins",
							fontStyle: "normal",
							color: "#4A4A68",
							// paddingBottom: "2px",
							padding: "12px",
							minWidth: "276px"
						}}
					>
						<PersonInfo tag={tag} floorPlan={updatedCurrentFloorPlan} areas={areas} />
						<TimeInfo tag={tag} />
						<ButtonRow
							tag={tag}
							setSendMessageModal={setSendMessageModal}
							floorPlan={updatedCurrentFloorPlan}
							setGetDirections={setGetDirections}
							geoJsonRoutesUndefinedSnackBar={geoJsonRoutesUndefinedSnackBar}
							setClickedToResetPath={setClickedToResetPath}
							setOpenDrawerBar={setOpenDrawerBar} //  opens New Path box on drawer
							setDrawerOptions={setDrawerOptions}
							setEndingPoint={setEndingPoint}
							dispatch={dispatch}
						/>
					</div>
				</div>
			}
			placement="top"
			delay={200}
			containerRef={isFullscreen && mapContainerRef}
		>
			<div
				className={
					"d-flex justify-content-center align-items-center slider-markers-size markers-" +
					(tag.person ? "people" : tag.asset ? "assets" : "unassignedTags")
				}
				style={{
					width: "28px",
					height: "28px",
					// width:
					// 	tag.person && markersSize?.people
					// 		? `${Math.abs(Math.floor((markersSize?.people / 100) * (80 - 14 + 1) + 14))}px`
					// 		: tag.asset && markersSize?.assets
					// 		? `${Math.abs(Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14))}px`
					// 		: !tag.person && !tag.asset
					// 		? `${Math.abs(
					// 				Math.floor((markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14)
					// 		  )}px`
					// 		: "26px",
					// height:
					// 	tag.person && markersSize?.people
					// 		? `${Math.abs(Math.floor((markersSize?.people / 100) * (80 - 14 + 1) + 14))}px`
					// 		: tag.asset && markersSize?.assets
					// 		? `${Math.abs(Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14))}px`
					// 		: !tag.person && !tag.asset
					// 		? `${Math.abs(
					// 				Math.floor((markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14)
					// 		  )}px`
					// 		: "26px",
					position: "relative",
					overflow: "hidden",
					margin: "auto",
					backgroundColor: color || "#ffffff", // old code, before layers
					outline: `2px ${tag.person || tag.asset ? "solid" : "dashed"} ${"#ffffff"}`, // old code, before layers
					borderRadius: "50%"
				}}
			>
				{tag.person ? (
					<img
						style={{
							marginTop: important ? "0" : "6px",
							marginBottom: important ? "2px" : "0px",
							width: important ? "75%" : "100%",

							height: "auto",
							display: "block",
							color: color
						}}
						alt="person"
						src={
							color
								? important
									? "/icons/Star.svg"
									: "/media/crowdkeep/Person_white_new.svg"
								: "/media/crowdkeep/Person_black.svg"
						}
					/>
				) : tag.asset ? (
					<div
						className="d-flex justify-content-center align-items-center slider-markers-size markers-assets"
						style={{
							width:
								`${Math.abs(
									Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14)
								)}px` || "26px",
							height:
								`${Math.abs(
									Math.floor((markersSize?.assets / 100) * (80 - 14 + 1) + 14)
								)}px` || "26px",
							position: "relative",
							overflow: "hidden"
						}}
					>
						<img
							style={{
								width: "80%",
								height: "auto",
								display: "block",
								color: color
							}}
							alt="asset"
							src={"/media/crowdkeep/Asset_Cube_Filled.svg"}
						/>
					</div>
				) : (
					// For unassigned tags
					<div
						className="d-flex justify-content-center align-items-center slider-markers-size markers-unassignedTags"
						style={{
							width: markersSize?.unassignedTags
								? `${Math.abs(
										Math.floor((markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14)
								  )}px`
								: "26px",
							height: markersSize?.unassignedTags
								? `${Math.abs(
										Math.floor((markersSize?.unassignedTags / 100) * (80 - 14 + 1) + 14)
								  )}px`
								: "26px",
							position: "relative",
							overflow: "hidden"
						}}
					></div>
				)}
			</div>
		</PopoverStickOnHover>
	)

	ReactDOM.render(content, pinElem)
}

function ButtonRow(props) {
	const {
		tag,
		setSendMessageModal,
		floorPlan,
		setGetDirections,
		geoJsonRoutesUndefinedSnackBar,
		setClickedToResetPath,
		setOpenDrawerBar,
		setDrawerOptions,
		setEndingPoint,
		dispatch
	} = props

	return (
		<div className="d-flex mt-3">
			<div className="flex-fill mr-1">
				<Button
					className="w-100"
					onClick={() => {
						// ↓ if no routes drawn display message to user and don't show directions box
						if (!floorPlan?.geoJsonRoutes || floorPlan?.geoJsonRoutes.features.length === 0) {
							geoJsonRoutesUndefinedSnackBar(floorPlan)
							return
						}

						// const foundedPerson = people && people.find(option => tag.uuid === option.uuid)

						// if (!foundedPerson) {
						// 	const paragraphToaster = (
						// 		<p style={{ textAlign: "center" }}>
						// 			This tag has no person information.
						// 			<br />
						// 			<span>Can't display path with this tag.</span>
						// 		</p>
						// 	)

						// 	dispatch({
						// 		type: "SNACKBAR_ERROR",
						// 		payload: paragraphToaster
						// 	})

						// 	return
						// }

						const name = tag.person?.personalData?.name || tag.asset?.name || tag.uuid

						if (!name || !tag?.gpsData || !tag?.floorPlanId || !tag?.siteId) {
							dispatch({
								type: "SNACKBAR_ERROR",
								payload: "Error searching for a path to this tag"
							})
							return
						}

						const tagWithPersonalData = {
							group: tag.person ? "People" : tag.asset ? "Assets" : "Unassigned",
							name: name,
							gpsData: tag?.gpsData,
							floorPlanId: tag?.floorPlanId,
							siteId: tag?.siteId, // Added the siteId
							...tag
						}

						// ↓ Select Tag has start point in directions box
						setGetDirections({
							type: tag.person ? "people" : tag.asset ? "assets" : "unassigned",
							feature: tagWithPersonalData,
							clicked: true,
							group: tag.person ? "People" : tag.asset ? "Assets" : "Unassigned",
							floorPlanId: floorPlan?.id
						})

						setEndingPoint(tagWithPersonalData) // new endingPoint

						// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
						// // Set a copy of start point (that doesn't close directions box)
						// setCopyOfStartPointCoords([
						//   tag.gpsData.longitude,
						//   tag.gpsData.latitude,
						// ]);

						// ↓ Read me: Don't need this anymore with new version of Path. Set a Copy of Start Point (that doesn't closes the directions box)
						// // ↓ Empty go to in directions box
						// setTravelTo()

						// ↓ Reset Path
						setClickedToResetPath(true)

						// ↓ opens New Path box on drawer
						setOpenDrawerBar(true)
						setDrawerOptions({
							openSearch: false,
							openFilter: false,
							openPath: true,
							openOccupancy: false,
							openSatellite: false,
							openSlider: false
						})
					}}
				>
					<span
						className="mr-2"
						style={{
							fontSize: "12px",
							letterSpacing: "0.56px",
							fontFamily: "Poppins",
							fontWeight: 700,
							fontStyle: "normal",
							lineHeight: "normal"
						}}
					>
						Directions
					</span>
					<img alt="Directions" src="/icons/directions.svg" />
				</Button>
			</div>
			{tag.person && (
				<>
					<div className="flex-fill mr-1 ml-1">
						<Button
							disabled={!tag.person?.personalData?.phoneNumber}
							className="w-100"
							variant="outlined"
						>
							<span className="mr-2">Call</span>
							<img alt="Directions" src="/icons/phone.svg" />
						</Button>
					</div>
					<div className="flex-fill ml-1">
						<Button
							disabled={!tag.person?.UID && !tag.person?.personalData?.phoneNumber}
							className="w-100"
							variant="outlined"
							onClick={() => {
								if (tag.person?.UID || tag.person?.personalData?.phoneNumber) {
									setSendMessageModal({
										person: tag.person
									})
								}
							}}
						>
							<span className="mr-2">Text</span>
							<img alt="Directions" src="/icons/email.svg" />
						</Button>
					</div>
				</>
			)}
		</div>
	)
}

function TimeInfo(props) {
	const { tag } = props

	if (!tag.person && !tag.asset) return <div></div>

	// const checkIn = tag.firstSeen ? moment(tag.firstSeen.toDate()).format("hh:mm A") : "--:--"
	// const lastSeen = tag.lastSeen ? moment(tag.lastSeen.toDate()).format("hh:mm A") : "--:--"

	const firstSeenDate = tag.firstSeen ? moment(tag.firstSeen) : null
	const lastSeenDate = tag.lastSeen ? moment(tag.lastSeen) : null

	const checkIn = firstSeenDate ? firstSeenDate.format("hh:mm A") : "--:--"
	const lastSeen = lastSeenDate ? lastSeenDate.format("hh:mm A") : "--:--"

	const minutesSinceCheckIn =
		firstSeenDate && lastSeenDate && lastSeenDate.diff(firstSeenDate, "minutes")

	const hours = minutesSinceCheckIn ? Math.floor(minutesSinceCheckIn / 60) : 0
	const minutes = minutesSinceCheckIn ? minutesSinceCheckIn % 60 : 0

	const total = `${hours}h ${minutes.toString().padStart(2, "0")}min`

	return (
		<div className="d-flex mt-3">
			{checkIn && (
				<div
					className="flex-fill mr-3"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						First Seen
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{checkIn}
					</div>
				</div>
			)}
			{lastSeen && (
				<div
					className="flex-fill"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Last Seen
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{lastSeen}
					</div>
				</div>
			)}
			{total && (
				<div
					className="flex-fill ml-3"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px"
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Total Hours
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{total}
					</div>
				</div>
			)}
		</div>
	)
}

function PersonInfo(props) {
	const { tag, floorPlan, areas } = props

	const defaultPicture = tag.person
		? "/media/crowdkeep/Person_Placeholder.svg"
		: tag.asset
		? "/media/crowdkeep/Asset_Cube_Placeholder.svg"
		: "/media/crowdkeep/Asset_Cube_Placeholder.svg"
	const picture = tag.person?.picture
	const name = tag.person?.personalData?.name || tag.asset?.name
	const role = tag.person?.role
	const type = tag.asset?.type

	// const area =
	// 	areas &&
	// 	areas.find(val => {
	// 		if (!val.polygon) return false

	// 		var areapolygon = val.polygon.map(coord => [coord.longitude, coord.latitude])
	// 		areapolygon.push(areapolygon[0])

	// 		var poly = polygon([areapolygon])
	// 		var tagpoint = point([tag.gpsData.longitude, tag.gpsData.latitude])
	// 		var inside = booleanPointInPolygon(tagpoint, poly)

	// 		return inside
	// 	})

	return (
		<div className="d-flex">
			<div className="mr-3">
				<img
					alt="Person"
					src={picture || defaultPicture}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = defaultPicture
					}}
					style={{
						backgroundColor: "#FAFCFE",
						width: "86px",
						height: "72px",
						objectFit: "contain",
						borderRadius: "4px"
					}}
				/>
			</div>
			<div className="d-flex flex-column">
				<span
					style={{
						fontWeight: 400,
						fontSize: "10px",
						lineHeight: "160%"
					}}
				>
					{role ||
						type ||
						(!name && (
							<span>
								<b>{"UUID: "}</b>
								{tag.uuid}
							</span>
						))}
				</span>
				<span
					style={{
						fontWeight: 700,
						fontSize: "18px",
						lineHeight: "160%"
					}}
				>
					{name || "Unassigned tag"}
				</span>
				<div>
					<IoLocationSharp
						style={{
							height: "18px",
							width: "18px",
							marginRight: "5px"
						}}
					/>
					<span
						style={{
							fontWeight: 300,
							fontSize: "10px",
							lineHeight: "160%"
						}}
					>
						{floorPlan?.name}
						{/* {area && `, ${area.name}`} */}
					</span>
				</div>
			</div>
		</div>
	)
}

function DeveloperData({ tag }) {
	return (
		<div
			style={{
				background: "#ECF1F4",
				boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
				borderRadius: "6px",
				fontFamily: "Poppins",
				fontStyle: "normal",
				color: "#4A4A68",
				padding: "12px",
				marginBottom: "15px"
			}}
		>
			<div>
				<b>Super admin data:</b>
			</div>
			<div>
				<b>{"ID: "}</b>
				<span>{tag?.id}</span>
			</div>
			<div>
				<b>{"UUID: "}</b>
				<span>{tag?.uuid}</span>
			</div>
			{tag?.positioningAnchorInfo?.anchorAddress && (
				<div>
					<b>{"anchorAddress: "}</b>
					<span>{tag?.positioningAnchorInfo?.anchorAddress}</span>
				</div>
			)}
			{tag?.positioningAnchorInfo?.rssi && (
				<div>
					<b>{"rssi: "}</b>
					<span>{tag?.positioningAnchorInfo?.rssi}</span>
				</div>
			)}

			<div style={{ display: "inline-block" }}>
				<b>gpsData: </b>
				<span>{tag?.gpsData?.latitude},</span>
				<span>{tag?.gpsData?.longitude}</span>
			</div>
		</div>
	)
}

export const removePopupSticky = () => {
	// make sure that the popover popup is closed
	const popupSticky = document.getElementsByClassName("popover")
	const popupStickyArray = Array.from(popupSticky) // Convert HTMLCollection to an array

	if (popupStickyArray && popupStickyArray.length !== 0) {
		popupStickyArray &&
			popupStickyArray.forEach(div => {
				div.style.visibility = "hidden"
				div.style.display = "none"
				div && div.remove()
			})
	}
}
