import React, { useState, useEffect } from "react"
import { ModalProgressBar } from "../../../../../_partials/ModalProgressBar"
import { Modal } from "react-bootstrap"
import { Bold } from "../../../../../_partials/typography"
import CloseIcon from "@material-ui/icons/Close"
import { IconButton } from "@material-ui/core"
import { Button } from "../../../../../_partials/Button"
import { shallowEqual, useSelector } from "react-redux"

export function DeleteSeveralModal({ showDeleteSeveral, setShowDeleteSeveral, unplaceAnchors }) {
	const { isLoading } = useSelector(
		state => ({
			isLoading: state.designStudio.actionsLoading
		}),
		shallowEqual
	)

	const [pressedApprove, setPressedApprove] = useState(false)

	// React.useEffect(() => {
	// 	console.log(isLoading, "isLoading")
	// 	console.log(pressedApprove, "pressedApprove")
	// }, [isLoading, pressedApprove])

	return (
		<Modal
			backdrop="static"
			show={showDeleteSeveral}
			onHide={() => {
				setShowDeleteSeveral(false)
				setPressedApprove(false)
			}}
		>
			<Modal.Body
				style={{
					padding: "0",
					backgroundColor: "#ECF1F4",
					borderRadius: "10px",
					overflow: "hidden"
				}}
			>
				<div className="pl-7 pr-7 pb-7 pt-2">
					{/* ━━★  TITLE  ★━━ */}
					<div className="d-flex align-items-center justify-content-between mt-3">
						<div>
							<Bold>UNPLACE ANCHORS</Bold>
						</div>
						<div>
							<IconButton
								size="small"
								onClick={() => setShowDeleteSeveral(false)}
								style={{ left: "12px" }}
								data-testid="close-button"
							>
								<CloseIcon />
							</IconButton>
						</div>
					</div>
					{isLoading && pressedApprove && <ModalProgressBar variant="query" />}

					{/* ━━★  BODY  ★━━ */}
					<div className="mt-5 pb-5">
						{/* {!isLoading && !pressedApprove && ( */}
						<span>Are you sure you want to unplace selected anchors?</span>
						{/* )} */}
						{/* {isLoading && pressedApprove && <span>Deleting anchors...</span>} */}
					</div>
					<div className="row" style={{ marginTop: "2rem" }}>
						<div className="col">
							<Button
								variant="outlined"
								color="primary"
								style={{ width: "100%" }}
								disabled={isLoading && pressedApprove}
								onClick={() => setShowDeleteSeveral(false)}
								text="Cancel"
								data-testid="cancel-button"
							/>
						</div>
						<div className="col">
							<Button
								style={{ width: "100%" }}
								variant="contained"
								color="primary"
								disabled={isLoading && pressedApprove}
								onClick={() => {
									unplaceAnchors()
									setPressedApprove(true)
								}}
								text="Unplace"
								data-testid="delete-button"
							/>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
