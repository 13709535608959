import moment from "moment"

function fetchAPIVersion2(props) {
	const {
		networkId,
		areas,
		dateStart,
		dateEnd,
		url,
		floorPlanId,
		uuid,
		siteId,
		timeZone,
		nodeType
	} = props
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_CUSTOMER_API_KEY)
	myHeaders.append("Content-Type", "application/json")

	// Set dates to correct times and then change those times to match UTC
	const startDate = moment(dateStart).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
	const endDate = moment(dateEnd).endOf("day").format("YYYY-MM-DDTHH:mm:ss")

	// Construct base URL and endpoint
	const baseUrl = process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION
	const endpoint = `/sites/${siteId}/reporting/${url}`

	// Construct query parameters
	const queryParams = new URLSearchParams({
		dateStart: startDate,
		dateEnd: endDate
	})

	if (nodeType) queryParams.append("nodeType", nodeType)
	if (networkId) queryParams.append("networkId", networkId)
	if (timeZone) queryParams.append("timeZone", timeZone)

	const finalUrl = `${baseUrl}${endpoint}?${queryParams.toString()}`

	// console.log("🚀  finalUrl:", finalUrl)

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	}

	return fetch(finalUrl, requestOptions).catch(error =>
		console.error("Error fetching data: ", error)
	)
}

//★━━━━━━━━━━━━━━━━★ Floor ★━━━━━━━━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\
export function getAllThePeopleDistribution(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId,
		networkId: queryParams.selectedSite.netWirepas,
		timeZone: queryParams.selectedSite.timeZone,
		// typechart: "timesheet",
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		url: `peopleday`,
		nodeType: queryParams?.nodeType
	})
}

export function getAvgTimeSpent(queryParams) {
	if (
		!queryParams.customerId ||
		!queryParams?.startDate ||
		!queryParams?.endDate ||
		!queryParams?.areas ||
		!Array.isArray(queryParams.areas) ||
		queryParams.areas.length === 0
	)
		return Promise.resolve(null)

	const allAreasNames = queryParams.areas.map(val => val.name)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		areas: allAreasNames,
		url: `areas/day`,
		nodeType: queryParams?.nodeType
	})
}

export function getPeopleDistribution(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		url: `floors/day`,
		nodeType: queryParams?.nodeType
	})
}

export function getPeopleDistributionBuildings(queryParams) {
	if (!queryParams.customerId || !queryParams?.startDate || !queryParams?.endDate)
		return Promise.resolve(null)

	return fetchAPIVersion2({
		siteId: queryParams.siteId || "",
		networkId: queryParams.selectedSite.netWirepas,
		dateStart: queryParams.startDate,
		dateEnd: queryParams.endDate,
		url: `buildings/day`,
		nodeType: queryParams?.nodeType
	})
}
