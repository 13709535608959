import React, { useMemo } from "react"
import { Button } from "../../../../../_partials/Button"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers"
import { useDispatch } from "react-redux"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

export function UserGrouping({ selectedRows, setShowDeleteSeveral }) {
	const dispatch = useDispatch()

	const checkForIds = () => {
		if (selectedRows.length > 0) {
			setShowDeleteSeveral(true)
		} else {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: `No Anchors Selected.`
			})
		}
	}

	return (
		<div className="form" data-testid="manage-roles_userGrouping">
			<div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
				<div className="col-xl-12">
					<div className="d-flex align-items-center">
						<div className="select_count_box">
							<label className="-font-bold font-danger-">
								<span className="span_selection_count">
									Selected anchors count:{" "}
									<b style={{ color: "#000" }}>{selectedRows?.length}</b>
								</span>
							</label>
						</div>
						<div>
							<OverlayTrigger placement="top" overlay={<Tooltip>Unplace</Tooltip>}>
								<Button
									variant="contained"
									color="errorLight"
									startIcon={
										<SVG
											className="MUICustom_secondary_svg"
											src={toAbsoluteUrl("/media/svg/icons/General/unplace-anchor.svg")}
										/>
									}
									className="MUICustom_secondary"
									style={{
										marginLeft: "1rem",
										borderRadius: "5px",
										maxWidth: "34px",
										minWidth: "34px",
										height: "33px"
									}}
									onClick={checkForIds}
									data-testid="delete-button"
								/>
							</OverlayTrigger>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
